import { makeStyles } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { translate } from 'src/i18n/translate';
import { AddTipDialog } from 'src/modules/pos/components/AddTipDialog';
import { useOrderTipActions } from 'src/modules/pos/tip/orderTipStore';
import { posReducer } from 'src/reducers/posReducer';
import { useAddPosTip } from 'src/services/pos/useAddPosTip';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosAddTipButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const notification = useNotification();
    const addTip = useAddPosTip();
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();
    const openAddTipDialog = useOrderTipActions((actions) => actions.openAddTipDialog);
    const closeAddTipDialog = useOrderTipActions((actions) => actions.closeAddTipDialog);

    const payments = useSelector((state) => state.pos.payments);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);

    const setCustomerDisplayScreenOrderStep = useAction(posReducer.actions.setCustomerDisplayScreenOrderStep);
    const setCustomerNumberSelected = useAction(posReducer.actions.setCustomerNumberSelected);

    const disabled = disabledPosPaymentActions || paying;
    const posPaymentIsPaymentLink = payments?.some((posPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const hasPaymentCompleted = BigNumber(totalPaid).isGreaterThanOrEqualTo(total);

    const addPosTip = (tip: any) => {
        setCustomerNumberSelected(tip.customerNumber);
        addTip(tip);
        closeAddTipDialog();
    };

    const handleOpenAddTipDialog = () => {
        if (!hasPaymentCompleted) return notification({ message: translate('Cannot add tip when payment is missing') });

        openAddTipDialog();
        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.ADD_TIP);
    };

    const handleCloseAddTipDialog = () => {
        closeAddTipDialog();
        setCustomerDisplayScreenOrderStep(CustomerDisplayScreenOrderSteps.ORDER_SUMMARY);
    };

    return (
        <>
            <AddTipDialog onClose={handleCloseAddTipDialog} onSuccess={addPosTip} />
            <Button secondary classes={{ button: classes.basicButton }} disabled={disabled || posPaymentIsPaymentLink} onClick={handleOpenAddTipDialog}>
                {translate('Add Tip')}
            </Button>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
