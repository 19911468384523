import { createStore } from '@pidedirecto/ui/hooks';

export const [useOrderTipStore, useOrderTipActions] = createStore<State, OrderTipActions>({
    initialState: {
        addTipDialogState: { open: false, tipPercentage: undefined },
    },
    actions: {
        openAddTipDialog: (state: State, tipPercentage: number | undefined) => {
            state.addTipDialogState.open = true;
            state.addTipDialogState.tipPercentage = tipPercentage;
        },
        closeAddTipDialog: (state: State) => {
            state.addTipDialogState.open = false;
            state.addTipDialogState.tipPercentage = undefined;
        },
    },
});

export type State = {
    addTipDialogState: { open: boolean; tipPercentage: number | undefined };
};

export type OrderTipActions = {
    openAddTipDialog: (tipPercentage?: number) => void;
    closeAddTipDialog: () => void;
};
