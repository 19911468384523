import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { isOnlineApp } from 'src/utils/app/isOnlineApp';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';

export function translateOrderPaymentMethod(order: OrderVm): string {
    switch (order.paymentMethod) {
        case PaymentMethods.CASH: {
            return translate('Cash');
        }
        case PaymentMethods.CREDIT_CARD:
        case PaymentMethods.PAYROLL: {
            if (order.hasPaymentMethodChangedToCard && isCardPayment(order.paymentMethod)) return translate('Credit Card (changed)');
            if (isOnlineApp(order.app)) return translate('Online payment');
            return translate('Credit Card');
        }
        case PaymentMethods.CARD_ON_DELIVERY: {
            return translate('Pay with card on delivery');
        }
        case PaymentMethods.PAYMENT_LINK:
            return translate('Payment Link');
        case PaymentMethods.PENDING: {
            return translate('Pending');
        }
        case PaymentMethods.TRANSFER: {
            return translate(PaymentMethods.TRANSFER);
        }
        case PaymentMethods.CREDITS: {
            return translate('Credits');
        }
        default: {
            return order.paymentMethod ?? '';
        }
    }
}
