import { createFilter } from 'redux-persist-transform-filter';
import { persistCombineReducers } from 'redux-persist5'; // TODO: change to redux-persist workaround  see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/410

// @ts-ignore
import storage from 'redux-persist5/lib/storage';
import { app2 } from 'src/app2';
import { appReducer } from 'src/reducers/appReducer';
import { authReducer } from 'src/reducers/authReducer';
import { inventory } from 'src/reducers/inventoryReducer';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import { reducer as locale } from 'src/reducers/LocaleReducer';
import { menuReducer } from 'src/reducers/menuReducer';
import { posReducer } from 'src/reducers/posReducer';
import { reducer as rejectOrderDialog } from 'src/reducers/RejectOrderDialogReducer';
import { reducer as monitoring } from 'src/scenes/letseatadmin/monitoring/MonitoringReducer';
import { reducer as changeRestaurantDetailsDialog } from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialogReducer';
import { reducer as theMenu } from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';

const config = {
    key: 'primary',
    storage: storage,
    whitelist: ['app', 'app2'],
    transforms: [createFilter('app', ['developerMode', 'deviceMemory', 'deviceMemoryUsed']), createFilter('app2', ['city'])],
} as const;

const reducers: any = persistCombineReducers(config, {
    app2: app2.reducer,
    authentication: authReducer.reducer,
    locale,
    monitoring,
    changeRestaurantDetailsDialog,
    rejectOrderDialog,
    theMenu,
    menu: menuReducer.reducer,
    liveDrivers: liveDrivers.reducer,
    inventory: inventory.reducer,
    pos: posReducer.reducer,
    app: appReducer.reducer,
});
export default reducers;
