import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { Suspense } from 'react';
import { Route } from 'react-router-dom';

export function LazyRoute({ children, path, exact }: Props): React.ReactElement {
    return (
        <Route path={path} exact={exact}>
            <Suspense fallback={<LoadingPage />}>{children}</Suspense>
        </Route>
    );
}

function LoadingPage(): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Loader loading={true} size={40} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

type Props = {
    children: React.ReactNode;
    path: string | Array<string>;
    exact?: boolean;
};
