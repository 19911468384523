export const NubefactCreditNoteTypes = {
    ANULACION_DE_LA_OPERACIÓN: 1,
    ANULACIÓN_POR_ERROR_EN_EL_RUC: 2,
    CORRECCION_POR_ERROR_EN_LA_DESCRIPCION: 3,
    DESCUENTO_GLOBAL: 4,
    DESCUENTO_POR_ITEM: 5,
    DEVOLUCION_TOTAL: 6,
    DEVOLUCION_POR_ITEM: 7,
    BONIFICACION: 8,
    DISMINUCIÓN_EN_EL_VALOR: 9,
    OTROS_CONCEPTOS: 10,
    AJUSTES_AFECTOS_AL_IVAP: 11,
    AJUSTES_DE_OPERACIONES_DE_EXPORTACION: 12,
    AJUSTES_MONTOS_Y_O_FECHAS_DE_PAGO: 13,
} as const;

export type NubefactCreditNoteType = (typeof NubefactCreditNoteTypes)[keyof typeof NubefactCreditNoteTypes];
