import * as React from 'react';
import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { LazyRoute } from 'src/components/app/LazyRoute';
import { CustomAlertDialogHandler } from 'src/components/dialog/CustomAlertDialogHandler';
import { CancelDeliveryDialog } from 'src/components/dialog/delivery/CancelDeliveryDialog';
import { CancelDeliveryManagerDialog } from 'src/components/dialog/delivery/CancelDeliveryManagerDialog';
import { CompleteDeliveryManagerDialog } from 'src/components/dialog/delivery/CompleteDeliveryManagerDialog';
import { RestartDeliveryManagerDialog } from 'src/components/dialog/delivery/RestartDeliveryManagerDialog';
import ChangePickupStationsDialog from 'src/components/dialog/restaurant/ChangePickupStationsDialog';
import { RoutePaths } from 'src/constants/RoutePath';
import ChangeEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/ChangeEmailTemplateDialog';
import CreateEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/CreateEmailTemplateDialog';
import { ChangeRestaurantDetailsDialog } from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialog';
import ChangeScheduledTaskDialog from 'src/scenes/letseatadmin/scheduledTasks/ChangeScheduledTaskDialog';
import CreateScheduledTaskDialog from 'src/scenes/letseatadmin/scheduledTasks/CreateScheduledTaskDialog';
import { CopyRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CopyRestaurantMenuDialog';
import { CreateRestaurantMenuPromotionDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateRestaurantMenuPromotionDialog';
import { ImportRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ImportRestaurantMenuDialog';

const LazyMonitoring = lazy(() => import('src/scenes/letseatadmin/monitoring/Monitoring'));
const LazySupportPage = lazy(() => import('src/scenes/letseatadmin/SupportPage'));
const LazySupportPhoneNumbersPage = lazy(() => import('src/scenes/letseatadmin/SupportPhoneNumbersPage'));
const LazyRestaurantReferralsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantReferralsPage'));
const LazyDriverReferralsPage = lazy(() => import('src/scenes/letseatadmin/DriverReferralsPage'));
const LazyOverallStatsPage = lazy(() => import('src/scenes/letseatadmin/OverallStatsPage'));
const LazyRestaurantSalesPage = lazy(() => import('src/scenes/letseatadmin/RestaurantSalesPage'));
const LazyDeliveryMonitoringPage = lazy(() => import('src/scenes/letseatadmin/DeliveryMonitoringPage'));
const LazyRappiIntegrationsPage = lazy(() => import('src/scenes/letseatadmin/RappiIntegrationsPage'));
const LazyQuickActionsPage = lazy(() => import('src/scenes/letseatadmin/QuickActionsPage'));
const LazyDriverBannersPage = lazy(() => import('src/scenes/letseatadmin/DriverBannersPage'));
const LazyStatisticsPage = lazy(() => import('src/scenes/letseatadmin/StatisticsPage'));
const LazyStatisticsOldPage = lazy(() => import('src/scenes/letseatadmin/StatisticsOldPage'));
const LazySurveysPage = lazy(() => import('src/scenes/letseatadmin/SurveysPage'));
const LazySurveyPage = lazy(() => import('src/scenes/letseatadmin/SurveyPage'));
const LazyAdminCurrentOrders = lazy(() => import('src/scenes/letseatadmin/currentOrders/AdminCurrentOrders'));
const LazyOrdersPage = lazy(() => import('src/scenes/letseatadmin/OrdersPage'));
const LazyOrderPage = lazy(() => import('src/scenes/letseatadmin/OrderPage'));
const LazyOrderIncidentsPage = lazy(() => import('src/scenes/letseatadmin/OrderIncidentsPage'));
const LazySuspiciousOrdersPage = lazy(() => import('src/scenes/letseatadmin/SuspiciousOrdersPage'));
const LazyOfficeDeliveryPage = lazy(() => import('src/scenes/letseatadmin/OfficeDeliveryPage'));
const LazyPickupStationsPage = lazy(() => import('src/scenes/letseatadmin/PickupStationsPage'));
const LazyBusinessesPage = lazy(() => import('src/scenes/letseatadmin/BusinessesPage'));
const LazyBrandPage = lazy(() => import('src/scenes/letseatadmin/BrandPage'));
const LazyBrandsPage = lazy(() => import('src/scenes/letseatadmin/BrandsPage'));
const LazyRestaurantsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantsPage'));
const LazyRestaurantsDetails = lazy(() => import('src/scenes/letseatadmin/restaurantsDetails/RestaurantsDetails'));
const LazyManagerUserPage = lazy(() => import('src/scenes/letseatadmin/ManagerUserPage'));
const LazyManagerUsersPage = lazy(() => import('src/scenes/letseatadmin/ManagerUsersPage'));
const LazyCustomerPage = lazy(() => import('src/scenes/letseatadmin/CustomerPage'));
const LazyCustomersPage = lazy(() => import('src/scenes/letseatadmin/CustomersPage'));
const LazyDriverPage = lazy(() => import('src/scenes/letseatadmin/DriverPage'));
const LazyDriversPage = lazy(() => import('src/scenes/letseatadmin/DriversPage'));
const LazyDriverBonusOffersPage = lazy(() => import('src/scenes/letseatadmin/DriverBonusOffersPage'));
const LazyOngoingDriverBonusesPage = lazy(() => import('src/scenes/letseatadmin/OngoingDriverBonusesPage'));
const LazyPromoCodesPage = lazy(() => import('src/scenes/letseatadmin/PromoCodesPage'));
const LazyBannersPage = lazy(() => import('src/scenes/letseatadmin/BannersPage'));
const LazyScheduledTasksPage = lazy(() => import('src/scenes/letseatadmin/ScheduledTasksPage'));
const LazyEmailTemplatesPage = lazy(() => import('src/scenes/letseatadmin/EmailTemplatesPage'));
const LazyEmailsPage = lazy(() => import('src/scenes/letseatadmin/EmailsPage'));
const LazyFaqsPage = lazy(() => import('src/scenes/letseatadmin/FaqsPage'));
const LazyAlertDialogsPage = lazy(() => import('src/scenes/letseatadmin/AlertDialogsPage'));
const LazyCompanyPage = lazy(() => import('src/scenes/letseatadmin/CompanyPage'));
const LazyCompaniesPage = lazy(() => import('src/scenes/letseatadmin/CompaniesPage'));
const LazyBenefitPlanPage = lazy(() => import('src/scenes/letseatadmin/BenefitPlanPage'));
const LazyEmployeePage = lazy(() => import('src/scenes/letseatadmin/EmployeePage'));
const LazySubscriptionPage = lazy(() => import('src/scenes/letseatadmin/SubscriptionPage'));
const LazySubscriptionsPage = lazy(() => import('src/scenes/letseatadmin/SubscriptionsPage'));
const LazySubscriptionHistoriesPage = lazy(() => import('src/scenes/letseatadmin/SubscriptionHistoriesPage'));
const LazySubscriptionHistoryPage = lazy(() => import('src/scenes/letseatadmin/SubscriptionHistoryPage'));
const LazySubscriptionPayPerUsePaymentsPage = lazy(() => import('src/scenes/letseatadmin/SubscriptionPayPerUsePaymentsPage'));
const LazyRestaurantBillingPage = lazy(() => import('src/scenes/letseatadmin/RestaurantBillingPage'));
const LazyRestaurantBillingPageUy = lazy(() => import('src/scenes/letseatadmin/RestaurantBillingPageUy'));
const LazyDriverBillingPage = lazy(() => import('src/scenes/letseatadmin/DriverBillingPage'));
const LazyDriverBonusBillingPage = lazy(() => import('src/scenes/letseatadmin/DriverBonusBillingPage'));
const LazyKitchenBillingPage = lazy(() => import('src/scenes/letseatadmin/KitchenBillingPage'));
const LazyCompanyCreditsBillingPage = lazy(() => import('src/scenes/letseatadmin/CompanyCreditsBillingPage'));
const LazyCompanyPayrollBillingPage = lazy(() => import('src/scenes/letseatadmin/CompanyPayrollBillingPage'));
const LazyRestaurantVideosPage = lazy(() => import('src/scenes/letseatadmin/RestaurantVideosPage'));
const LazySettingsPage = lazy(() => import('src/scenes/letseatadmin/SettingsPage'));
const LazyLiveDriversPage = lazy(() => import('src/scenes/letseatadmin/LiveDriversPage'));
const LazyThirdPartyLogisticsCoveragePage = lazy(() => import('src/scenes/letseatadmin/ThirdPartyLogisticsCoveragePage'));
const LazyDemoRestaurantsPage = lazy(() => import('src/scenes/letseatadmin/DemoRestaurantsPage'));
const LazyRestaurantSelfRegistrationsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantSelfRegistrationsPage'));
const LazyAdminLogEventsPage = lazy(() => import('src/scenes/letseatadmin/AdminLogEventsPage'));
const LazyReleasesNotesPage = lazy(() => import('src/scenes/letseatadmin/ReleasesNotesPage'));
const LazyRequestInternalReportPage = lazy(() => import('src/scenes/letseatadmin/RequestInternalReportPage'));

export function AppRouteAdmin(): React.ReactElement {
    return (
        <>
            <CancelDeliveryDialog />
            <RestartDeliveryManagerDialog />
            <CompleteDeliveryManagerDialog />
            <CancelDeliveryManagerDialog />
            <ChangePickupStationsDialog />
            <CreateScheduledTaskDialog />
            <ChangeScheduledTaskDialog />
            <CreateEmailTemplateDialog />
            <ChangeEmailTemplateDialog />
            <ChangeRestaurantDetailsDialog />
            <CopyRestaurantMenuDialog />
            <ImportRestaurantMenuDialog />
            <CreateRestaurantMenuPromotionDialog />
            <CustomAlertDialogHandler />

            <LazyRoute path={RoutePaths.ADMIN_MONITORING}>
                <LazyMonitoring />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SUPPORT}>
                <LazySupportPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SUPPORT_PHONE_NUMBERS}>
                <LazySupportPhoneNumbersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_REFERRALS}>
                <LazyRestaurantReferralsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_REFERRALS}>
                <LazyDriverReferralsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_OVERALL_STATS}>
                <LazyOverallStatsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_SALES}>
                <LazyRestaurantSalesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DELIVERY_MONITORING}>
                <LazyDeliveryMonitoringPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RAPPI_INTEGRATIONS}>
                <LazyRappiIntegrationsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_QUICK_ACTIONS}>
                <LazyQuickActionsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVERAPP_SETTINGS}>
                <LazyDriverBannersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_STATISTICS}>
                <LazyStatisticsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_STATISTICS_OLD}>
                <LazyStatisticsOldPage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={[RoutePaths.ADMIN_SURVEYS, RoutePaths.ADMIN_SURVEYS_RESTAURANTS]} exact>
                    <LazySurveysPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_SURVEY}>
                    <LazySurveyPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_ONGOING_ORDERS}>
                <LazyAdminCurrentOrders />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_ORDERS} exact>
                    <LazyOrdersPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_ORDER}>
                    <LazyOrderPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_ORDER_INCIDENTS}>
                <LazyOrderIncidentsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SUSPICIOUS_ORDERS}>
                <LazySuspiciousOrdersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_OFFICE_DELIVERY}>
                <LazyOfficeDeliveryPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_PICKUP_STATIONS}>
                <LazyPickupStationsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_BUSINESSES}>
                <LazyBusinessesPage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_BRAND}>
                    <LazyBrandPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_BRANDS}>
                    <LazyBrandsPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANTS}>
                <LazyRestaurantsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANTS_DETAILS}>
                <LazyRestaurantsDetails />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_REGISTERS}>
                <LazyRestaurantsDetails />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_MANAGER_USER}>
                    <LazyManagerUserPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_MANAGER_USERS}>
                    <LazyManagerUsersPage />
                </LazyRoute>
            </Switch>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_CUSTOMER}>
                    <LazyCustomerPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_CUSTOMERS}>
                    <LazyCustomersPage />
                </LazyRoute>
            </Switch>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_DRIVER}>
                    <LazyDriverPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_DRIVERS}>
                    <LazyDriversPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS}>
                <LazyDriverBonusOffersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES}>
                <LazyOngoingDriverBonusesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_PROMO_CODES}>
                <LazyPromoCodesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_BANNERS}>
                <LazyBannersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SCHEDULED_TASKS}>
                <LazyScheduledTasksPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_EMAIL_TEMPLATES}>
                <LazyEmailTemplatesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_EMAILS}>
                <LazyEmailsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_FAQ}>
                <LazyFaqsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_CUSTOM_ALERT_DIALOG}>
                <LazyAlertDialogsPage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_COMPANY}>
                    <LazyCompanyPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_COMPANIES}>
                    <LazyCompaniesPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_BENEFIT_PLAN}>
                <LazyBenefitPlanPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_EMPLOYEE}>
                <LazyEmployeePage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_SUBSCRIPTION}>
                    <LazySubscriptionPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_SUBSCRIPTIONS}>
                    <LazySubscriptionsPage />
                </LazyRoute>
            </Switch>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_SUBSCRIPTION_HISTORIES}>
                    <LazySubscriptionHistoriesPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_SUBSCRIPTION_HISTORY}>
                    <LazySubscriptionHistoryPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_SUBSCRIPTION_PAYMENT_PAGE}>
                <LazySubscriptionPayPerUsePaymentsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_BILLING}>
                <LazyRestaurantBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_BILLING_UY}>
                <LazyRestaurantBillingPageUy />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BILLING}>
                <LazyDriverBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BONUS_BILLING}>
                <LazyDriverBonusBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_KITCHEN_BILLING}>
                <LazyKitchenBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_COMPANY_CREDITS_BILLING}>
                <LazyCompanyCreditsBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_COMPANY_PAYROLL_BILLING}>
                <LazyCompanyPayrollBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.RESTAURANT_VIDEOS}>
                <LazyRestaurantVideosPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SETTINGS}>
                <LazySettingsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_LIVE_DRIVERS}>
                <LazyLiveDriversPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE}>
                <LazyThirdPartyLogisticsCoveragePage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS}>
                <LazyDemoRestaurantsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_SELF_REGISTRATIONS}>
                <LazyRestaurantSelfRegistrationsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_LOG_EVENTS}>
                <LazyAdminLogEventsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RELEASES_NOTES}>
                <LazyReleasesNotesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_REQUEST_INTERNAL_REPORT}>
                <LazyRequestInternalReportPage />
            </LazyRoute>
        </>
    );
}
