import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { closePosBusinessDayApi } from 'src/api/letseatmanager/pos/closePosBusinessDayApi';
import { getPosBusinessDayReportApi } from 'src/api/letseatmanager/pos/getPosBusinessDayReportApi';
import { sendEndOfDayReportEmailApi } from 'src/api/letseatmanager/pos/sendEndOfDayReportEmailApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { Chip } from 'src/components/Chip';
import { CashRegisterPosBusinessDays } from 'src/components/pos/cashRegister/CashRegisterPosBusinessDays';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { posReducer } from 'src/reducers/posReducer';
import { PosCashRegisterActions } from 'src/scenes/letseatmanager/pos/PosCashRegisterActions';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { PosCashRegisterTransactions } from 'src/scenes/letseatmanager/posCashRegister/PosCashRegisterTransactions';
import { PosClosedCashRegister } from 'src/scenes/letseatmanager/posCashRegister/PosClosedCashRegister';
import { PosOpenedCashRegisterSummary } from 'src/scenes/letseatmanager/posCashRegister/PosOpenedCashRegisterSummary';
import { PosOpenedPosBusinessDaySummary } from 'src/scenes/letseatmanager/posCashRegister/PosOpenedPosBusinessDaySummary';
import { PrintPartialShiftCashRegisterButton } from 'src/scenes/letseatmanager/posCashRegister/PrintPartialShiftCashRegisterButton';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useFetchCashRegisterTransactions } from 'src/services/cashRegister/useFetchCashRegisterTransactions';
import { useFetchPosBusinessDaySummary } from 'src/services/cashRegister/useFetchPosBusinessDaySummary';
import { useNotification } from 'src/services/notification/useNotification';
import { usePrintPosBusinessDayReport } from 'src/services/printer/usePrintPosBusinessDayReport';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { RegExps } from 'src/utils/RegExps';

export function PosBusinessDayCashRegisters(): React.ReactElement {
    const classes = useStyles();

    const history = useHistory();
    const notification = useNotification();

    const { fetchOpenedCashRegister, loading: loadingCashRegister } = useFetchCashRegister();
    const { fetchPosBusinessDaySummary, posBusinessDaySummary, loading } = useFetchPosBusinessDaySummary();
    const { fetchCashRegisterTransactions, transactions } = useFetchCashRegisterTransactions();

    const [userHasRolePermission] = useUserHasRolePermission();
    const [printPosBusinessDayReport] = usePrintPosBusinessDayReport();

    const [isClosingPosBusinessDay, setIsClosingPosBusinessDay] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication?.pinCodeUserSignedIn);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);

    const hasPersonalCashRegisterPermission = userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const hasReadOnlyPermissions = !hasManageCashRegisterPermission && !hasPersonalCashRegisterPermission;
    const hasOpenedCashRegisterPosBusinessDay = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay?.opened,
    );
    const cashRegisterName = openedCashRegister
        ? translate('Cash register @cashRegisterNumber', { cashRegisterNumber: openedCashRegister?.cashRegisterPosBusinessDayNumber || '' })
        : translate('Active Cash Registers');

    useEffect(() => {
        fetchPosBusinessDaySummaryAndCashRegister();
    }, [pinCodeUserSignedIn]);

    useEffect(() => {
        if (openedCashRegister) fetchCashRegisterTransactions();
    }, [openedCashRegister]);

    const fetchPosBusinessDaySummaryAndCashRegister = () => {
        if (!posMultipleCashRegistersEnabled) return;
        if (!pinCodeUserSignedIn) return;

        if (hasPersonalCashRegisterPermission && openedCashRegister) {
            fetchOpenedCashRegister();
        }

        if (hasManageCashRegisterPermission || hasReadOnlyPermissions) {
            fetchPosBusinessDaySummary();
        }
    };

    const handleClosePosBusinessDay = async () => {
        setIsClosingPosBusinessDay(true);
        const response = await closePosBusinessDayApi({
            managerUserId: pinCodeUserSignedIn?.managerUserId,
            restaurantId,
        });

        if (!response.ok) {
            setIsClosingPosBusinessDay(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        setCashRegisterOpen(false);
        setOpenedPosBusinessDay();

        const posBusinessDayReport = await getPosBusinessDayReportApi({
            posBusinessDayReportId: response.data.posBusinessDayReportId,
            salesType,
        });

        if (!posBusinessDayReport.ok) {
            notification({ message: translate('Failed to send the report by email.') });
            return;
        }

        await printPosBusinessDayReport(posBusinessDayReport.data, true);

        const responseEmail = await sendEndOfDayReportEmailApi({ posBusinessDayId: response.data.posBusinessDayReportId });
        setIsClosingPosBusinessDay(false);

        if (!responseEmail.ok) {
            notification({ message: translate('Failed to send the report by email. Are you sure an email is already registered?') });
        }

        notification({ message: translate('Report successfully sent out via email') });

        history.push({
            pathname: RoutePaths.POS_BUSINESS_DAY.replace(`:posBusinessDayId(${RegExps.uuid})`, response.data.posBusinessDayReportId),
            search: history.location.search,
        });
    };

    const handleGoBack = () => {
        fetchPosBusinessDaySummary();
        setOpenedCashRegister();
        setOpenedCashRegisterPosBusinessDay();
    };

    if (loadingCashRegister || loading || isClosingPosBusinessDay) {
        return (
            <div className={classes.cashRegister}>
                <Loader size={40} loading={true} />
            </div>
        );
    }

    if (!openedCashRegister && (hasPersonalCashRegisterPermission || hasReadOnlyPermissions) && !hasManageCashRegisterPermission) {
        return <PosClosedCashRegister />;
    }

    if ((hasManageCashRegisterPermission || hasReadOnlyPermissions) && !posBusinessDaySummary) {
        return <PosClosedCashRegister />;
    }

    return (
        <div className={classes.cashRegister}>
            <PosRouter />
            <div className={classes.container}>
                {openedCashRegister && hasManageCashRegisterPermission && (
                    <GoBackButton classes={{ button: classes.goBackButton }} label={translate('Go back to cash registers')} goBack={handleGoBack} />
                )}
                <div className={classes.infoContainer}>
                    <div className={classes.statusContainer}>
                        <div className={classes.cashContainer}>
                            <h1 className={classes.name}>{cashRegisterName}</h1>
                            {openedCashRegister && (
                                <Chip classes={{ chip: classNames(classes.chip, { [classes.closeCashRegisterChip]: !openedCashRegister?.opened }) }}>
                                    {openedCashRegister?.opened ? translate('Opened') : translate('Closed')}
                                </Chip>
                            )}
                        </div>
                        {hasManageCashRegisterPermission && !openedCashRegister && (
                            <Button classes={{ button: classes.button }} disabled={isClosingPosBusinessDay || !!hasOpenedCashRegisterPosBusinessDay} onClick={handleClosePosBusinessDay}>
                                {translate('Make closing day')}
                            </Button>
                        )}
                        {openedCashRegister && <PrintPartialShiftCashRegisterButton cashRegisterId={openedCashRegister?.cashRegisterId} />}
                    </div>
                    <div className={classNames(classes.statusContainer, !userHasRolePermission(RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT) ? classes.endContainer : '')}>
                        {openedCashRegister?.openedBy && (
                            <div className={classes.contentText}>
                                <UserFilledIcon height={18} width={18} />
                                <div>{openedCashRegister?.openedBy}</div>
                            </div>
                        )}
                    </div>
                    <PosOpenedCashRegisterSummary />
                    {posBusinessDaySummary && !openedCashRegister && hasManageCashRegisterPermission && <PosOpenedPosBusinessDaySummary posBusinessDaySummary={posBusinessDaySummary} />}
                    {openedCashRegister && (
                        <PosCashRegisterActions
                            cashRegister={openedCashRegister}
                            refreshCashRegisterTransactions={fetchCashRegisterTransactions}
                            refreshPosBusinessDaySummary={posMultipleCashRegistersEnabled ? fetchPosBusinessDaySummary : undefined}
                        />
                    )}
                    {posBusinessDaySummary && !openedCashRegister && hasManageCashRegisterPermission && (
                        <CashRegisterPosBusinessDays cashRegisterPosBusinessDaysSummary={posBusinessDaySummary?.cashRegisterPosBusinessDaysSummary} />
                    )}
                    <PosCashRegisterTransactions transactions={transactions} />
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    chip: {
        height: 30,
        fontSize: 12,
        backgroundColor: '#CBF7E9',
        padding: '10px 20px',
        borderRadius: 8,
        color: '#024B49',
        fontFamily: theme.typography.regular,
    },
    cashRegister: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cashContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        height: 42,
    },
    goBackButton: {
        top: -50,
        left: 0,
        alignSelf: 'start',
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
    },
    infoContainer: {
        width: '100%',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            margin: '30px 0',
        },
    },
    buttonsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
        gap: 20,
    },
    statusContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    endContainer: {
        justifyContent: 'end',
    },
    closeCashRegisterChip: {
        backgroundColor: '#FBE1E9',
        color: '#DB3956',
    },
    name: {
        fontFamily: theme.typography.semiBold,
        margin: 0,
        fontSize: 20,
        marginRight: 10,
        color: theme.palette.text.primary,
    },
    contentText: {
        display: 'flex',
        alignItems: 'center',
    },
}));
