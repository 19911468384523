import { ConfirmDialog, Notifications } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect } from 'react';
import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppRouteOperations } from 'src/AppRoutesOperations';
import { AppRouteSupport } from 'src/AppRoutesSupport';
import { MinimumInventoryReachedAlert } from 'src/components/alertDialog/inventory/MinimumInventoryReachedAlert';
import { ConnectDeviceToWebSocket } from 'src/components/app/ConnectDeviceToWebSocket';
import { FetchKitchenOrdersUpdates } from 'src/components/app/FetchKitchenOrdersUpdates';
import { FetchRealtimeUpdates } from 'src/components/app/FetchRealtimeUpdates';
import { LazyRoute } from 'src/components/app/LazyRoute';
import { MenuSubscriptionUpdates } from 'src/components/app/MenuSubscriptionUpdates';
import { NewKitchenOrdersSoundPlayer } from 'src/components/app/NewKitchenOrdersSoundPlayer';
import { NewOrdersSoundPlayer } from 'src/components/app/NewOrdersSoundPlayer';
import { PosPaymentsSubscriptionUpdates } from 'src/components/app/PosPaymentsSubscriptionUpdates';
import { PosPaymentsSubscriptionUpdatesDeprecated } from 'src/components/app/PosPaymentsSubscriptionUpdatesDeprecated';
import { PrintAutoAcceptedOrder } from 'src/components/app/PrintAutoAcceptedOrder';
import { SyncOfflineUiLogEvents } from 'src/components/app/SyncOfflineUiLogEvents';
import { CustomAlertDialogHandler } from 'src/components/dialog/CustomAlertDialogHandler';
import { MockPaymentTerminalResponseDialog } from 'src/components/dialog/deprecated/MockPaymentTerminalResponseDialog';
import { IntegrationMenuChangesApprovalDialog } from 'src/components/dialog/menu/IntegrationMenuChangesApprovalDialog';
import { NoInternetConnectionDialog } from 'src/components/dialog/NoInternetConnectionDialog';
import { CreateManualOrderDeliveryDialog } from 'src/components/dialog/order/CreateManualOrderDeliveryDialog';
import RejectOrderDialog from 'src/components/dialog/order/RejectOrderDialog';
import { PinCodeUserSignInDialog } from 'src/components/dialog/PinCodeUserSignInDialog';
import { CustomerDisplayScreenOrderUpdater } from 'src/components/pos/CustomerDisplayScreenOrderUpdater';
import { PosBusinessDaySubscriptionUpdates } from 'src/components/pos/posPayment/PosBusinessDaySubscriptionUpdates';
import { history } from 'src/config/history';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { RoutePaths } from 'src/constants/RoutePath';
import { AskForRolePermissionAuthorizationDialog } from 'src/modules/order/components/AskForRolePermissionAuthorizationDialog';
import { AppRouteAdmin } from 'src/routers/AppRoutesAdmin';
import { CurrentOrdersDeprecated } from 'src/scenes/letseatmanager/currentOrders/CurrentOrdersDeprecated';
import { ChangeMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuCategoryDialog';
import { ChangeMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuDialog';
import { ChangeMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuItemDialog';
import { CreateMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuCategoryDialog';
import { CreateMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuDialog';
import { CreateMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuItemDialog';
import { HideMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/HideMenuItemDialog';
import { MoveMenuItemToMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/MoveMenuItemToMenuDialog';
import { RemoveMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuCategoryDialog';
import { RemoveMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuDialog';
import { RemoveMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuItemDialog';
import { FreeSubscriptionPlanEndPage } from 'src/scenes/letseatmanager/FreeSubscriptionPlanEndPage';
import { KitchenDisplayScreenPage } from 'src/scenes/letseatmanager/KitchenDisplayScreenPage';
import { SelectPaymentTerminalPaymentResultMockDialog } from 'src/scenes/letseatmanager/paymentTerminalPayment/SelectPaymentTerminalPaymentResultMockDialog';
import { SelectOpenedCashRegisterDialog } from 'src/scenes/letseatmanager/pos/SelectOpenedCashRegisterDialog';
import { VerifyPosPasswordDialog } from 'src/scenes/letseatmanager/pos/VerifyPosPasswordDialog';
import { VerifyPosPinCodeUserDialog } from 'src/scenes/letseatmanager/pos/VerifyPosPinCodeUserDialog';
import { PosBusinessDayReportPage } from 'src/scenes/letseatmanager/PosBusinessDayReportPage';
import { OpenCashRegisterDialog } from 'src/scenes/letseatmanager/posCashRegister/OpenCashRegisterDialog';
import { OpenPosBusinessDayDialog } from 'src/scenes/letseatmanager/posCashRegister/OpenPosBusinessDayDialog';
import { PosCashRegistersPage } from 'src/scenes/letseatmanager/PosCashRegistersPage';
import { PosPage } from 'src/scenes/letseatmanager/PosPage';
import { PosRestaurantZonesPage } from 'src/scenes/letseatmanager/PosRestaurantZonesPage';
import { PosUserLockscreenPage } from 'src/scenes/letseatmanager/PosUserLockscreenPage';
import { SubscriptionPage } from 'src/scenes/letseatmanager/SubscriptionPage';
import { TemporalOrdersPage } from 'src/scenes/letseatmanager/TemporalOrdersPage';
import { useRemotePrinter } from 'src/utils/react/useRemotePrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { useZendeskChat } from 'src/utils/react/useZendeskChat';

const LazyDevicesPage = lazy(() => import('src/scenes/letseatmanager/DevicesPage'));
const LazyTemporalPrintersPage = lazy(() => import('src/scenes/letseatmanager/TemporalPrintersPage'));
const LazyDeliveryZonesPage = lazy(() => import('src/scenes/letseatmanager/DeliveryZonesPage'));
const LazyHowDoesItWorkPage = lazy(() => import('src/scenes/letseatmanager/HowDoesItWorkPage'));
const LazyInventoryPage = lazy(() => import('src/scenes/letseatmanager/InventoryPage'));
const LazyInventorySuppliersPage = lazy(() => import('src/scenes/letseatmanager/InventorySuppliersPage'));
const LazyInventorySuppliesPage = lazy(() => import('src/scenes/letseatmanager/InventorySuppliesPage'));
const LazyInventoryRecipesPage = lazy(() => import('src/scenes/letseatmanager/InventoryRecipesPage'));
const LazyInventoryBuyOrdersPage = lazy(() => import('src/scenes/letseatmanager/InventoryBuyOrdersPage'));
const LazyCreateBuyOrderPage = lazy(() => import('src/scenes/letseatmanager/CreateBuyOrderPage'));
const LazyChangeBuyOrderPage = lazy(() => import('src/scenes/letseatmanager/ChangeBuyOrderPage'));
const LazyCreateSupplyMassiveProductionPage = lazy(() => import('src/scenes/letseatmanager/CreateSupplyMassiveProductionPage'));
const LazySelectBuyOrderCreationMethodPage = lazy(() => import('src/scenes/letseatmanager/SelectBuyOrderCreationMethodPage'));
const LazyInventoryProductionPage = lazy(() => import('src/scenes/letseatmanager/InventoryProductionPage'));
const LazyUserManagementPage = lazy(() => import('src/scenes/letseatmanager/UserManagementPage'));
const LazyInventoryKardexReportPage = lazy(() => import('src/scenes/letseatmanager/InventoryKardexReportPage'));
const LazyInventoryConsumptionsReportPage = lazy(() => import('src/scenes/letseatmanager/InventoryConsumptionsReportPage'));
const LazyInventoryReportPage = lazy(() => import('src/scenes/letseatmanager/InventoryReportPage'));
const LazyOwnFleetDriversReportPage = lazy(() => import('src/scenes/letseatmanager/OwnFleetDriversReportPage'));
const LazyCustomizePrintsPage = lazy(() => import('src/scenes/letseatmanager/CustomizePrintsPage'));
const LazyRestaurantTaxesPage = lazy(() => import('src/scenes/letseatmanager/RestaurantTaxesPage'));
const LazyPaymentLinksPage = lazy(() => import('src/scenes/letseatmanager/PaymentLinksPage'));
const LazyMarketingPage = lazy(() => import('src/scenes/letseatmanager/MarketingPage'));
const LazyIntegrationsPage = lazy(() => import('src/scenes/letseatmanager/IntegrationsPage'));
const LazyCustomersPage = lazy(() => import('src/scenes/letseatmanager/CustomersPage'));
const LazyPinCodeUserResumeDayPage = lazy(() => import('src/scenes/letseatmanager/PinCodeUserResumeDayPage'));
const LazyOrderInvoicesPage = lazy(() => import('src/scenes/letseatmanager/OrderInvoicesPage'));
const LazyGlobalOrderInvoicesPage = lazy(() => import('src/scenes/letseatmanager/GlobalOrderInvoicesPage'));
const LazyOwnFleetDriversPage = lazy(() => import('src/scenes/letseatmanager/OwnFleetDriversPage'));
const LazyAdvancedReportsPage = lazy(() => import('src/scenes/letseatmanager/AdvancedReportsPage'));
const LazyUploadBuyOrderPage = lazy(() => import('src/scenes/letseatmanager/UploadBuyOrderPage'));
const LazyOpeningHoursPage = lazy(() => import('src/scenes/letseatmanager/OpeningHoursPage'));
const LazyInventorySupplyHistoryReportPage = lazy(() => import('src/scenes/letseatmanager/InventorySupplyHistoryReportPage'));
const LazyInventorySuppliersReportPage = lazy(() => import('src/scenes/letseatmanager/InventorySuppliersReportPage'));
const LazyPromotionsPage = lazy(() => import('src/scenes/letseatmanager/PromotionsPage'));
const LazyRestaurantManualEarningsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantManualEarningsPage'));
const LazyTemporalMenuPage = lazy(() => import('src/scenes/letseatmanager/TemporalMenuPage'));
const LazyTheRestaurantAdminPage = lazy(() => import('src/scenes/letseatadmin/TheRestaurantPage'));
const LazyTheRestaurantPage = lazy(() => import('src/scenes/letseatmanager/TheRestaurantPage'));
const LazyPromoCodesPage = lazy(() => import('src/scenes/letseatmanager/PromoCodesPage'));
const LazySurveysPage = lazy(() => import('src/scenes/letseatmanager/SurveysPage'));
const LazyRestaurantPosReportPage = lazy(() => import('src/scenes/letseatmanager/RestaurantPosReportPage'));
const LazyRestaurantSalesReportPage = lazy(() => import('src/scenes/letseatmanager/RestaurantSalesReportPage'));
const LazyRestaurantGeneralReportPage = lazy(() => import('src/scenes/letseatmanager/RestaurantGeneralReportPage'));
const LazyCustomerReturnsPage = lazy(() => import('src/scenes/letseatmanager/CustomerReturnsPage'));
const LazyCustomerPage = lazy(() => import('src/scenes/letseatmanager/CustomerPage'));
const LazyChangeUserRolePage = lazy(() => import('src/scenes/letseatmanager/ChangeUserRolePage'));
const LazyCreateUserRolePage = lazy(() => import('src/scenes/letseatmanager/CreateUserRolePage'));
const LazyDeliveryItemsPage = lazy(() => import('src/scenes/letseatdriver/DeliveryItemsPage'));
const LazyPosEmployeeLogEventsPage = lazy(() => import('src/scenes/letseatmanager/PosEmployeeLogEventsPage'));

export function AppRoutes(): React.ReactElement {
    const location = useLocation();

    useZendeskChat();
    useRemotePrinter();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const operationsUser = useSelector((state) => state.authentication.operationsUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);

    useEffect(() => {
        if (location.pathname === PublicRoutePaths.RESTAURANT_SELF_REGISTRATION) {
            history.push({
                pathname: RoutePaths.ONGOING_ORDERS,
                search: history.location.search,
            });
        }
    }, [location.pathname]);

    return (
        <>
            <PrintAutoAcceptedOrder />
            <FetchRealtimeUpdates />
            <MenuSubscriptionUpdates />
            <PosBusinessDaySubscriptionUpdates />
            <NewOrdersSoundPlayer />
            <PosPaymentsSubscriptionUpdates />
            <PosPaymentsSubscriptionUpdatesDeprecated />
            <CustomerDisplayScreenOrderUpdater />
            <NewKitchenOrdersSoundPlayer />
            <ConnectDeviceToWebSocket />
            <FetchKitchenOrdersUpdates />
            <SyncOfflineUiLogEvents />
            <RejectOrderDialog />
            <MinimumInventoryReachedAlert />
            <IntegrationMenuChangesApprovalDialog />
            <Notifications />
            <ConfirmDialog />
            {restaurantId && (
                <>
                    <CustomAlertDialogHandler />
                    <CreateMenuDialog />
                    <ChangeMenuDialog />
                    <RemoveMenuDialog />
                    <CreateMenuCategoryDialog />
                    <ChangeMenuCategoryDialog />
                    <RemoveMenuCategoryDialog />
                    <CreateMenuItemDialog />
                    <ChangeMenuItemDialog />
                    <RemoveMenuItemDialog />
                    <HideMenuItemDialog />
                    <MoveMenuItemToMenuDialog />
                    <CreateManualOrderDeliveryDialog />
                    <NoInternetConnectionDialog />
                    <VerifyPosPasswordDialog />
                    <VerifyPosPinCodeUserDialog />
                    <PinCodeUserSignInDialog />
                    <OpenPosBusinessDayDialog />
                    <SelectOpenedCashRegisterDialog />
                    <OpenCashRegisterDialog />
                    <AskForRolePermissionAuthorizationDialog />
                    <MockPaymentTerminalResponseDialog />
                    <SelectPaymentTerminalPaymentResultMockDialog />
                    <Route path={RoutePaths.FREE_SUBSCRIPTION_PLAN_END} component={FreeSubscriptionPlanEndPage} />
                    <Route path={RoutePaths.ONGOING_ORDERS} component={CurrentOrdersDeprecated} />
                    <Route path={RoutePaths.ORDERS} component={TemporalOrdersPage} />
                    <Route path={RoutePaths.SUBSCRIPTION} component={SubscriptionPage} />
                    <Route path={RoutePaths.POS} exact component={PosPage} />
                    <Route path={RoutePaths.PIN_CODE_USER_LOGIN} exact component={PosUserLockscreenPage} />
                    <Route path={RoutePaths.POS_CASH_REGISTERS} component={PosCashRegistersPage} />
                    <Route path={RoutePaths.POS_TABLES} component={PosRestaurantZonesPage} />
                    <Route path={RoutePaths.POS_BUSINESS_DAY} component={PosBusinessDayReportPage} />
                    <Route path={RoutePaths.KITCHEN_DISPLAY_SCREEN} component={KitchenDisplayScreenPage} />
                    <LazyRoute path={RoutePaths.CUSTOMERS}>
                        <LazyCustomerPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.CUSTOMER_RETURNS}>
                        <LazyCustomerReturnsPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.RESTAURANT_GENERAL_REPORT}>
                        <LazyRestaurantGeneralReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.RESTAURANT_SALES_REPORT}>
                        <LazyRestaurantSalesReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.RESTAURANT_POS_REPORT}>
                        <LazyRestaurantPosReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.SURVEYS}>
                        <LazySurveysPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.PROMO_CODES}>
                        <LazyPromoCodesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.THE_RESTAURANT}>{adminUser || supportUser || operationsUser || viewUser ? <LazyTheRestaurantAdminPage /> : <LazyTheRestaurantPage />}</LazyRoute>
                    <LazyRoute path={RoutePaths.THE_MENU}>
                        <LazyTemporalMenuPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.PRINTERS}>
                        <LazyTemporalPrintersPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_MANUAL_EARNING}>
                        <LazyRestaurantManualEarningsPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.DEVICES}>
                        <LazyDevicesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.DELIVERY_ZONES}>
                        <LazyDeliveryZonesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.HOW_DOES_IT_WORK}>
                        <LazyHowDoesItWorkPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY}>
                        <LazyInventoryPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_SUPPLIER}>
                        <LazyInventorySuppliersPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_SUPPLY}>
                        <LazyInventorySuppliesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_RECIPE}>
                        <LazyInventoryRecipesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_BUY_ORDER}>
                        <LazyInventoryBuyOrdersPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_CREATE_BUY_ORDER}>
                        <LazyCreateBuyOrderPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_CHANGE_BUY_ORDER}>
                        <LazyChangeBuyOrderPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_CREATE_SUPPLY_MASSIVE_PRODUCTION}>
                        <LazyCreateSupplyMassiveProductionPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.BUY_ORDER_REGISTER_METHOD}>
                        <LazySelectBuyOrderCreationMethodPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_PRODUCTION}>
                        <LazyInventoryProductionPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.USER_MANAGEMENT}>
                        <LazyUserManagementPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.KARDEX_REPORT}>
                        <LazyInventoryKardexReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_CONSUMPTION_REPORT}>
                        <LazyInventoryConsumptionsReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_REPORT}>
                        <LazyInventoryReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.OWN_FLEET_DRIVERS_REPORT}>
                        <LazyOwnFleetDriversReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.CUSTOMIZE_PRINTS}>
                        <LazyCustomizePrintsPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INTEGRATIONS}>
                        <LazyIntegrationsPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.TAXES}>
                        <LazyRestaurantTaxesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.PAYMENT_LINKS}>
                        <LazyPaymentLinksPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.MARKETING}>
                        <LazyMarketingPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.MARKETING_CUSTOMERS}>
                        <LazyCustomersPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.EMPLOYEE_RESUME_DAY}>
                        <LazyPinCodeUserResumeDayPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.ORDER_INVOICES}>
                        <LazyOrderInvoicesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.GLOBAL_ORDER_INVOICES}>
                        <LazyGlobalOrderInvoicesPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.OWN_FLEET_DRIVERS}>
                        <LazyOwnFleetDriversPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.ADVANCED_REPORTS}>
                        <LazyAdvancedReportsPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.UPLOAD_BUY_ORDER}>
                        <LazyUploadBuyOrderPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.OPENING_HOURS}>
                        <LazyOpeningHoursPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.SUPPLY_HISTORY_REPORT}>
                        <LazyInventorySupplyHistoryReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.INVENTORY_SUPPLIERS_REPORT}>
                        <LazyInventorySuppliersReportPage />
                    </LazyRoute>
                    <LazyRoute path={RoutePaths.PROMOTIONS}>
                        <LazyPromotionsPage />
                    </LazyRoute>

                    <Switch>
                        <LazyRoute path={RoutePaths.CHANGE_USER_ROLE}>
                            <LazyChangeUserRolePage />
                        </LazyRoute>
                        <LazyRoute path={RoutePaths.CREATE_USER_ROLE}>
                            <LazyCreateUserRolePage />
                        </LazyRoute>
                    </Switch>
                    <LazyRoute path={RoutePaths.POS_EMPLOYEE_EVENTS}>
                        <LazyPosEmployeeLogEventsPage />
                    </LazyRoute>
                </>
            )}
            {(adminUser || supportUser || operationsUser || viewUser || kitchensUser) && (
                <LazyRoute path={RoutePaths.DRIVER_DELIVERY_ITEMS_PAGE}>
                    <LazyDeliveryItemsPage />
                </LazyRoute>
            )}
            {(adminUser || viewUser) && <AppRouteAdmin />}
            {supportUser && <AppRouteSupport />}
            {operationsUser && <AppRouteOperations />}
        </>
    );
}
