import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';

export async function chargeKioskOrderApi(request: ChargeKioskOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/chargeKioskOrderApi', request);
}

export type ChargeKioskOrderApiRequest = {
    orderId: OrderId;
    payments: Array<OrderPaymentVm>;
};
