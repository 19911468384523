import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { SeparateOrderDialog } from 'src/components/dialog/pos/SeparateOrderDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function SeparateOrderButton(): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const [separateOrderDialogState, seSeparateOrderDialogState] = useState(false);

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);

    const isAvailableToSeparateOrder = (items?.length < 2 && items[0]?.quantity === 1) || !items;

    const openSeparateOrderDialog = () => {
        seSeparateOrderDialogState(true);
    };

    const closeSeparateOrderDialog = () => {
        seSeparateOrderDialogState(false);
    };

    if (!isEatHereOrder(orderType) || !pendingOrder) return null;

    return (
        <>
            <SeparateOrderDialog open={separateOrderDialogState} handleClose={closeSeparateOrderDialog} />
            <SecuredContent rolePermission={RolePermissions.SEPARATE_ORDERS}>
                <Button
                    size={posInterfaceLargerEnabled ? 'large' : undefined}
                    classes={{ button: classes.basicButton }}
                    variant={'secondary'}
                    onClick={openSeparateOrderDialog}
                    disabled={isAvailableToSeparateOrder}
                >
                    {translate('Separate account')}
                </Button>
            </SecuredContent>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
