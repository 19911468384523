import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { ShowOrderQrCodeDialog } from 'src/components/dialog/order/ShowOrderQrCodeDialog';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';

export function ShowOrderQrCodeButton({ order }: Props): React.ReactElement | null {
    const [showQrDialogState, setShowQrDialogState] = useState(false);

    const showQrDialog = () => {
        setShowQrDialogState(true);
    };

    const hideQrDialog = () => {
        setShowQrDialogState(false);
    };

    if (!order.rappiQrCode || !order.rappiValidationCode) return null;

    return (
        <>
            <ShowOrderQrCodeDialog open={showQrDialogState} onClose={hideQrDialog} qrCode={order.rappiQrCode} validationCode={order.rappiValidationCode} />
            <Button variant='secondary' onClick={showQrDialog}>
                {translate('Show QR')}
            </Button>
        </>
    );
}

type Props = {
    order: OrderVm;
};
