import { useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { CreditNoteOfCancelledOrderDialog } from 'src/components/dialog/order/CreditNoteOfCancelledOrderDialog';
import { RefundSuppliesOfCancelledOrderDialog } from 'src/components/dialog/order/RefundSuppliesOfCancelledOrderDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { Text } from 'src/components/Text';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useAskForRolePermissionAuthorization } from 'src/modules/order/utils/useAskForRolePermissionAuthorization';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isPeru } from 'src/utils/country/isPeru';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function CancelOrderButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const confirmDialog = useConfirmDialog();
    const [userHasRolePermission] = useUserHasRolePermission();
    const askForRolePermissionAuthorization = useAskForRolePermissionAuthorization();

    const [refundSuppliesDialogState, setRefundSuppliesDialogState] = useState({ open: false });
    const [creditNoteOfCancelledOrderDialogState, setCreditNoteOfCancelledOrderDialogState] = useState({ open: false });

    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);

    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    const isPosCompletedOrder = isCompletedOrder(order.orderStatus) && isPosApp(order.app);
    const shouldRenderButton = isAcceptedOrder(order.orderStatus) || isPendingOrder(order.orderStatus) || isPosCompletedOrder || isClosedOrder(order.orderStatus);

    const handleCancelOrder = async () => {
        if (!isClosedOrder(order.orderStatus) || userHasRolePermission(RolePermissions.OPEN_CLOSED_ORDERS)) {
            cancelOrder();
            return;
        }

        const isAuthorized = await askForRolePermissionAuthorization({
            rolePermission: RolePermissions.OPEN_CLOSED_ORDERS,
            title: translate('Printed partial account'),
            subtitle: translate('Unblock the order with an authorized PIN'),
        });
        if (!isAuthorized) return;

        cancelOrder();
    };

    const cancelOrder = async () => {
        const rejectOrder = await confirmDialog({
            content: translate('Are you sure you want to reject this order? Please note that the customer has been notified that the order has been accepted already.'),
            buttonText: translate('Continue'),
            cancelButtonText: translate('Cancel'),
        });

        if (!rejectOrder) return;

        if (order.recipeIds?.length && inventoryEnabled) {
            return openRefundSuppliesDialog();
        }

        if (order.invoiced && isPeru(order.country)) return openCreditNoteDialog();

        openRejectOrderDialog({
            orderId: order.orderId,
            onReject: onSuccess,
        });
    };

    const openRefundSuppliesDialog = () => setRefundSuppliesDialogState({ open: true });

    const closeRefundSuppliesDialog = () => {
        setRefundSuppliesDialogState({ open: false });
    };

    const openCreditNoteDialog = () => setCreditNoteOfCancelledOrderDialogState({ open: true });

    const closeCreditNoteDialog = () => setCreditNoteOfCancelledOrderDialogState({ open: false });

    if (!shouldRenderButton) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.CANCEL_ORDERS}>
            <RefundSuppliesOfCancelledOrderDialog open={refundSuppliesDialogState.open} order={order} onClose={closeRefundSuppliesDialog} />
            <CreditNoteOfCancelledOrderDialog open={creditNoteOfCancelledOrderDialogState.open} onSuccess={onSuccess} order={order} onClose={closeCreditNoteDialog} />
            <Button secondary onClick={handleCancelOrder} disabled={disabled} color={'red'}>
                <Text error>{isNewOrder(order.orderStatus) ? translate('Reject order') : translate('Cancel order')}</Text>
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
