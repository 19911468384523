import { Button, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { Form, FormCurrencyNumberStringField, FormImageUpload, FormTextField } from '@pidedirecto/ui/form';
import { useForm, useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { createOrderIncidentApi } from 'src/api/letseatadmin/orderIncident/createOrderIncidentApi';
import { isOrderIncidentValidApi } from 'src/api/letseatadmin/orderIncident/isOrderIncidentValidApi';
import { FormOrderIncidentTypesSelect } from 'src/components/form/FormOrderIncidentTypesSelect';
import { ImageTypes } from 'src/constants/ImageType';
import { OrderIncidentType } from 'src/constants/OrderIncidentType';
import { translate } from 'src/i18n/translate';
import { uploadImage } from 'src/services/image/uploadImage';
import { OrderId, ZendeskId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBugIncident } from 'src/utils/orderIncident/isBugIncident';
import { isDamagedOrderItemReturnedToRestaurantIncident } from 'src/utils/orderIncident/isDamagedOrderItemReturnedToRestaurantIncident';
import { isDamagedOrderItemsIncident } from 'src/utils/orderIncident/isDamagedOrderItemsIncident';
import { isIncorrectOrderIncident } from 'src/utils/orderIncident/isIncorrectOrderIncident';
import { isRestaurantWasteOrderIncident } from 'src/utils/orderIncident/isRestaurantWasteOrderIncident';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateOrderIncidentDialog({ open, order, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const username = useSelector((state) => state.authentication.username);
    const managerUserId = useSelector((state) => state.authentication.managerUserId);

    const orderIncidentType = form.watch('orderIncidentType');

    useEffect(() => {
        if (order) {
            form.reset({
                orderId: order.orderId,
            });
        }
    }, [order]);

    const closeDialog = () => {
        if (loading) return;
        onClose();
    };

    const createOrderIncident = async (form: FormFields) => {
        setLoading(true);
        const isOrderIncidentValid = await validateOrderIncidentType(form.orderIncidentType, form.refundAmount);
        if (!isOrderIncidentValid) {
            setLoading(false);
            return;
        }

        const response = await createOrderIncidentApi({
            orderId: form.orderId,
            managerUserId,
            zendeskId: form.zendeskId,
            orderIncidentType: form.orderIncidentType,
            refundAmount: form.refundAmount,
            imageUrl: form.imageUrl,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onSuccess?.();
    };

    const validateOrderIncidentType = async (orderIncidentType: OrderIncidentType, refundAmount: string) => {
        if (!order) return true;

        const response = await isOrderIncidentValidApi({
            orderId: order.orderId,
            orderIncidentType,
            refundAmount,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return false;
        }

        if (!response.data) notification({ message: translate('The incident reason is not valid for this order') });
        return response.data;
    };

    const isOrderIncidentEvidenceRequired = () => {
        return (
            isIncorrectOrderIncident(orderIncidentType) ||
            isDamagedOrderItemReturnedToRestaurantIncident(orderIncidentType) ||
            isDamagedOrderItemsIncident(orderIncidentType) ||
            isRestaurantWasteOrderIncident(orderIncidentType) ||
            isBugIncident(orderIncidentType)
        );
    };

    const getMinimumRefundableAmount = () => {
        if (!order) return;
        return order.isProtectedOrder ? 500 : 1;
    };

    const getMaximumRefundableAmount = () => {
        if (!order) return;
        return order.isProtectedOrder ? 5000 : 500;
    };

    const handleUploadImage = async (params: ImageData) => {
        const image = await uploadImage({
            ...params,
            imageType: ImageTypes.RESTAURANT,
        });
        return image.imageUrl ?? '';
    };

    return (
        <Dialog open={open} loading={loading} onClose={closeDialog} title={translate('Register order with incident')}>
            <Form form={form} onSubmit={createOrderIncident}>
                <DialogContent>
                    <FormTextField
                        name='orderId'
                        label={translate('Order Id')}
                        disabled={!!order}
                        required={{ value: true, message: translate('This field is required') }}
                        helperText={!!order ? undefined : translate('This field should be the whole order id')}
                    />
                    <FormTextField name='zendeskId' label={translate('Zendesk ticket')} required={{ value: true, message: translate('This field is required') }} />
                    <FormCurrencyNumberStringField
                        name='refundAmount'
                        label={translate('Refund amount')}
                        country={order?.country as any}
                        required={{ value: true, message: translate('This field is required') }}
                        rules={{
                            min: {
                                value: getMinimumRefundableAmount(),
                                message: translate('Must be number equal to or larger than @number', { number: getMinimumRefundableAmount() }),
                            },
                            max: {
                                value: getMaximumRefundableAmount(),
                                message: translate('Must be number equal to or less than @number', { number: getMaximumRefundableAmount() }),
                            },
                        }}
                    />
                    <FormOrderIncidentTypesSelect name='orderIncidentType' label={translate('Incident reason')} required />
                    <FormTextField name='managerUsername' label={translate('User')} disabled required={{ value: true, message: translate('This field is required') }} defaultValue={username} />
                    <FormImageUpload
                        name='imageUrl'
                        label={translate('Evidence')}
                        required={{ value: isOrderIncidentEvidenceRequired(), message: translate('This field is required') }}
                        transformer={handleUploadImage}
                    >
                        <Text>{translate('Drag or upload an image here')}</Text>
                    </FormImageUpload>
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={closeDialog}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Create')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    order?: OrderVm;
    onClose: () => void;
    onSuccess?: () => void;
};

type FormFields = {
    orderId: OrderId;
    zendeskId: ZendeskId;
    refundAmount: string;
    orderIncidentType: OrderIncidentType;
    managerUsername: string;
    imageUrl: string;
};

type ImageData = {
    fileName: string;
    imageBlob: Blob;
    type: string;
};
