import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CashRegisterId, RestaurantId } from 'src/types/Id';

export async function sendClosedCashRegisterReportEmailApi(request: SendClosedCashRegisterReportEmailApiRequest): ApiSauceResponse<void> {
    return pidedirectoReportsMethod('/sendClosedCashRegisterReportEmailApi', request);
}

type SendClosedCashRegisterReportEmailApiRequest = {
    restaurantId: RestaurantId;
    cashRegisterId: CashRegisterId;
};
