import { Button } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useState } from 'react';
import { openClosedOrderApi } from 'src/api/letseatmanager/order/openClosedOrderApi';
import { app2 } from 'src/app2';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useAskForRolePermissionAuthorization } from 'src/modules/order/utils/useAskForRolePermissionAuthorization';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function OpenClosedOrderButton({ order, classes, onSuccess }: Props): React.ReactElement | null {
    const notification = useNotification();
    const [userHasRolePermission] = useUserHasRolePermission();
    const askForRolePermissionAuthorization = useAskForRolePermissionAuthorization();

    const [loading, setLoading] = useState(false);

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const updateOrder = useAction(app2.actions.updateOrder);

    const openClosedOrder = async () => {
        setLoading(true);
        const response = await openClosedOrderApi({ orderId: order.orderId });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        updateOrder({ order: response.data });
        onSuccess?.();
        notification({ message: translate('Unlocked order') });
    };

    const handleOpenClosedOrder = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (brandOpened) {
            notification({ message: translate('Select a restaurant to edit orders') });
            return;
        }

        if (userHasRolePermission(RolePermissions.OPEN_CLOSED_ORDERS)) {
            await openClosedOrder();
            return;
        }

        const isAuthorized = await askForRolePermissionAuthorization({
            rolePermission: RolePermissions.OPEN_CLOSED_ORDERS,
            title: translate('Printed partial account'),
            subtitle: translate('Unblock the order with an authorized PIN'),
        });

        if (!isAuthorized) return;

        await openClosedOrder();
    };

    if (!isClosedOrder(order.orderStatus)) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.EDIT_ORDERS}>
            <Button classes={{ button: classes?.button }} variant={'outline'} disabled={loading} onClick={handleOpenClosedOrder}>
                {translate('Unlock')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    classes?: { button: string };
    onSuccess?: () => void;
};
