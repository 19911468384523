import * as React from 'react';
import { Qr } from 'src/components/Qr';
import { useSelector } from 'src/utils/react/useSelector';
import { getAmbitMenuWebUrl } from 'src/utils/url/getAmbitMenuWebUrl';

export function RestaurantQr(): React.ReactElement | null {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const appLoading = useSelector((state) => state.app.loading);

    const shouldShowQrToUser = viewUser || internalUser;
    const showQr = shouldShowQrToUser && restaurant.webOrdersEnabled && restaurant.urlSubdomain && restaurant.urlPathname && !brandOpened && !appLoading;
    const restaurantUrl = `${restaurant.urlSubdomain}.${getAmbitMenuWebUrl()}/${restaurant.urlPathname}`;

    if (!showQr) return null;

    return <Qr code={restaurantUrl} />;
}
