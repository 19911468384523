import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Loader } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createDriverRequestOrderApi } from 'src/api/letseatmanager/order/createDriverRequestOrderApi';
import { getDeliveryEstimateApi } from 'src/api/letseatmanager/order/getDeliveryEstimateApi';
import { createPaymentLinkApi } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import { RestaurantCustomerAddress } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersAddressOptionsApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAddress } from 'src/components/form/FormAddress';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormDeliveryAddressAutocompleteWithMap } from 'src/components/form/FormDeliveryAddressAutocompleteWithMap';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPhoneNumberCustomerAddressAutocomplete } from 'src/components/form/FormPhoneNumberCustomerAddressAutocomplete';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { FormTextField } from 'src/components/form/FormTextField';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { PickupTimeType, PickupTimeTypes } from 'src/constants/PickupTimeType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { appReducer } from 'src/reducers/appReducer';
import { posReducer } from 'src/reducers/posReducer';
import { getCountryCallingCode } from 'src/scenes/letseatmanager/phoneNumber/getCountryCallingCode';
import { getDriverArrivesAtStoreTimeColor } from 'src/services/order/getDriverArrivesAtStoreTimeColor';
import { getDriverArrivesAtStoreTimeInterval } from 'src/services/order/getDriverArrivesAtStoreTimeInterval';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { DeliveryEstimateVm } from 'src/types/DeliveryEstimateVm';
import { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { OrderId } from 'src/types/Id';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { emptyArrayToUndefined } from 'src/utils/array/emptyArrayToUndefined';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { isEmpty } from 'src/utils/object/isEmpty';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { requireValue } from 'src/utils/require/requireValue';

export function CreateUruguayanManualOrderDialog(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const [userHasRolePermission] = useUserHasRolePermission();

    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [address, setAddress] = useState('');
    const [customerAddress, setCustomerAddress] = useState<string | null>(null);
    const [customerAddresses, setCustomerAddresses] = useState<Array<RestaurantCustomerAddress> | undefined>(undefined);
    const [deliveryLocation, setDeliveryLocation] = useState<GeoJsonPoint | undefined>(undefined);
    const [deliveryEstimate, setDeliveryEstimate] = useState<DeliveryEstimateVm | undefined>(undefined);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const clabe = useSelector((state) => state.app.restaurant.clabe);
    const bank = useSelector((state) => state.app.restaurant.bank);
    const restaurant = useSelector((state) => state.app.restaurant);
    const open = useSelector((state) => state.app2.createManualOrderDeliveryDialog.open);
    const isForPos = useSelector((state) => state.app2.createManualOrderDeliveryDialog.isForPos);
    const onSuccess = useSelector((state) => state.app2.createManualOrderDeliveryDialog.onSuccess);
    const driverArrivesAtStoreTime = useSelector((state) => state.app2.driverArrivesAtStoreTime);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const total = useSelector((state) => state.pos.payment?.total);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const quickActionMessage = useSelector((state) => state.app2.quickActionMessage);
    const pideDirectoProtectionCost = useSelector((state) => state.app.restaurant?.pideDirectoProtectionCost);
    const pideDirectoProtectionMinimumOrderAmount = useSelector((state) => state.app.restaurant?.pideDirectoProtectionMinimumOrderAmount);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const close = useAction(app2.actions.closeCreateManualOrderDeliveryDialog);
    const updateOrder = useAction(app2.actions.updateOrder);
    const setPosDriverRequest = useAction(posReducer.actions.setPosDriverRequest);
    const setCustomerId = useAction(posReducer.actions.setCustomerId);

    const customerDeliveryCostFixed = restaurant?.customerDeliveryCostFixed?.toString() ?? '0';
    const country = restaurant?.country;

    const form = useForm();
    const { control, setValue } = form;

    const deliveryPaidByRestaurant = useWatch({ name: 'deliveryPaidByRestaurant', control, defaultValue: restaurant.deliveryPaidByRestaurant ?? false });
    const deliveryPaidByPideDirecto = useWatch({ name: 'deliveryPaidByPideDirecto', control, defaultValue: false });
    const price = useWatch({ name: 'price', control, defaultValue: '0' });
    const deliveryCost = useWatch({ name: 'deliveryCost', control, defaultValue: '0' });
    const q = useWatch({ name: 'q', control, defaultValue: {} });
    const pickupTimeType = useWatch<PickupTimeType>({ name: 'pickupTimeType', control, defaultValue: PickupTimeTypes.ASAP });
    const addPaymentLink = useWatch({ name: 'addPaymentLink', control, defaultValue: false });
    const paymentLinkAmount = useWatch({ name: 'paymentLinkAmount', control, defaultValue: 0 });
    const phoneNumber = useWatch({ name: 'phoneNumber', control, defaultValue: '' });
    const isBigOrder = useWatch({ name: 'isBigOrder', control, defaultValue: false });
    const isProtectedOrder = useWatch({
        name: 'isProtectedOrder',
        control,
        defaultValue: false,
    });
    const directoProtectionPaidByCustomer = useWatch({ name: 'directoProtectionPaidByCustomer', control, defaultValue: false });
    const isExternalCardPayment = useWatch({ name: 'isExternalCardPayment', control, defaultValue: false });

    const paymentLinkEnabled = clabe && bank && restaurant?.paymentLinksEnabled;
    const useNonGeoLocatedDeliveryAddressesEnabled = restaurant?.useNonGeoLocatedDeliveryAddressesEnabled;
    const customOrderPriceLimitForManualOrder = restaurant?.orderPriceLimitForManualOrder ? BigNumber(restaurant?.orderPriceLimitForManualOrder).toNumber() : null;
    const externalCardPaymentsForManualOrdersEnabled = restaurant?.externalCardPaymentsForManualOrdersEnabled;
    const showDeliveryPaidByPideDirecto = internalUser && !useNonGeoLocatedDeliveryAddressesEnabled;
    const paymentLinkRestaurantPaymentMethod = restaurant?.restaurantPaymentMethods?.find((restaurantPaymentMethod: RestaurantPaymentMethodVm) =>
        isPaymentLinkPayment(restaurantPaymentMethod.paymentMethod),
    );

    const customerPhoneNumberRequired = () => {
        if (!restaurant.externalDelivery) {
            return true;
        }
        if (restaurant.externalDelivery && restaurant.customerPhoneNumberRequiredForExternalDelivery) {
            return true;
        }
        if (restaurant.externalDelivery && !restaurant.customerPhoneNumberRequiredForExternalDelivery) {
            return false;
        }
        return false;
    };

    const disabled = loading || !price || (customerPhoneNumberRequired() && !phoneNumber);

    useEffect(() => {
        if (!deliveryLocation) {
            setDeliveryEstimate(undefined);
            return;
        }

        async function fetchDeliveryEstimate() {
            setLoading(true);
            const response = await getDeliveryEstimateApi({ restaurantId: restaurant.restaurantId, deliveryLocation: deliveryLocation! });
            setLoading(false);
            if (!response.ok) {
                setDeliveryEstimate(undefined);
                return;
            }
            if (cancelled) return;
            setDeliveryEstimate(response.data);
        }
        let cancelled = false;
        fetchDeliveryEstimate();
        return () => {
            cancelled = true;
        };
    }, [deliveryLocation]);

    useEffect(() => {
        if (address && q !== null) {
            setDeliveryLocation((q as any).location);
        }
    }, [q]);

    useEffect(() => {
        if (deliveryCost && total) setValue('total', BigNumber(total).plus(deliveryCost).toString());
    }, [deliveryCost, total, price]);

    useEffect(() => {
        if (driverRequest && open) {
            setTimeout(() => {
                setValue('firstName', driverRequest.firstName);
                setValue('lastName', driverRequest.lastName);
                setValue('phoneNumber', driverRequest.phoneNumber);
                setValue('q', {
                    location: driverRequest.address.location,
                    street: driverRequest.address.street,
                    googlePlaceId: driverRequest.address.googlePlaceId,
                    formattedAddress: driverRequest.address.formattedAddress,
                    name: driverRequest.address.name,
                });
                setAddress(driverRequest.address.formattedAddress ?? '');
                setValue('other', driverRequest.address.other);
                setValue('instructions', driverRequest.address.instructions);
                setValue('deliveryPaidByRestaurant', driverRequest.deliveryPaidByRestaurant);
                setValue('deliveryPaidByPideDirecto', driverRequest.deliveryPaidByPideDirecto);
                setValue('pickupTimeType', driverRequest.pickupTimeType);
                setValue('pickupTime', driverRequest.pickupTime);
                setValue('deliveryCost', driverRequest.deliveryCost);
                setDeliveryLocation(driverRequest.address.location);
            }, 500);
        } else {
            setAddress('');
            setDeliveryLocation(undefined);
        }
    }, [driverRequest, open]);

    useEffect(() => {
        updateTotal();
    }, [deliveryPaidByRestaurant, price, deliveryPaidByPideDirecto, directoProtectionPaidByCustomer]);

    useEffect(() => {
        if (restaurant.invoiceRestaurantDeliveryCost) {
            form.setValue('deliveryCost', deliveryEstimate?.deliveryCost ?? 0);
        } else {
            form.setValue('deliveryCost', deliveryEstimate?.deliveryCost ?? null);
        }
    }, [deliveryEstimate]);

    useEffect(() => {
        if (restaurant.invoiceRestaurantDeliveryCost) {
            form.setValue('deliveryCost', deliveryEstimate?.deliveryCost ? translate('0 (@amount INVOICED)', { amount: formatAsCurrencyNumber(deliveryEstimate?.deliveryCost ?? 0) }) : null);
        } else {
            form.setValue('deliveryCost', deliveryEstimate?.deliveryCost ?? null);
        }
    }, [deliveryEstimate]);

    const handlePressCancel = async () => {
        setDeliveryEstimate(undefined);
        close();
        clearCustomerAddress();
    };

    const onSelectPhoneNumberAutocomplete = (restaurantCustomer: any) => {
        setCustomerName(restaurantCustomer);
        if (restaurantCustomer.customerId) form.setValue('customerId', restaurantCustomer.customerId);

        form.setValue('phoneNumber', restaurantCustomer.mobileNumber);
        if (restaurantCustomer.email) form.setValue('email', restaurantCustomer.email);
        if (restaurantCustomer.note) form.setValue('customerNote', restaurantCustomer.note);

        if (restaurantCustomer.addresses?.length) {
            setCustomerAddresses(emptyArrayToUndefined(restaurantCustomer.addresses));

            const defaultCustomerAddress = restaurantCustomer.addresses?.[0];
            if (isEmpty(defaultCustomerAddress)) return;

            onChangeAddress(defaultCustomerAddress);
        }
    };

    const onChangeAddress = (restaurantCustomerAddress: RestaurantCustomerAddress) => {
        if (restaurantCustomerAddress.street) form.setValue('street', restaurantCustomerAddress.street);
        if (restaurantCustomerAddress.number) form.setValue('number', restaurantCustomerAddress.number);

        if (!isEmpty(restaurantCustomerAddress)) {
            form.setValue('q', useNonGeoLocatedDeliveryAddressesEnabled ? restaurantCustomerAddress.street : restaurantCustomerAddress, { shouldValidate: true });
        }

        if (restaurantCustomerAddress.location) setDeliveryLocation(restaurantCustomerAddress.location);
        if (restaurantCustomerAddress.formattedAddress) setAddress(restaurantCustomerAddress.formattedAddress);
        if (!isEmpty(restaurantCustomerAddress)) setCustomerAddress(restaurantCustomerAddress.street);

        if (restaurantCustomerAddress.other) form.setValue('other', restaurantCustomerAddress.other, { shouldValidate: true });
        if (restaurantCustomerAddress.instructions) form.setValue('instructions', restaurantCustomerAddress.instructions, { shouldValidate: true });
    };

    const setCustomerName = (restaurantCustomer: any) => {
        if (restaurantCustomer.firstName || restaurantCustomer.lastName) {
            if (restaurantCustomer.firstName) form.setValue('firstName', restaurantCustomer.firstName);
            if (restaurantCustomer.lastName) form.setValue('lastName', restaurantCustomer.lastName);
            return;
        }

        if (!restaurantCustomer.name) return;

        const names = restaurantCustomer.name.split(' ');
        form.setValue('firstName', names[0]);

        const lastName = names.slice(1).join(' ');
        form.setValue('lastName', lastName);
    };

    const clearCustomerAddress = () => {
        setAddress('');
        setCustomerAddress('');
    };

    const createPaymentLink = async (orderId: OrderId) => {
        const response = await createPaymentLinkApi({
            amount: paymentLinkAmount,
            restaurantId,
            orderId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        copyTextToClipboard(response.data.url);
    };

    const onSubmit = async (form: any) => {
        if (loading) return;
        if (!deliveryEstimate && !useNonGeoLocatedDeliveryAddressesEnabled) return;
        if (useNonGeoLocatedDeliveryAddressesEnabled) {
            form.deliveryPaidByRestaurant = false;
            form.deliveryPaidByPideDirecto = false;
        }

        const paymentMethod = addPaymentLink ? PaymentMethods.PAYMENT_LINK : isExternalCardPayment ? PaymentMethods.CREDIT_CARD : PaymentMethods.CASH;

        const request = {
            restaurantId: restaurantId,
            firstName: form.firstName,
            lastName: form.lastName,
            phoneNumber: form.phoneNumber,
            address: {
                location: requireValue(form.q).location,
                street: !useNonGeoLocatedDeliveryAddressesEnabled ? form.q.street : form.q,
                number: form.number,
                neighborhood: form.neighborhood,
                postalCode: form.postalCode,
                other: form.other,
                instructions: form.instructions,
                googlePlaceId: requireValue(form.q).googlePlaceId,
                formattedAddress: requireValue(form.q).formattedAddress,
                name: form.q.name, // sent for debugging reasons
            },
            price: isForPos ? form.price : requireValue(form.price),
            deliveryCost: form.deliveryCost,
            deliveryEstimateId: !useNonGeoLocatedDeliveryAddressesEnabled && deliveryEstimate ? deliveryEstimate.deliveryEstimateId : undefined,
            deliveryPaidByRestaurant: form.deliveryPaidByRestaurant,
            deliveryPaidByPideDirecto: form.deliveryPaidByPideDirecto,
            pickupTimeType: form.pickupTimeType,
            pickupTime: form.pickupTime,
            paymentMethod: paymentMethod,
            isBigOrder: isBigOrder,
            externalCardPayment: form.isExternalCardPayment,
            isProtectedOrder: form.isProtectedOrder,
            directoProtectionPaidByCustomer: form.directoProtectionPaidByCustomer,
            pideDirectoProtectionCost,
            pideDirectoProtectionMinimumOrderAmount,
        } as const;

        if (isForPos) {
            if (!!form.customerId) setCustomerId(form.customerId);
            setPosDriverRequest({ ...request, deliveryCost, customerNote: form.customerNote, email: form.email });
            setDeliveryEstimate(undefined);
            clearCustomerAddress();
            close();
            return;
        }

        setLoading(true);
        setSubmitting(true);

        const response = await createDriverRequestOrderApi(request);

        if (!response.ok) {
            setLoading(false);
            setSubmitting(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        const order = response.data;
        updateOrder({ order });

        if (addPaymentLink) {
            await createPaymentLink(order.orderId);
        }

        setDeliveryEstimate(undefined);
        clearCustomerAddress();
        setLoading(false);
        setSubmitting(false);
        close();
        if (addPaymentLink) showSnackbar({ message: translate('Payment Link copied to clipboard') });
        onSuccess && onSuccess(order);
    };

    const updateTotal = () => {
        let total;

        if (form.getValues('deliveryPaidByRestaurant')) {
            total = BigNumber(form.getValues('price') || 0).toString();
        } else {
            total = BigNumber(form.getValues('price') || 0)
                .plus(deliveryEstimate?.customerDeliveryCost || 0)
                .toString();
        }

        if (form.getValues('deliveryPaidByPideDirecto')) {
            total = BigNumber(form.getValues('price') || 0).toString();
        }

        if (form.getValues('directoProtectionPaidByCustomer')) {
            total = BigNumber(form.getValues('total') || 0)
                .plus(pideDirectoProtectionCost ?? '0')
                .toString();
        }

        form.setValue('total', total);
        form.setValue('paymentLinkAmount', total);
    };

    const isRestaurantPaymentLinkEnabled = () => {
        if (paymentLinkRestaurantPaymentMethod && !paymentLinkRestaurantPaymentMethod?.enabled && !paymentLinkRestaurantPaymentMethod.orderTypes.includes(OrderTypes.DELIVERY_ORDER)) return false;
        return true;
    };

    return (
        <Dialog title={translate('Request a Driver')} open={open} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <h1 style={{ color: getDriverArrivesAtStoreTimeColor(driverArrivesAtStoreTime) }} className={classes.driverArrivesAtStoreTimeTitle}>
                    {translate('Driver arrives at store: approx @minutes minutes', { minutes: getDriverArrivesAtStoreTimeInterval(driverArrivesAtStoreTime) })}
                </h1>
                {!!quickActionMessage && <h2 className={classes.quickActionMessage}>{quickActionMessage}</h2>}
                <FormHiddenField name='customerNote' />
                <FormHiddenField name='customerId' />
                <FormHiddenField name='email' />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className={classes.sectionTitle}>{translate('Client details')}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <FormPhoneNumberCustomerAddressAutocomplete
                            name='phoneNumber'
                            label={translate('Phone Number')}
                            required={customerPhoneNumberRequired()}
                            onSelect={onSelectPhoneNumberAutocomplete}
                            defaultValue={driverRequest?.phoneNumber || getCountryCallingCode(country)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField name='firstName' label={translate('First Name')} disabled={loading} required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField name='lastName' label={translate('Last Name')} disabled={loading} />
                    </Grid>
                    {useNonGeoLocatedDeliveryAddressesEnabled && (
                        <Grid item xs={12}>
                            <FormAddress name='q' label={translate('Street address and building number')} disabled={loading} required fullWidth />
                        </Grid>
                    )}
                    {!useNonGeoLocatedDeliveryAddressesEnabled && (
                        <Grid item xs={12}>
                            <FormDeliveryAddressAutocompleteWithMap
                                name='q'
                                label={translate('Find location with Google Maps')}
                                restaurant={restaurant}
                                disabled={loading}
                                required
                                fullWidth
                                customerAddress={customerAddress}
                                customerAddresses={customerAddresses}
                                deliveryEstimate={deliveryEstimate}
                                onChangeAddress={onChangeAddress}
                            />
                        </Grid>
                    )}
                    <Grid item sm={12} md={6}>
                        <FormTextField name='other' label={translate('Staircase/apartment/floor/other')} disabled={loading} />
                    </Grid>
                    <div className={classes.divider}></div>
                    <Grid item xs={12}>
                        <p className={classes.sectionTitle}>{translate('Order details')}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='instructions' label={translate('Instructions for carrier')} disabled={loading} defaultValue={''} />
                    </Grid>
                    {(!isForPos || total) && (
                        <Grid item md={4} xs={12}>
                            <FormCurrencyNumberStringField
                                name='price'
                                label={translate('Cost of dishes')}
                                disabled={loading || isForPos}
                                required
                                defaultValue={isForPos ? total : ''}
                                max={customOrderPriceLimitForManualOrder ?? undefined}
                                min={0}
                            />
                        </Grid>
                    )}
                    <Grid item md={4} xs={12}>
                        <FormTextField
                            name='deliveryCost'
                            label={translate('Delivery Cost')}
                            defaultValue={useNonGeoLocatedDeliveryAddressesEnabled ? customerDeliveryCostFixed : ''}
                            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                            disabled={!useNonGeoLocatedDeliveryAddressesEnabled}
                        />
                        {restaurant?.invoiceRestaurantDeliveryCost && <div className={classes.invoiceRestaurantWarning}>{translate('(INVOICED)')}</div>}
                    </Grid>

                    {price && isProtectedOrder && (
                        <Grid item md={4} xs={12}>
                            <FormCurrencyNumberStringField name='pideDirectoProtectionCost' label={translate('Directo Protection')} defaultValue={pideDirectoProtectionCost ?? '0'} disabled />
                        </Grid>
                    )}

                    {(!isForPos || total) && (
                        <Grid item md={4} xs={12}>
                            <FormCurrencyNumberStringField name='total' label={translate('Total')} disabled defaultValue={isForPos ? BigNumber(total).plus(deliveryCost).toString() : ''} />
                        </Grid>
                    )}

                    {price && BigNumber(price).isGreaterThan(pideDirectoProtectionMinimumOrderAmount ?? 500) && (
                        <Grid item xs={12}>
                            <FormCheckbox
                                name='isProtectedOrder'
                                label={translate('Directo Protection')}
                                disabled={loading}
                                tooltipText={translate('Check this option if you want to protect your order')}
                                defaultValue={true}
                            />
                            {!isProtectedOrder && <span className={classes.warning}>{translate('Your order will be protected for an amount up to @amount', { amount: 500 })}</span>}
                        </Grid>
                    )}

                    {isProtectedOrder && (
                        <Grid item xs={12}>
                            <FormCheckbox
                                name='directoProtectionPaidByCustomer'
                                label={translate('Directo protection is paid by customer')}
                                disabled={loading}
                                tooltipText={translate('Check this option if you want to customer pays the Directo Protection')}
                                defaultValue={false}
                            />
                        </Grid>
                    )}

                    {externalCardPaymentsForManualOrdersEnabled && !isForPos && (
                        <Grid item xs={12}>
                            <FormCheckbox
                                name='isExternalCardPayment'
                                label={translate('Manual order already paid by customer')}
                                disabled={loading}
                                tooltipText={translate('Check this option if you want to customer pays the Directo Protection')}
                                defaultValue={restaurant?.externalCardPaymentsForManualOrdersAsDefaultEnabled}
                            />
                        </Grid>
                    )}

                    {!isForPos && (
                        <>
                            {paymentLinkEnabled && (
                                <Grid item xs={12}>
                                    <FormCheckbox name='addPaymentLink' label={translate('Create payment link with this amount')} defaultValue={false} disabled={Boolean(!price)} />
                                </Grid>
                            )}
                            {isRestaurantPaymentLinkEnabled() && (
                                <Grid item xs={12}>
                                    <FormCheckbox name='addPaymentLink' label={translate('Create payment link with this amount')} defaultValue={false} disabled={Boolean(!price)} />
                                </Grid>
                            )}
                        </>
                    )}

                    <Grid item sm={12} className={isForPos || !addPaymentLink ? classes.paymentAmount : classes.paymentAmountContainer}>
                        <FormNumberField name='paymentLinkAmount' label={translate('Amount')} disabled />
                    </Grid>
                    {!useNonGeoLocatedDeliveryAddressesEnabled && userHasRolePermission(RolePermissions.VIEW_CHECK_DELIVERY_PAID_BY_RESTAURANT) && (
                        <Grid item xs={12}>
                            <FormCheckbox
                                name='deliveryPaidByRestaurant'
                                label={translate('Delivery is paid by restaurant')}
                                defaultValue={restaurant.deliveryPaidByRestaurant ?? false}
                                disabled={restaurant.deliveryPaidByRestaurant || loading}
                            />
                        </Grid>
                    )}
                    {showDeliveryPaidByPideDirecto && (
                        <Grid item xs={12}>
                            <FormCheckbox disabled={loading} name='deliveryPaidByPideDirecto' label={translate('Delivery is paid by PideDirecto')} />
                        </Grid>
                    )}
                    <div className={classes.divider}></div>
                    <Grid item xs={12}>
                        <FormRadioGroup name='pickupTimeType' label={translate('Driver needed')} row defaultValue={PickupTimeTypes.ASAP}>
                            <FormRadio label={translate('ASAP')} value={PickupTimeTypes.ASAP} />
                            <FormRadio label={translate('Specify Time')} value={PickupTimeTypes.PLANNED} />
                        </FormRadioGroup>
                        <Grid item lg={6} xs={12} style={{ marginTop: 8 }}>
                            {pickupTimeType === PickupTimeTypes.PLANNED && <FormDateTimePicker name='pickupTime' label={translate('Driver Pickup Time')} required />}
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox
                                name='isBigOrder'
                                label={translate('It is a big order')}
                                disabled={loading}
                                tooltipText={translate('Check this option if you require a larger vehicle for this order. The time and cost could be greater,the time and cost could be greater.')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions className={classes.dialogActions}>
                    <Button larger={posInterfaceLargerEnabled && isForPos} classes={{ button: classes.button }} secondary onClick={handlePressCancel} disabled={loading}>
                        {translate(isForPos ? 'Close' : 'Cancel')}
                    </Button>
                    <Button larger={posInterfaceLargerEnabled && isForPos} classes={{ button: classes.button }} type='submit' disabled={disabled}>
                        <div>{submitting ? <Loader size={12} loading={true} /> : translate(isForPos ? 'Save Address Info' : 'Request')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 720,
            padding: 40,
        },
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: 24,
        },
    },
    terms: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('sm')]: {
            gap: 6,
            flexDirection: 'row',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        padding: '0px 42px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 24px',
        },
    },
    driverArrivesAtStoreTimeTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#1B5764',
        margin: 0,
        marginBottom: 18,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    disabled: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 6,
        height: 42,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        [theme.breakpoints.down('sm')]: {
            width: '60vw',
        },
    },
    quickActionMessage: {
        fontFamily: theme.typography.regular,
        textAlign: 'center',
        fontSize: 16,
        marginTop: 0,
        color: '#444444',
    },
    paymentAmount: {
        display: 'none',
    },
    paymentAmountContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionTitle: {
        color: '#0D3037',
        fontSize: 16,
        fontFamily: theme.typography.semiBold,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    divider: {
        height: '1px',
        width: '100%',
        borderTop: '1px solid #d9d9d9',
        marginTop: 18,
        marginBottom: 18,
    },
    link: {
        margin: 0,
        fontFamily: theme.typography.regular,
        color: '#198074',
        fontSize: 14,
    },
    invoiceRestaurantWarning: {
        color: '#999999',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 10,
    },
    button: {
        width: '100%',
    },
    warning: {
        color: theme.palette.surface.contrast,
        fontSize: 12,
        textAlign: 'center',
        width: '100%',
        maxWidth: '320px',
        fontFamily: theme.typography.regular,
    },
}));
