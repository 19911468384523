import { makeStyles } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, Input, Text } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import { CashIcon, CreditCardIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { chargeKioskOrderApi } from 'src/api/letseatmanager/order/chargeKioskOrderApi';
import { app2 } from 'src/app2';
import { CurrencyNumericPad } from 'src/components/CurrencyNumericPad';
import { PosPaymentResumeItem } from 'src/components/pos/PosPaymentResumeItem';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { lessThanZeroToUndefined } from 'src/utils/number/lessThanZeroToUndefined';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChargeKioskOrderDialog({ open, order, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const notification = useNotification();
    const { payWithPaymentTerminal } = usePayWithPaymentTerminal();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState<{ paymentMethod: PaymentMethod; amount: string } | undefined>(undefined);

    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);

    const updateOrder = useAction(app2.actions.updateOrder);

    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable;
    const change = lessThanZeroToUndefined(
        BigNumber(payment?.amount ?? 0)
            .minus(order.total)
            .toNumber(),
    );
    const missingPayment = lessThanZeroToUndefined(
        BigNumber(order.total)
            .minus(payment?.amount ?? 0)
            .toNumber(),
    );

    useEffect(() => {
        if (open && order.paymentMethod && isCardPayment(order.paymentMethod)) {
            setPayment({
                paymentMethod: order.paymentMethod,
                amount: order.total,
            });
        }
    }, [open]);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleChargeKioskOrder = async () => {
        if (!payment) return;
        if (isCardPayment(order.paymentMethod) && payWithTerminalAvailable) {
            setLoading(true);
            const paymentResponse = await payWithPaymentTerminal({
                amount: order.total,
            });
            if (!paymentResponse.paid) {
                setLoading(false);
                return;
            }
            setLoading(false);
        }
        await chargeKioskOrder();
    };

    const chargeKioskOrder = async () => {
        if (!payment) return;
        if (!order.paymentMethod) return;

        setLoading(true);
        const response = await chargeKioskOrderApi({
            orderId: order.orderId,
            payments: [
                {
                    paymentMethod: payment?.paymentMethod,
                    amount: payment.amount,
                },
            ],
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.(response.data);
        updateOrder({ order: response.data });
        handleClose();
    };

    const addPayment = (amount: string) => {
        if (!order.paymentMethod) return;
        if (BigNumber(amount).isLessThan(order.total)) {
            notification({ message: translate('Payment must be for the total order amount') });
            return;
        }
        setPayment({
            paymentMethod: order.paymentMethod,
            amount,
        });
    };

    return (
        <Dialog title={translate('Charge order')} classes={{ dialog: classes.dialog }} open={open} onClose={handleClose} loading={loading}>
            <DialogContent className={classes.container}>
                <div className={classes.paymentMethodsContainer}>
                    <Text variant={'subtitle'}>{translate('Payment Method')}</Text>
                    <div className={classes.paymentCard}>
                        {isCardPayment(order.paymentMethod) && <CreditCardIcon size={20} />}
                        {isCashPayment(order.paymentMethod) && <CashIcon size={20} />}
                        <Text className={classes.paymentText}>{translate(order.paymentMethod)}</Text>
                    </div>
                    <Text variant={'subtitle'}>{translate('Customer Details')}</Text>
                    <Input label={translate('Name')} name={'customerName'} value={order.customerName} disabled />
                    <Input label={translate('Phone Number')} name={'customerMobileNumber'} value={order.customerMobileNumber} disabled />
                    <Input label={translate('Email')} name={'email'} value={order.email} disabled />
                </div>
                <div className={classes.numericPadContainer}>
                    <div className={classes.numericPad}>
                        <CurrencyNumericPad onEnter={addPayment} disabled={isCardPayment(order.paymentMethod)} />
                    </div>
                    <Button classes={{ button: classes.button }} variant={'outline'} disabled={!!payment?.amount} onClick={() => addPayment(order.total)}>
                        {translate('Total Ticket')}
                    </Button>
                </div>
                <div className={classes.cart}>
                    <Text className={classes.centeredText}>{translate('Total')}</Text>
                    <Text className={classes.centeredText} variant={'title'}>
                        {formatAsCurrencyNumber(order.total)}
                    </Text>
                    {payment && <PosPaymentResumeItem posPayment={payment as any} onRemove={() => setPayment(undefined)} />}
                    <div className={classes.paymentSummary}>
                        <div className={classes.divider}></div>
                        <div className={classes.separatedText}>
                            <Text>{translate('change')}</Text>
                            <Text>{formatAsCurrencyNumber(change ?? 0)}</Text>
                        </div>
                        <div className={classes.separatedText}>
                            <Text>{translate('Payment missing')}</Text>
                            <Text>{formatAsCurrencyNumber(missingPayment ?? 0)}</Text>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={'secondary'} onClick={handleClose} disabled={loading}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleChargeKioskOrder} disabled={loading || !payment?.amount}>
                    {translate('Charge')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 20,
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
        },
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    dialog: {
        padding: 20,
        width: 400,
        maxWidth: '90%',
        height: 'fit-content',
        [theme.breakpoints.up('md')]: {
            width: '90%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '70%',
        },
    },
    paymentMethodsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.up('md')]: {
            width: '25%',
        },
    },
    paymentMethodSelected: {
        fontFamily: theme.typography.medium,
        color: '#06B7A2',
        border: `1px solid #06B7A2`,
    },
    button: {
        width: '100%',
    },
    paymentCard: {
        width: 100,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 10,
        alignItems: 'center',
        color: theme.palette.text.brand,
        border: `1px solid ${theme.palette.border.brandContrast}`,
        borderRadius: 12,
        backgroundColor: theme.palette.surface.brand,
    },
    paymentText: {
        display: 'inline-block',
        textAlign: 'center',
    },
    cart: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: 16,
        padding: '18px 12px',
        gap: 10,
        minHeight: 340,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('md')]: {
            width: '35%',
        },
    },
    numericPadContainer: {
        width: '100%',
        minHeight: 400,
        [theme.breakpoints.up('md')]: {
            width: '30%',
        },
    },
    numericPad: {
        width: '100%',
        height: '90%',
    },
    paymentSummary: {
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    divider: {
        height: '1px',
        width: '95%',
        margin: '0 auto',
        borderTop: `1px solid ${theme.palette.border.primary}`,
    },
    separatedText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    centeredText: {
        textAlign: 'center',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
};
