import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { ChargeKioskOrderDialog } from 'src/components/button/order/ChargeKioskOrderDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';

export function ChargeKioskOrderButton({ order, onSuccess }: Props): React.ReactElement | null {
    const [chargeKioskOrderDialogState, setChargeKioskOrderDialogState] = useState({ open: false });

    const shouldRenderChargeKioskOrderButton = isKioskApp(order.app) && !order.payments?.length && !isCompletedOrder(order.orderStatus) && isAcceptedOrder(order.orderStatus);

    const openChargeKioskOrderDialog = () => setChargeKioskOrderDialogState({ open: true });

    const closeChargeKioskOrderDialog = () => setChargeKioskOrderDialogState({ open: false });

    if (!shouldRenderChargeKioskOrderButton) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.CHARGE_ORDERS}>
            <ChargeKioskOrderDialog order={order} open={chargeKioskOrderDialogState.open} onClose={closeChargeKioskOrderDialog} onSuccess={onSuccess} />
            <Button variant={'outline'} onClick={openChargeKioskOrderDialog}>
                {translate('Charge')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: () => void;
};
