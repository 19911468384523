import { useEffect } from 'react';
import { useTipOrderUpdatesListener } from 'src/modules/pos/tip/services/useTipOrderUpdatesListener';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerDisplayScreenOrderUpdater(): null {
    useTipOrderUpdatesListener();
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const items = useSelector((state) => state.pos.items);
    const tips = useSelector((state) => state.pos.tips);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const payment = useSelector((state) => state.pos.payment);
    const customerDisplayScreenOrderStep = useSelector((state) => state.pos.customerDisplayScreenOrderStep);
    const customerDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenEnabled);

    useEffect(() => {
        if (customerDisplayScreenEnabled) {
            sendOrderUpdateToCustomerDisplayScreen(customerDisplayScreenOrderStep);
        }
    }, [items, tips, usedCustomerCredits, useLetsEatCredits, payment, customerDisplayScreenOrderStep]);

    return null;
}
