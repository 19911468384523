import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FiscalEntity } from 'src/constants/FiscalEntity';
import type { EmailAddress, OrderId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';

export async function createPeruvianOrderInvoiceApi(request: CreatePeruvianOrderInvoiceApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('invoice/createPeruvianOrderInvoiceApi', request);
}

export type CreatePeruvianOrderInvoiceApiRequest = {
    orderId: OrderId;
    email?: EmailAddress;
    fiscalEntity: FiscalEntity;
    dni?: string;
    ruc?: string;
    businessName?: string;
    address?: string;
    city?: string;
};
