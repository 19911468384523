import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { Switch } from 'src/components/Switch';
import { Tooltip } from 'src/components/Tooltip';

export function FormSwitch({ name, label, helperText, defaultValue = false, disabled, tooltip }: Props): React.ReactElement {
    const {
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <Controller
                    control={control}
                    name={name}
                    render={({ onChange, value, name }) => (
                        <Switch helperText={helperText} label={label} value={value} onChange={(e: any) => onChange(!value)} name={name} disabled={isSubmitting || disabled || formContext.disabled} />
                    )}
                    defaultValue={defaultValue}
                />
                {tooltip && (
                    <div className={classes.tooltipSpacing}>
                        <Tooltip text={tooltip} position={'bottom'} />
                    </div>
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: { display: 'flex' },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
    },
    tooltipSpacing: {
        marginLeft: '4px',
        position: 'relative',
        top: '14px',
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | boolean;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    switchStyle?: 'danger';
    tooltip?: string;
};
