import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { getOrderApi } from 'src/api/letseatmanager/order/getOrderApi';
import { getOrdersApi } from 'src/api/letseatmanager/order/getOrdersApi';
import { unzip } from 'src/api/utils/unzip';
import { IncomingOrdersAlert } from 'src/components/alertDialog/order/IncomingOrdersAlert';
import { Button } from 'src/components/Button';
import { AddTemporaryPreparationTimeButton } from 'src/components/button/AddTemporaryPreparationTimeButton';
import { Input } from 'src/components/Input';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { TakeAwayOrderStatuses } from 'src/constants/TakeAwayOrderStatus';
import { translate } from 'src/i18n/translate';
import { BarChartIcon } from 'src/icons/BarChartIcon';
import { FilterIcon } from 'src/icons/FilterIcon';
import { WarningIcon } from 'src/icons/WarningIcon';
import { ToggleOrdersPageViewButton } from 'src/modules/order/components/ToggleOrdersPageViewButton';
import { AllOrders } from 'src/scenes/letseatmanager/orders/AllOrders';
import { AskForDriverButton } from 'src/scenes/letseatmanager/orders/AskForDriverButton';
import { DeliveryOrders } from 'src/scenes/letseatmanager/orders/DeliveryOrders';
import { DeliveryOrdersMapButton } from 'src/scenes/letseatmanager/orders/DeliveryOrdersMapButton';
import { EatHereOrders } from 'src/scenes/letseatmanager/orders/EatHereOrders';
import { NewOrderButton } from 'src/scenes/letseatmanager/orders/NewOrderButton';
import { OrdersPageFilter } from 'src/scenes/letseatmanager/orders/OrdersPageFilter';
import { OrdersPageFilterDialog } from 'src/scenes/letseatmanager/orders/OrdersPageFilterDialog';
import { OrdersSalesDialog } from 'src/scenes/letseatmanager/orders/OrdersSalesDialog';
import { RoomServiceOrders } from 'src/scenes/letseatmanager/orders/RoomServiceOrders';
import { TakeAwayOrders } from 'src/scenes/letseatmanager/orders/TakeAwayOrders';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import { SentryService } from 'src/services/SentryService';
import { Uuid, type OrderId } from 'src/types/Id';
import { OrdersAndReportsVm } from 'src/types/OrdersAndReportsVm';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isOngoingOrder } from 'src/utils/order/isOngoingOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { classNames } from 'src/utils/react/classNames';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { playAlertSound } from 'src/utils/sound/playAlertSound';
import { newUuid } from 'src/utils/uuid/newUuid';

export function OrdersPage(): React.ReactElement {
    const limit = 30;
    const INITIAL_PAGE = 1;
    const loadOrderProcessId = useRef<Uuid | undefined>(undefined);

    const classes = useStyles();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const [userHasRolePermission] = useUserHasRolePermission();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();
    const notification = useNotification();

    const [currentTab, setCurrentTab] = useState<any>(TABS.ALL);
    const [ordersSalesInfoDialogState, setOrdersSalesInfoDialogState] = useState({ open: false });
    const [ordersPageFiltersDialogState, setOrdersPageFiltersDialogState] = useState({ open: false });
    const [from, setFrom] = useState(moment().startOf('day').toDate());
    const [to, setTo] = useState(moment().endOf('day').toDate());
    const [loading, setLoading] = useState(false);
    const [ordersReport, setOrdersReport] = useState<OrdersAndReportsVm>({ orders: [], reportOrders: [], sales: undefined });
    const [orders, setOrders] = useState<Array<OrderVm>>([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [noVisibleOrdersError, setNoVisibleOrdersError] = useState('');
    const [orderStatuses, setOrderStatuses] = useState<Array<OrderStatus>>([OrderStatuses.ACCEPTED, OrderStatuses.COMPLETE, OrderStatuses.REJECTED, OrderStatuses.CANCELLED, OrderStatuses.RETURNED]);
    const [eatHereOrderStatuses, setEatHereOrderStatuses] = useState([OrderStatuses.PENDING, OrderStatuses.COMPLETE, OrderStatuses.REJECTED, OrderStatuses.CANCELLED]);
    const [takeAwayOrderStatuses, setTakeAwayOrderStatuses] = useState([
        TakeAwayOrderStatuses.ACCEPTED,
        TakeAwayOrderStatuses.READY_FOR_PICK_UP,
        TakeAwayOrderStatuses.COMPLETED,
        TakeAwayOrderStatuses.CANCELLED,
        TakeAwayOrderStatuses.REJECTED,
    ]);
    const [deliveryStatuses, setDeliveryStatuses] = useState([
        DeliveryStatuses.REQUESTED,
        DeliveryStatuses.ACCEPTED,
        DeliveryStatuses.DELIVERED,
        DeliveryStatuses.RETURNED,
        DeliveryStatuses.REJECTED,
        DeliveryStatuses.CANCELLED,
    ]);
    const [roomServiceStatuses, setRoomServiceStatuses] = useState([OrderStatuses.NEW, OrderStatuses.ACCEPTED, OrderStatuses.COMPLETE, OrderStatuses.REJECTED, OrderStatuses.CANCELLED]);

    const restaurant = useSelector((state) => state.app.restaurant);
    const roomServiceEnabled = useSelector((state) => state.app.restaurant.roomServiceEnabled);
    const deliveryEnabled = useSelector((state) => state.app.restaurant.deliveryEnabled);
    const takeAwayEnabled = useSelector((state) => state.app.restaurant.takeAwayEnabled);
    const eatHereEnabled = useSelector((state) => state.app.restaurant.eatHereEnabled);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);
    const cancelledOrdersAlertSoundEnabled = useSelector((state) => state.app.restaurant.cancelledOrdersAlertSoundEnabled);
    const soundMuted = useSelector((state) => state.app.soundMuted);

    const userOpenedCashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();
    const cashRegisterPosBusinessDayId = openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId ?? userOpenedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId;

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const shouldFilterByCashRegisterPosBusinessDayId =
        userHasRolePermission(RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER) &&
        !!posMultipleCashRegistersEnabled &&
        (!!openedCashRegisterPosBusinessDay || !!userOpenedCashRegisterPosBusinessDay) &&
        !brandOpened;

    useEffect(() => {
        handleNewOrders();
    }, [currentOrders, loading, to, from]);

    useEffect(() => {
        startLoadAllOrders(from, to);
    }, [restaurantIds]);

    const handleNewOrders = () => {
        if (loading) return;

        const ongoingOrders = currentOrders.filter((order) => isOngoingOrder(order.orderStatus));
        const filteredOrders = ongoingOrders.filter((order) => moment(order.createdAt).isAfter(from) && moment(order.createdAt).isBefore(to));

        handleFinishedOrders(filteredOrders);

        if (filteredOrders.length === 0) {
            handleOrdersError(ongoingOrders);
            return;
        }

        setNoVisibleOrdersError('');
        setOrders(removeDuplicatedOrders([...orders, ...filteredOrders]));
    };

    const handleFinishedOrders = async (ongoingOrders: Array<OrderVm>) => {
        const ordersThatMustBeFinished = orders.filter((order) => {
            return isOngoingOrder(order.orderStatus) && !ongoingOrders.some((ongoingOrder) => ongoingOrder.orderId === order.orderId);
        });

        if (ordersThatMustBeFinished.length === 0 || orders.length === 0) return;
        const updatedOrders: Array<OrderVm> = [];
        for (const order of ordersThatMustBeFinished) {
            const response = await getOrderApi({ orderId: order.orderId });
            if (!response.ok) continue;
            alertCancelledOrder();
            updatedOrders.push(response.data);
        }
        setOrders(removeDuplicatedOrders([...orders, ...updatedOrders]));
    };

    const alertCancelledOrder = () => {
        if (!cancelledOrdersAlertSoundEnabled) return;
        if (!soundMuted) playAlertSound(restaurant.cancelledOrdersAlertSound);
        notification({ message: translate('Order cancelled. Check your orders!'), timeOpened: 10000 });
    };

    const handleOrdersError = (newOrders: Array<OrderVm>) => {
        if (newOrders.length === 0) return setNoVisibleOrdersError('');

        const sortedOrders = newOrders.sort((orderA: OrderVm, orderB: OrderVm) => {
            const createdAtA = new Date(orderA.createdAt).getTime();
            const createdAtB = new Date(orderB.createdAt).getTime();
            return createdAtA - createdAtB;
        });
        const oldestOrder = sortedOrders.find((order) => moment(order.createdAt).isBefore(from));
        if (oldestOrder) {
            return setNoVisibleOrdersError(translate('You have ongoing orders that are not inside the date range since @date', { date: moment(oldestOrder.createdAt).format('DD/MM/YYYY') }));
        }

        setNoVisibleOrdersError(translate('You have ongoing orders that are not inside the date range'));
    };

    const removeDuplicatedOrders = (orders: Array<OrderVm>) => {
        const orderIds: Partial<Record<OrderId, OrderVm>> = {};
        for (const order of orders) {
            if (orderIds[order.orderId]) {
                if (moment(orderIds[order.orderId]?.modifiedAt).isAfter(order.modifiedAt)) continue;
            }
            orderIds[order.orderId] = order;
        }
        return Object.values(orderIds) as Array<any>;
    };

    const openOrdersSalesInfoDialog = () => setOrdersSalesInfoDialogState({ open: true });

    const closeOrdersSalesInfoDialog = () => setOrdersSalesInfoDialogState({ open: false });

    const openOrdersPageFiltersDialog = () => setOrdersPageFiltersDialogState({ open: true });

    const closeOrdersPageFiltersDialog = () => setOrdersPageFiltersDialogState({ open: false });

    const getOrdersFiltered = () => {
        const sortedOrders = orders.sort((orderA: OrderVm, orderB: OrderVm) => {
            const createdAtA = new Date(orderA.createdAt).getTime();
            const createdAtB = new Date(orderB.createdAt).getTime();
            return createdAtA - createdAtB;
        });
        const filteredOrders = sortedOrders.filter((order) => {
            const filterPendingAndAcceptedOrders = orderStatuses.includes(OrderStatuses.ACCEPTED);

            if (filterPendingAndAcceptedOrders) return [...orderStatuses, OrderStatuses.PENDING, OrderStatuses.CLOSED].includes(order.orderStatus);

            return orderStatuses.includes(order.orderStatus as any);
        });
        const newOrders = sortedOrders.filter((order) => isNewOrder(order.orderStatus));
        const ordersFilteredByOrderStatus = [...filteredOrders, ...newOrders];
        const ordersFilteredByUser = ordersFilteredByOrderStatus.filter(isOrderVisibleForUser);

        if (!searchFilter) return ordersFilteredByUser;

        return ordersFilteredByUser.filter((order) => {
            const normalizeSearchFilter = searchFilter.replace(/[^a-z0-9]/gi, '');
            const customerNameMatchesFilter = order.customerName.match(new RegExp(normalizeSearchFilter, 'gi'));
            const shortOrderIdMatchesFilter = order.shortOrderId.match(new RegExp(normalizeSearchFilter, 'gi'));
            return customerNameMatchesFilter || shortOrderIdMatchesFilter;
        });
    };

    const refreshOrders = () => {
        startLoadAllOrders(from, to);
    };

    const startLoadAllOrders = useCallback(
        async (from: Date, to: Date) => {
            let page = INITIAL_PAGE;
            let orders: Array<OrderVm> = [];
            const currentLoadProcessId = newUuid() as Uuid;
            loadOrderProcessId.current = currentLoadProcessId;
            setLoading(true);
            do {
                if (currentLoadProcessId !== loadOrderProcessId.current) return;
                const ordersReport = await loadOrders(from, to, page);
                if (currentLoadProcessId !== loadOrderProcessId.current) return;

                if (!ordersReport) {
                    setLoading(false);
                    return;
                }

                const currentOrders = ordersReport.orders ?? [];
                const ordersLoadedAreLessThanTheLimit = currentOrders.length < limit;

                if (ordersLoadedAreLessThanTheLimit && page === 1) {
                    setOrders(removeDuplicatedOrders([...currentOrders]));
                    setOrdersReport(ordersReport);
                    setLoading(false);
                    loadOrderProcessId.current = undefined;
                    return;
                }

                if (currentOrders.length === 0 && page !== 1) {
                    setOrdersReport(ordersReport);
                    setOrders(orders);
                    setLoading(false);
                    loadOrderProcessId.current = undefined;
                    return;
                }

                page++;
                orders = orders.concat(ordersReport.orders);
            } while (true);
        },
        [restaurantIds, shouldFilterByCashRegisterPosBusinessDayId, cashRegisterPosBusinessDayId],
    );

    const loadOrders = async (from: Date, to: Date, page: number) => {
        const response = await getOrdersApi({
            restaurantIds,
            from: moment(formatDateToRestaurantTimeZone(from)).toDate(),
            to: moment(formatDateToRestaurantTimeZone(to)).toDate(),
            maxOrders: limit,
            page,
            cashRegisterPosBusinessDayId: shouldFilterByCashRegisterPosBusinessDayId ? cashRegisterPosBusinessDayId : undefined,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            SentryService.logInfoBreadcrumb('Failed to download the orders view model', { response });
            return;
        }
        return unzip(response.data) as OrdersAndReportsVm;
    };

    const handleChangeFilters = (filters: any) => {
        if (filters.from) {
            startLoadAllOrders(filters.from, to);
            setFrom(filters.from);
        }
        if (filters.to) {
            startLoadAllOrders(from, filters.to);
            setTo(filters.to);
        }
        if (filters.orderStatuses) setOrderStatuses(filters.orderStatuses);
        if (filters.eatHereOrderStatuses) setEatHereOrderStatuses(filters.eatHereOrderStatuses);
        if (filters.takeAwayOrderStatuses) setTakeAwayOrderStatuses(filters.takeAwayOrderStatuses);
        if (filters.deliveryStatuses) setDeliveryStatuses(filters.deliveryStatuses);
        if (filters.deliveryStatuses) setRoomServiceStatuses(filters.deliveryStatuses);
    };

    const getFilterDefaultValues = () => ({
        from,
        to,
        orderStatuses,
        eatHereOrderStatuses,
        takeAwayOrderStatuses,
        deliveryStatuses,
    });

    const ordersFiltered = getOrdersFiltered();
    const ongoingOrders = ordersFiltered.filter((order) => isOngoingOrder(order.orderStatus));
    const deliveryOrders = ordersFiltered.filter((order) => isDeliveryOrder(order.orderType));
    const ongoingDeliveryOrders = deliveryOrders.filter((order) => isOngoingOrder(order.orderStatus));
    const takeAwayOrders = ordersFiltered.filter((order) => isTakeAwayOrder(order.orderType));
    const ongoingTakeAwayOrders = takeAwayOrders.filter((order) => isOngoingOrder(order.orderStatus));
    const eatHereOrders = ordersFiltered.filter((order) => isEatHereOrder(order.orderType));
    const ongoingEatHereOrders = eatHereOrders.filter((order) => isOngoingOrder(order.orderStatus));
    const roomServiceOrders = ordersFiltered.filter((order) => isRoomServiceOrder(order.orderType));
    const ongoingRoomServiceOrders = roomServiceOrders.filter((order) => isOngoingOrder(order.orderStatus));

    const tabs = [
        {
            value: '1',
            label: translate('All'),
            badge: ongoingOrders.length,
            onClick: () => setCurrentTab(TABS.ALL),
        },
        {
            value: '2',
            hidden: !deliveryEnabled,
            label: translate('Delivery'),
            badge: ongoingDeliveryOrders.length,
            onClick: () => setCurrentTab(TABS.DELIVERY),
        },
        {
            value: '3',
            hidden: !takeAwayEnabled,
            label: translate('Take away'),
            badge: ongoingTakeAwayOrders.length,
            onClick: () => setCurrentTab(TABS.TAKE_AWAY),
        },
        {
            value: '4',
            hidden: !eatHereEnabled,
            label: translate('Tables'),
            badge: ongoingEatHereOrders.length,
            onClick: () => setCurrentTab(TABS.EAT_HERE),
        },
        {
            value: '5',
            hidden: !roomServiceEnabled,
            label: translate('Room service'),
            badge: ongoingRoomServiceOrders.length,
            onClick: () => setCurrentTab(TABS.ROOM_SERVICE),
        },
    ];

    return (
        <SecuredAndSubscribedPage title={translate('Orders')} rolePermission={RolePermissions.PENDING_ORDERS_PAGE} className={classes.container}>
            <IncomingOrdersAlert />
            <OrdersSalesDialog
                open={ordersSalesInfoDialogState.open}
                onClose={closeOrdersSalesInfoDialog}
                filter={{ from, to }}
                ordersReport={ordersReport}
                onChangeFrom={(from: any) => handleChangeFilters({ from })}
                onChangeTo={(to: any) => handleChangeFilters({ to })}
            />
            <OrdersPageFilterDialog
                open={ordersPageFiltersDialogState.open}
                currentTab={currentTab}
                defaultValues={getFilterDefaultValues()}
                onClose={closeOrdersPageFiltersDialog}
                onChange={handleChangeFilters}
            />
            <div className={(classes as any).buttonsContainer}>
                <div className={classes.row}>
                    <div className={classes.toolbar}>
                        <div className={classes.mainActionsContainer}>
                            <Input
                                type='search'
                                name='search'
                                placeholder={translate('Search...')}
                                onChange={(value: any) => setSearchFilter(value)}
                                classes={{ input: classNames(classes.input), container: classNames(classes.inputContainer, classes.hideBelowDesktop) }}
                            />
                            <AddTemporaryPreparationTimeButton />
                            <AskForDriverButton />
                            <NewOrderButton />
                        </div>
                        <div className={classes.filtersContainer}>
                            {loading && (
                                <div className={classes.loading}>
                                    <Loader size={10} loading={true} />
                                    <span className={classes.text}>{translate('We are loading your orders, this could take a couple of minutes')}</span>
                                </div>
                            )}
                            <div className={classes.secondaryActionsContainer}>
                                <div className={classes.row}>
                                    <div className={classes.hideBelowDesktop}>
                                        <OrdersPageFilter currentTab={currentTab} onChange={handleChangeFilters} defaultValues={getFilterDefaultValues()} />
                                    </div>
                                    <Input
                                        type='search'
                                        name='search'
                                        placeholder={translate('Search...')}
                                        onChange={(value: any) => setSearchFilter(value)}
                                        classes={{ input: classNames(classes.input), container: classNames(classes.inputContainer, classes.hideDesktop) }}
                                    />
                                </div>
                                <div className={classes.secondaryButtonsContainer}>
                                    <Button secondary onClick={openOrdersPageFiltersDialog} classes={{ button: classes.hideDesktop }}>
                                        <FilterIcon />
                                    </Button>
                                    <Button secondary onClick={openOrdersSalesInfoDialog}>
                                        <span className={classes.hideBelowDesktop}>{translate('Sales report')}</span>
                                        <BarChartIcon width={16} height={16} />
                                    </Button>
                                    <ToggleOrdersPageViewButton />
                                    <DeliveryOrdersMapButton />
                                </div>
                            </div>
                            {!!noVisibleOrdersError && (
                                <div className={classes.error}>
                                    <WarningIcon />
                                    <span className={classes.text}>{noVisibleOrdersError}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Tabs tabs={tabs}>
                    <AllOrders orders={ordersFiltered} orderStatuses={orderStatuses} onChangeOrder={refreshOrders} />
                    {deliveryEnabled && <DeliveryOrders orders={deliveryOrders} deliveryStatuses={deliveryStatuses} onChangeOrder={refreshOrders} />}
                    {takeAwayEnabled && <TakeAwayOrders orders={takeAwayOrders} takeAwayOrderStatuses={takeAwayOrderStatuses} onChangeOrder={refreshOrders} />}
                    {eatHereEnabled && <EatHereOrders orders={eatHereOrders} orderStatuses={eatHereOrderStatuses} onChangeOrder={refreshOrders} />}
                    {roomServiceEnabled && <RoomServiceOrders orders={roomServiceOrders} orderStatuses={roomServiceStatuses} onChangeOrder={refreshOrders} />}
                </Tabs>
            </div>
        </SecuredAndSubscribedPage>
    );
}

const TABS = {
    ALL: 'ALL',
    DELIVERY: 'DELIVERY',
    TAKE_AWAY: 'TAKE_AWAY',
    EAT_HERE: 'EAT_HERE',
    ROOM_SERVICE: 'ROOM_SERVICE',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            padding: '10px 20px',
        },
    },
    toolbar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    mainActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
        gap: 10,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: 'fit-content',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            width: '100%',
        },
    },
    secondaryActionsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            gap: 10,
        },
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    secondaryButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
        position: 'relative',
    },
    badge: {
        padding: '3px 13px',
        backgroundColor: '#CBF7E9',
        borderRadius: 10,
        color: '#198073',
        fontFamily: theme.typography.medium,
        fontSize: 12,
    },
    loading: {
        display: 'flex',
        gap: 10,
        flexDirection: 'row',
        alignItems: 'center',
        height: '30px',
        position: 'absolute',
        top: '-30px',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
    },
    hideBelowDesktop: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    hideDesktop: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    actionButton: {
        width: 'max-content',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    error: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    inputContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
