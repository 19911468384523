import { makeStyles } from '@material-ui/core/styles';
import { DropDownItem, Text } from '@pidedirecto/ui';
import { RingingBellIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { RestaurantNotification } from 'src/components/app/appBar/RestaurantNotification';
import { translate } from 'src/i18n/translate';
import { RestaurantNotificationVm } from 'src/types/RestaurantNotificationVm';

export function QuickActionRestaurantNotifications({ notifications }: Props): React.ReactElement {
    const classes = useStyles();

    if (!notifications.length) {
        return (
            <div className={classes.emptyStateContainer}>
                <RingingBellIcon size={20} className={classes.emptyIcon} />
                <Text className={classes.emptyText} weight='semiBold'>
                    {translate('You do not have alerts by the moment')}
                </Text>
                <Text className={classes.emptyText}>{translate('We will notice you when there is news')}</Text>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {notifications.map((notification) => (
                <DropDownItem classes={{ container: classes.dropDownItem }}>
                    <RestaurantNotification title={notification.title} message={notification.message} url={notification.url} variant='warning' />
                </DropDownItem>
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    emptyStateContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 150,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    dropDownItem: {
        borderBottom: `1px solid ${theme.palette.border.primary}`,
    },
    emptyText: {
        color: theme.palette.text.secondary,
    },
    emptyIcon: {
        marginBottom: 10,
        color: theme.palette.icons.primary,
    },
}));

type Props = {
    notifications: Array<RestaurantNotificationVm>;
};
