import type { CompletePendingOrderApiRequest } from 'src/api/letseatmanager/pos/completePendingOrderApi';
import type { SavePosOrderApiRequest } from 'src/api/letseatmanager/pos/savePosOrderApi';
import type { UpdatePendingOrderApiRequest } from 'src/api/letseatmanager/pos/updatePendingOrderApi';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { getPosOrdersOfflineInLocalStorage } from 'src/localStorage/getPosOrdersOfflineInLocalStorage';
import { setPosOrdersOfflineInLocalStorage } from 'src/localStorage/setPosOrdersOfflineInLocalStorage';
import { updatePosRestaurantZonesOfflineInLocalStorageByTableOrder } from 'src/localStorage/updatePosRestaurantZonesOfflineInLocalStorageByTableOrder';
import { newUuid } from 'src/utils/uuid/newUuid';

export function savePosOrderOffline(posOrderRequest: Request) {
    const posOrdersOffline = getPosOrdersOfflineInLocalStorage();
    if ((posOrderRequest as SavePosOrderApiRequest).table && posOrderRequest.orderStatus === OrderStatuses.PENDING) {
        updatePosRestaurantZonesOfflineInLocalStorageByTableOrder({
            table: (posOrderRequest as SavePosOrderApiRequest).table!,
            restaurantZoneId: (posOrderRequest as SavePosOrderApiRequest).restaurantZoneId,
            orderStatus: OrderStatuses.PENDING,
            orderItems: posOrderRequest.orderItems as any,
            cancelledItems: posOrderRequest.cancelledItems as any,
        });
    }
    if ((posOrderRequest as UpdatePendingOrderApiRequest).orderId) {
        if (posOrdersOffline.some((posOrderOffline) => posOrderOffline.orderId === (posOrderRequest as UpdatePendingOrderApiRequest).orderId)) {
            const order = posOrdersOffline.find((posOrderOffline) => posOrderOffline.orderId === (posOrderRequest as UpdatePendingOrderApiRequest).orderId);
            if (order) {
                setPosOrdersOfflineInLocalStorage([
                    ...posOrdersOffline.filter((posOrderOffline) => posOrderOffline.orderId !== (posOrderRequest as UpdatePendingOrderApiRequest).orderId),
                    {
                        ...order,
                        orderItems: posOrderRequest.orderItems,
                        orderStatus: posOrderRequest.orderStatus,
                        modifiedAt: new Date(),
                        completedAt: posOrderRequest.orderStatus === OrderStatuses.COMPLETE ? new Date() : undefined,
                    },
                ]);
            }

            return;
        }
    }
    setPosOrdersOfflineInLocalStorage([
        ...posOrdersOffline,
        {
            ...posOrderRequest,
            temporalOrderId: newUuid(),
            createdAt: !(posOrderRequest as UpdatePendingOrderApiRequest).modifiedAt ? new Date() : undefined,
            modifiedAt: new Date(),
            completedAt: posOrderRequest.orderStatus === OrderStatuses.COMPLETE ? new Date() : undefined,
        },
    ]);
}

type Request = SavePosOrderApiRequest | CompletePendingOrderApiRequest | UpdatePendingOrderApiRequest;
