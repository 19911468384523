import { makeStyles } from '@material-ui/core/styles';
import { Chip, DropDown, DropDownItem, SkeletonLoader, Tabs, Text } from '@pidedirecto/ui';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import { BellIcon, ChatBubblesIcon, DrawerIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { findRestaurantNotificationsApi } from 'src/api/letseatmanager/restaurant/findRestaurantNotificationsApi';
import { QuickActionRestaurantNotifications } from 'src/components/app/appBar/QuickActionRestaurantNotifications';
import { RestaurantNotifications } from 'src/components/app/appBar/RestaurantNotifications';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function RestaurantNotificationsDropDown(): React.ReactElement | null {
    const classes = useStyles();

    const [notificationsSeen, setNotificationsSeen] = useState(0);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, totalRestaurantNotifications, reloadNotificationsAmount] = useLoadApi(
        findRestaurantNotificationsApi,
        { restaurantId },
        { dependencies: [restaurantId], requiredValues: [restaurantId], initialValue: { notifications: [], quickActionsNotifications: [] } },
    );

    const totalNotifications = totalRestaurantNotifications.notifications.length + totalRestaurantNotifications.quickActionsNotifications.length;
    const missingNotifications = totalNotifications - notificationsSeen;

    const tabs = [
        {
            label: translate('Alerts'),
            content: ({ isTabSelected }: { isTabSelected: boolean }) => (
                <div className={isTabSelected ? classes.selectedTab : classes.tab}>
                    <DrawerIcon size={20} title={translate('Alerts')} />
                    <Text>{translate('Alerts')}</Text>
                    {!!totalRestaurantNotifications.quickActionsNotifications.length && (
                        <Chip classes={{ chip: !isTabSelected ? classes.chipNotSelected : '' }}>{totalRestaurantNotifications.quickActionsNotifications.length}</Chip>
                    )}
                </div>
            ),
            value: '1',
        },
        {
            label: translate('Announcements'),
            content: ({ isTabSelected }: { isTabSelected: boolean }) => (
                <div className={classes.tab}>
                    <ChatBubblesIcon size={20} title={translate('Announcements')} />
                    <Text>{translate('Announcements')}</Text>
                    {!!totalRestaurantNotifications.notifications.length && (
                        <Chip classes={{ chip: !isTabSelected ? classes.chipNotSelected : '' }}>{totalRestaurantNotifications.notifications.length}</Chip>
                    )}
                </div>
            ),
            value: '2',
        },
    ];

    useEffect(() => {
        const restaurantNotificationWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_NOTIFICATION_SENT, () => {
            reloadNotificationsAmount();
        });

        return () => {
            restaurantNotificationWebSocketEvent.remove();
        };
    }, []);

    if (!restaurantId) return null;

    return (
        <DropDown
            badge={BigNumber(missingNotifications).isGreaterThan(0) ? <Chip variant='error-contrast'>{missingNotifications}</Chip> : null}
            content={<BellIcon size={20} title={translate('Notifications')} />}
            classes={{ button: classes.button, dropdown: classes.dropdown }}
            position='right'
            onOpen={() => {
                setTimeout(() => {
                    setNotificationsSeen(totalNotifications);
                }, 100);
            }}
        >
            <Tabs tabs={tabs}>
                {loading ? <NotificationsLoader /> : <QuickActionRestaurantNotifications notifications={totalRestaurantNotifications.quickActionsNotifications} />}
                {loading ? <NotificationsLoader /> : <RestaurantNotifications notifications={totalRestaurantNotifications.notifications} />}
            </Tabs>
        </DropDown>
    );
}

function NotificationsLoader() {
    const classes = useStyles();
    return (
        <div className={classes.loaderContainer}>
            <DropDownItem classes={{ container: classes.dropDownItem }}>
                <SkeletonLoader variant='notification' />
            </DropDownItem>
            <DropDownItem classes={{ container: classes.dropDownItem }}>
                <SkeletonLoader variant='notification' />
            </DropDownItem>
            <DropDownItem>
                <SkeletonLoader variant='notification' />
            </DropDownItem>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.primary.main,
        height: 48,
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.secondary,
            height: 42,
            width: '100%',
            textDecoration: 'none !important',
        },
    },
    dropdown: {
        maxHeight: 350,
        [theme.breakpoints.down('sm')]: {
            maxHeight: '50vh',
            marginRight: -50,
        },
    },
    tab: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 200,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            minWidth: 50,
        },
    },
    selectedTab: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 200,
        color: theme.palette.text.brand,
        [theme.breakpoints.down('sm')]: {
            minWidth: 40,
        },
    },
    loaderContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    dropDownItem: {
        borderBottom: `1px solid ${theme.palette.border.primary}`,
    },
    chipNotSelected: {
        backgroundColor: theme.palette.surface.tertiary,
        color: theme.palette.text.primary,
    },
}));
