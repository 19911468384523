import * as React from 'react';
import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { LazyRoute } from 'src/components/app/LazyRoute';
import { CancelDeliveryDialog } from 'src/components/dialog/delivery/CancelDeliveryDialog';
import { CancelDeliveryManagerDialog } from 'src/components/dialog/delivery/CancelDeliveryManagerDialog';
import { CompleteDeliveryManagerDialog } from 'src/components/dialog/delivery/CompleteDeliveryManagerDialog';
import { RestartDeliveryManagerDialog } from 'src/components/dialog/delivery/RestartDeliveryManagerDialog';
import ChangePickupStationsDialog from 'src/components/dialog/restaurant/ChangePickupStationsDialog';
import { RoutePaths } from 'src/constants/RoutePath';
import ChangeEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/ChangeEmailTemplateDialog';
import CreateEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/CreateEmailTemplateDialog';
import { ChangeRestaurantDetailsDialog } from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialog';
import { CopyRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CopyRestaurantMenuDialog';
import { CreateRestaurantMenuPromotionDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateRestaurantMenuPromotionDialog';
import { ImportRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ImportRestaurantMenuDialog';

const LazySupportPage = lazy(() => import('src/scenes/letseatadmin/SupportPage'));
const LazyRestaurantReferralsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantReferralsPage'));
const LazyDriverReferralsPage = lazy(() => import('src/scenes/letseatadmin/DriverReferralsPage'));
const LazyRestaurantSalesPage = lazy(() => import('src/scenes/letseatadmin/RestaurantSalesPage'));
const LazyDeliveryMonitoringPage = lazy(() => import('src/scenes/letseatadmin/DeliveryMonitoringPage'));
const LazyQuickActionsPage = lazy(() => import('src/scenes/letseatadmin/QuickActionsPage'));
const LazyDriverBannersPage = lazy(() => import('src/scenes/letseatadmin/DriverBannersPage'));
const LazySurveysPage = lazy(() => import('src/scenes/letseatadmin/SurveysPage'));
const LazyAdminCurrentOrders = lazy(() => import('src/scenes/letseatadmin/currentOrders/AdminCurrentOrders'));
const LazyOrdersPage = lazy(() => import('src/scenes/letseatadmin/OrdersPage'));
const LazyOrderPage = lazy(() => import('src/scenes/letseatadmin/OrderPage'));
const LazyOrderIncidentsPage = lazy(() => import('src/scenes/letseatadmin/OrderIncidentsPage'));
const LazySuspiciousOrdersPage = lazy(() => import('src/scenes/letseatadmin/SuspiciousOrdersPage'));
const LazyPickupStationsPage = lazy(() => import('src/scenes/letseatadmin/PickupStationsPage'));
const LazyBrandPage = lazy(() => import('src/scenes/letseatadmin/BrandPage'));
const LazyBrandsPage = lazy(() => import('src/scenes/letseatadmin/BrandsPage'));
const LazyRestaurantsPage = lazy(() => import('src/scenes/letseatadmin/RestaurantsPage'));
const LazyRestaurantsDetails = lazy(() => import('src/scenes/letseatadmin/restaurantsDetails/RestaurantsDetails'));
const LazyCustomerPage = lazy(() => import('src/scenes/letseatadmin/CustomerPage'));
const LazyCustomersPage = lazy(() => import('src/scenes/letseatadmin/CustomersPage'));
const LazyDriverPage = lazy(() => import('src/scenes/letseatadmin/DriverPage'));
const LazyDriversPage = lazy(() => import('src/scenes/letseatadmin/DriversPage'));
const LazyDriverBonusOffersPage = lazy(() => import('src/scenes/letseatadmin/DriverBonusOffersPage'));
const LazyOngoingDriverBonusesPage = lazy(() => import('src/scenes/letseatadmin/OngoingDriverBonusesPage'));
const LazyFaqsPage = lazy(() => import('src/scenes/letseatadmin/FaqsPage'));
const LazyRestaurantBillingPage = lazy(() => import('src/scenes/letseatadmin/RestaurantBillingPage'));
const LazyRestaurantBillingPageUy = lazy(() => import('src/scenes/letseatadmin/RestaurantBillingPageUy'));
const LazyDriverBillingPage = lazy(() => import('src/scenes/letseatadmin/DriverBillingPage'));
const LazyDriverBonusBillingPage = lazy(() => import('src/scenes/letseatadmin/DriverBonusBillingPage'));
const LazySettingsPage = lazy(() => import('src/scenes/letseatadmin/SettingsPage'));
const LazyLiveDriversPage = lazy(() => import('src/scenes/letseatadmin/LiveDriversPage'));
const LazyThirdPartyLogisticsCoveragePage = lazy(() => import('src/scenes/letseatadmin/ThirdPartyLogisticsCoveragePage'));
const LazyDemoRestaurantsPage = lazy(() => import('src/scenes/letseatadmin/DemoRestaurantsPage'));
const LazyAdminLogEventsPage = lazy(() => import('src/scenes/letseatadmin/AdminLogEventsPage'));
const LazyRequestInternalReportPage = lazy(() => import('src/scenes/letseatadmin/RequestInternalReportPage'));

export function AppRouteOperations(): React.ReactElement {
    return (
        <>
            <CancelDeliveryDialog />
            <RestartDeliveryManagerDialog />
            <CompleteDeliveryManagerDialog />
            <CancelDeliveryManagerDialog />
            <ChangePickupStationsDialog />
            <CreateEmailTemplateDialog />
            <ChangeEmailTemplateDialog />
            <ChangeRestaurantDetailsDialog />
            <CopyRestaurantMenuDialog />
            <ImportRestaurantMenuDialog />
            <CreateRestaurantMenuPromotionDialog />
            <LazyRoute path={RoutePaths.ADMIN_SUPPORT}>
                <LazySupportPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_QUICK_ACTIONS}>
                <LazyQuickActionsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_BILLING}>
                <LazyRestaurantBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_BILLING_UY}>
                <LazyRestaurantBillingPageUy />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_REFERRALS}>
                <LazyRestaurantReferralsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_REFERRALS}>
                <LazyDriverReferralsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANT_SALES}>
                <LazyRestaurantSalesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DELIVERY_MONITORING}>
                <LazyDeliveryMonitoringPage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_SURVEYS_RESTAURANTS}>
                    <LazySurveysPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_ONGOING_ORDERS}>
                <LazyAdminCurrentOrders />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_ORDERS} exact>
                    <LazyOrdersPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_ORDER}>
                    <LazyOrderPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_ORDER_INCIDENTS}>
                <LazyOrderIncidentsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SUSPICIOUS_ORDERS}>
                <LazySuspiciousOrdersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_PICKUP_STATIONS}>
                <LazyPickupStationsPage />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_BRAND}>
                    <LazyBrandPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_BRANDS}>
                    <LazyBrandsPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANTS}>
                <LazyRestaurantsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_RESTAURANTS_DETAILS}>
                <LazyRestaurantsDetails />
            </LazyRoute>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_CUSTOMER}>
                    <LazyCustomerPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_CUSTOMERS}>
                    <LazyCustomersPage />
                </LazyRoute>
            </Switch>
            <Switch>
                <LazyRoute path={RoutePaths.ADMIN_DRIVER}>
                    <LazyDriverPage />
                </LazyRoute>
                <LazyRoute path={RoutePaths.ADMIN_DRIVERS}>
                    <LazyDriversPage />
                </LazyRoute>
            </Switch>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS}>
                <LazyDriverBonusOffersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES}>
                <LazyOngoingDriverBonusesPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BILLING}>
                <LazyDriverBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVER_BONUS_BILLING}>
                <LazyDriverBonusBillingPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_LIVE_DRIVERS}>
                <LazyLiveDriversPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_SETTINGS}>
                <LazySettingsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_FAQ}>
                <LazyFaqsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DRIVERAPP_SETTINGS}>
                <LazyDriverBannersPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_LOG_EVENTS}>
                <LazyAdminLogEventsPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_REQUEST_INTERNAL_REPORT}>
                <LazyRequestInternalReportPage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE}>
                <LazyThirdPartyLogisticsCoveragePage />
            </LazyRoute>
            <LazyRoute path={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS}>
                <LazyDemoRestaurantsPage />
            </LazyRoute>
        </>
    );
}
