import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import { BellIcon, OutlinedNotificationIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function RestaurantNotification({ title, message, url, variant }: Props): React.ReactElement {
    const classes = useStyles();

    const openLink = () => window.open(url, '_blank');

    return (
        <div className={classes.container}>
            {variant === 'warning' && <OutlinedNotificationIcon size={20} title={translate('Alert')} className={classes.icon} />}
            {variant === 'info' && <BellIcon size={20} title={translate('Announcement')} color='#7D8FFF' className={classes.icon} />}
            <div className={classes.info}>
                <Text weight='medium'>{title}</Text>
                <Text>{message}</Text>
                {!!url && (
                    <Text onClick={openLink} className={classes.link}>
                        {translate('See more')}
                    </Text>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        padding: '6px 0 ',
    },
    icon: {
        margin: '0 10px',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    link: {
        textDecoration: 'underline',
    },
}));

type Props = {
    title: string;
    message: string;
    url?: string;
    variant: 'warning' | 'error' | 'info';
};
