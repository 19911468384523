import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import { ChangeEvent, useEffect, useState } from 'react';
import * as React from 'react';
import { separateOrderApi } from 'src/api/letseatmanager/pos/separateOrderApi';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CartItemVm } from 'src/types/CartItemVm';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { splitGroupedItems } from 'src/utils/order/splitGroupedItems';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { useAction } from 'src/utils/react/useAction';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function SeparateOrderDialog({ open, handleClose }: Props): React.ReactElement | null {
    const classes = useStyles();
    const editPendingOrder = useEditPendingOrder();

    const [selectedOrderItemsToSeparate, setSelectedOrderItemsToSeparate] = useState<Array<CartItemVm>>([]);
    const [loading, setLoading] = useState(false);

    const items = useSelector((state) => state.pos.items);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);

    const closePosMenu = useAction(posReducer.actions.closePosMenu);

    useEffect(() => {
        if (open) {
            setSelectedOrderItemsToSeparate([]);
        }
    }, [items, open]);

    const handleSubmit = async () => {
        const updatedOrderItemsToSeparate = getSeparatedOrderItems() as any;
        const updatedOrderItems = getUpdatedOrderItems() as any;
        if (!updatedOrderItemsToSeparate) return;

        setLoading(true);
        const response = await separateOrderApi({
            orderId: pendingOrder.orderId,
            firstName: pendingOrder?.firstName,
            lastName: pendingOrder?.lastName,
            orderItemsToSeparate: updatedOrderItemsToSeparate.map(mapCartItemToOrderItem),
            orderItems: updatedOrderItems.map(mapCartItemToOrderItem),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
        handleClose();
        closePosMenu();
        if (response.data) editPendingOrder({ order: response.data as OrderVm });
    };

    const getSeparatedOrderItems = () => {
        const updatedOrderItemsToSeparate = removeNulls(
            items.map((item) => {
                const separatedOrderItems = selectedOrderItemsToSeparate.filter((selectedItem) => selectedItem.key === item.key);
                if (!separatedOrderItems.length) return null;
                if (isItemSoldByWeight(item)) return item;
                return {
                    ...item,
                    quantity: separatedOrderItems.length,
                };
            }),
        );

        return updatedOrderItemsToSeparate;
    };

    const getUpdatedOrderItems = () => {
        const updatedOrderItems = removeNulls(
            items.map((item) => {
                const separatedOrderItems = selectedOrderItemsToSeparate.filter((selectedItem) => selectedItem.key === item.key);
                const newQuantity = BigNumber(item.quantity).minus(separatedOrderItems.length).toNumber();
                if (BigNumber(newQuantity).isZero() || (separatedOrderItems.includes(item) && isItemSoldByWeight(item))) return null;
                return {
                    ...item,
                    quantity: newQuantity,
                };
            }),
        );
        return updatedOrderItems;
    };

    const onSelectOrderItem = (e: ChangeEvent<HTMLInputElement>, cartItem: CartItemVm) => {
        const orderItemsToSeparate = selectedOrderItemsToSeparate.filter((item) => item.key === cartItem.key);
        const updatedOrderItems = selectedOrderItemsToSeparate.filter((item) => item.key !== cartItem.key);
        if (!e.target.checked) {
            orderItemsToSeparate.pop();
            setSelectedOrderItemsToSeparate([...updatedOrderItems, ...orderItemsToSeparate]);
            return;
        }
        setSelectedOrderItemsToSeparate([...selectedOrderItemsToSeparate, cartItem]);
    };

    return (
        <Dialog title={translate('Select items')} open={open} onClose={handleClose}>
            <DialogContent>
                {splitGroupedItems(items)?.map((orderItem: CartItemVm, idx) => {
                    const modifiers = orderItem.modifierGroups?.flatMap((modifierGroup) => modifierGroup.modifiers);
                    const quantity = isItemSoldByWeight(orderItem) ? `${orderItem.quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : orderItem.quantity.toString();

                    return (
                        <div>
                            <Checkbox name={`${orderItem.key}-${idx}`} label={`${orderItem.name}  ${quantity}`} onChange={(e) => onSelectOrderItem(e, orderItem)} />
                            <div className={classes.modifiersContainer}>
                                {modifiers?.map((modifier) => (
                                    <Text className={classes.modifierText} variant={'paragraph'}>
                                        {modifier?.name}
                                    </Text>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button variant={'secondary'} onClick={handleClose} disabled={loading}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleSubmit} disabled={loading || !selectedOrderItemsToSeparate.length}>
                    {translate('Separate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    modifiersContainer: {
        marginLeft: 35,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        color: theme.palette.secondary.contrastText,
    },
    modifierText: {
        color: theme.palette.text.secondary,
    },
}));

type Props = {
    open: boolean;
    handleClose: () => void;
};
