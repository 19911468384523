import { BigNumber } from 'bignumber.js';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { useNotification } from 'src/services/notification/useNotification';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import type { PosTipVm } from 'src/types/PosTipVm';
import { calculateRestaurantCurrencyTipAmount } from 'src/utils/order/calculateRestaurantCurrencyTipAmount';
import { calculateTipAmount } from 'src/utils/order/calculateTipAmount';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { createPaymentDistribution } from 'src/utils/pos/createPaymentDistribution';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function useAddPosTip(): Result {
    const notification = useNotification();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();

    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const customers = useSelector((state) => state.pos.customers);
    const items = useSelector((state) => state.pos.items);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const orderType = useSelector((state) => state.pos.orderType);
    const discount = useSelector((state) => state.pos.discount);
    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);
    const country = useSelector((state) => state.app.restaurant.country);

    const addPosTip = useAction(posReducer.actions.addPosTip);
    const addPosCustomerTip = useAction(posReducer.actions.addPosCustomerTip);
    const setPayments = useAction(posReducer.actions.setPayments);
    const setTotalPaid = useAction(posReducer.actions.setTotalPaid);

    const hasPaymentWithCard = payments?.some((payment: PosPayment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);
    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable;

    const customerHasPaymentWithCard = (customerNumber: number) => {
        const customerPayments = payments.filter((posPayment) => posPayment.customerNumber === customerNumber) ?? [];
        return customerPayments?.some((payment: PosPayment) => payment.paymentMethod === PaymentMethods.CREDIT_CARD);
    };

    const hasCustomerTip = (customerNumber: number) => {
        return payments.some((posPayment) => posPayment.customerNumber === customerNumber && posPayment.isTipPayment);
    };

    const addTip = (tip: PosTipVm) => {
        const { customerNumber } = tip;

        const paymentId = newUuid();
        const customer = customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);

        if (!hasPaymentWithCard && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod) {
            return notification({ message: translate('Cannot add tip with card when not paying with credit card') });
        }

        if (customerNumber && hasCustomerTip(customerNumber)) return notification({ message: translate('Cannot add tip when customer already has tip') });

        if (hasPaymentWithCard && payWithTerminalAvailable && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod && !tip.isTipFromChange) {
            return notification({ message: translate('Cannot add tip with credit card payment completed') });
        }

        const paymentBeforeTips = createPaymentDistribution({
            orderItems: items,
            payments,
            country,
            discount,
            driverRequest,
            orderType,
        });

        const tipAmount = calculateTipAmount(customer ? (BigNumber(customer.payment?.total ?? 0).toNumber() ?? 0) : BigNumber(paymentBeforeTips.total).toNumber(), tip);
        const restaurantCurrencyAmount =
            tip.restaurantCurrencyAmount ??
            calculateRestaurantCurrencyTipAmount(customer ? (BigNumber(customer.payment?.total ?? 0).toNumber() ?? 0) : BigNumber(paymentBeforeTips.total).toNumber(), tip);

        if (!customerNumber) {
            addPosTip({
                ...tip,
                paymentId,
                tipAmount,
                restaurantCurrencyAmount,
            });
        } else {
            if (!customerHasPaymentWithCard(customerNumber) && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod)
                return notification({ message: translate('Cannot add tip when not paying with credit card') });

            addPosCustomerTip({ ...tip, tipAmount: tipAmount.toString(), restaurantCurrencyAmount, paymentId });
        }

        updatedTotalPaid({ ...tip, tipAmount: tipAmount.toString(), restaurantCurrencyAmount }, paymentId);
    };

    const updatedTotalPaid = (tip: PosTipVm, paymentId: any) => {
        const { customerNumber, paymentMethod, customPaymentMethod, isTipFromChange, restaurantCurrencyAmount } = tip;

        if (!isTipFromChange) {
            setPayments([
                ...(payments ?? []),
                { amount: tip.tipAmount, paymentMethod, customPaymentMethod, customerNumber, isTipPayment: true, id: paymentId, restaurantCurrencyAmount, restaurantCurrency: tip.restaurantCurrency },
            ]);

            setTotalPaid(
                BigNumber(totalPaid ?? 0)
                    .plus(tip.tipAmount ?? 0)
                    .toString(),
            );

            return;
        }

        const currentPayments = [...payments];
        currentPayments.push({
            id: paymentId,
            amount: tip.tipAmount.toString(),
            paymentMethod,
            customPaymentMethod,
            customerNumber,
            isTipPayment: true,
            isTipFromChange: true,
            restaurantCurrencyAmount: tip.restaurantCurrencyAmount,
            restaurantCurrency: tip.restaurantCurrency,
        });

        setPayments(currentPayments);
    };

    return addTip;
}

type Result = (arg1: PosTipVm) => void;
