import Grid from '@material-ui/core/Grid';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { OrderPriceInfoRow } from 'src/components/orderCard/OrderPriceInfoRow';
import { Apps } from 'src/constants/App';
import { OrderTypes } from 'src/constants/OrderType';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { OrderTaxVm } from 'src/types/OrderTaxVm';
import type { OrderVm } from 'src/types/OrderVm';
import { isLetsEatManagerApp } from 'src/utils/app/isLetsEatManagerApp';
import { isPideDirectoApp } from 'src/utils/app/isPideDirectoApp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { formatAsDistance } from 'src/utils/number/formatAsDistance';
import { calculateIva } from 'src/utils/order/calculateIva';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { getInternalOrderCost } from 'src/utils/order/getInternalOrderCost';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCustomDiscountComment } from 'src/utils/translate/translateCustomDiscountComment';

export function OrderPriceInfo({ order }: Props): React.ReactElement {
    const isRestaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);

    const isIntegrationOrder = order.app === Apps.DIDI_FOOD || order.app === Apps.UBER_EATS || order.app === Apps.RAPPI;
    const orderCost = isRestaurantUser ? getBaseOrderCost(order) : getInternalOrderCost(order);

    const orderHasDeliveryCost = BigNumber(order.deliveryCost ?? 0).isZero();
    const orderHasInvoicedDeliveryCost = order.invoiceRestaurantDeliveryCost && !BigNumber(order.restaurantDeliveryCost ?? 0).isZero();
    const showDeliveryInternalCost = (!orderHasDeliveryCost || orderHasInvoicedDeliveryCost) && !isIntegrationOrder;
    const orderHasSubsidizedDeliveryCost = order.restaurantSubsidizedDeliveryCost && !BigNumber(order.restaurantSubsidizedDeliveryCostAmount ?? 0).isZero();
    const showDynamicEarning = !BigNumber(order.dynamicDeliveryEarnings ?? 0).isZero() && !isIntegrationOrder;
    const hasInternalBonusDeliveryCost = !BigNumber(order.deliveryCostBonus ?? 0).isZero() && !isIntegrationOrder;
    const showDriverTip = !BigNumber(order.driverTip ?? 0).isZero() && !isIntegrationOrder;
    const showDiscount =
        !BigNumber(order.discount ?? 0).isZero() &&
        !BigNumber(order.discount ?? 0).isEqualTo(order.posDiscount ?? 0) &&
        !order.promoCodeId &&
        !order.restaurantPromoCodeCost &&
        !order.promotionsDiscount;
    const showTip = !BigNumber(order.posTipAmount ?? 0).isZero() && !isIntegrationOrder;
    const showPosDiscount = !BigNumber(order.posDiscount ?? 0).isZero() && BigNumber(order.discount ?? 0).isEqualTo(order.posDiscount ?? 0);
    const orderHasDeliveryEarning = !BigNumber(order.deliveryEarnings ?? 0).isZero();
    const showOrderCost = !BigNumber(order.driverPaysRestaurant ?? order.total ?? 0).isEqualTo(order.amountWithDiscount) && isIntegrationOrder;
    const hasPromoCodeCredits = order?.promoCodeRewardType === RewardTypes.CREDITS && order?.promoCodeCredits;
    const hideIvaToTakeAwayOrder = order.orderType === OrderTypes.TAKE_AWAY_ORDER && !!restaurant?.invoicesWithZeroTaxForTakeAwayOrdersEnabled;
    const showIva = !hideIvaToTakeAwayOrder && !restaurant.ordersWithoutIvaEnabled && !order.orderTaxes && !isUruguay(order.country) && restaurantTaxManagementEnabled;
    const showDirectoProtectionCost = order.isProtectedOrder && order.directoProtectionPaidByCustomer;
    const showSubtotal = !BigNumber(order.subtotal).isEqualTo(order.subtotalAfterDiscount);

    const deliveryCostBonus =
        hasInternalBonusDeliveryCost && !!order.driverTip
            ? BigNumber(order.deliveryCostBonus ?? 0)
                  .minus(order.driverTip ?? 0)
                  .toNumber()
            : order.deliveryCostBonus;
    const showBonusDeliveryCost = hasInternalBonusDeliveryCost && !BigNumber(deliveryCostBonus ?? 0).isZero();
    const showDriverPaysRestaurant = order.restaurantSubsidizedDeliveryCostAmount && isCashPayment(order.paymentMethod) && (isPideDirectoApp(order.app) || isLetsEatManagerApp(order.app));

    const getDeliveryCostLabel = () => {
        if (order.invoiceRestaurantDeliveryCost && order.restaurantDeliveryCost) {
            return translate('@amount (@invoiced TO INVOICE)', {
                amount: formatAsCurrencyNumber(order.customerDeliveryCost, { country: order.country as any }),
                invoiced: formatAsCurrencyNumber(order.restaurantDeliveryCost, { country: order.country as any }),
            });
        }

        if (order.deliveryPaidByRestaurant) {
            return `(${translate('Paid by Restaurant')}) -${formatAsCurrencyNumber(order.restaurantDeliveryCost, { country: order.country as any })}`;
        }

        return formatAsCurrencyNumber(order.deliveryCost ?? 0, { country: order.country as any });
    };

    return (
        <Grid item xs={12}>
            {(showOrderCost || !isIntegrationOrder) && <OrderPriceInfoRow title={translate('Order cost:')} orderInfo={formatAsCurrencyNumber(order.subtotal, { country: order.country as any })} />}
            {!isIntegrationOrder && order.tax && <OrderPriceInfoRow title={translate('Tax:')} orderInfo={formatAsCurrencyNumber(order.tax, { country: order.country as any })} />}
            {!isIntegrationOrder && order.marketplaceServiceFee && (
                <OrderPriceInfoRow
                    title={translate('Directo service fee @rate%:', { rate: order.marketplaceServiceFeeRate })}
                    orderInfo={formatAsCurrencyNumber(order.marketplaceServiceFee, { country: order.country as any })}
                />
            )}
            {!isIntegrationOrder && !!order.restaurantServiceFee && !!order.restaurantServiceFeeRate && (
                <OrderPriceInfoRow
                    title={translate('Service fee @rate%:', { rate: order.restaurantServiceFeeRate * 100 })}
                    orderInfo={formatAsCurrencyNumber(order.restaurantServiceFee, { country: order.country as any })}
                />
            )}
            {isIntegrationOrder && <OrderPriceInfoRow title={translate('Subtotal (Excluded Discount):')} orderInfo={formatAsCurrencyNumber(order.subtotal, { country: order.country as any })} />}
            {showDiscount && <OrderPriceInfoRow title={translate('Discount:')} orderInfo={`${formatAsCurrencyNumber(order.discount, { country: order.country as any })}`} />}
            {showPosDiscount && !isIntegrationOrder && (
                <OrderPriceInfoRow title={translate('POS Discount:')} orderInfo={`-${formatAsCurrencyNumber(order?.posDiscount ?? 0, { country: order.country as any })}`} />
            )}
            {order.posDiscountNotes && !isIntegrationOrder && (
                <OrderPriceInfoRow title={translate('NOTE : @posDiscountNotes', { posDiscountNotes: translateCustomDiscountComment(order?.posDiscountNotes) })} />
            )}
            {order.restaurantPromoCodeCost && !order.promoCodeId && (
                <OrderPriceInfoRow title={translate('Restaurant discount:')} orderInfo={`-${formatAsCurrencyNumber(order.discount, { country: order.country as any }) ?? 0}`} />
            )}
            {showTip && <OrderPriceInfoRow title={translate('Tip:')} orderInfo={formatAsCurrencyNumber(order.posTipAmount, { country: order.country as any })} />}
            {showSubtotal && !isIntegrationOrder && (
                <OrderPriceInfoRow title={translate('Subtotal')} orderInfo={formatAsCurrencyNumber(order.subtotalAfterDiscount ?? 0, { currency: order.currency })} />
            )}
            {order.promoCode && (
                <>
                    <OrderPriceInfoRow
                        title={translate('Promo Code @promoCode:', { promoCode: order.promoCode })}
                        orderInfo={
                            hasPromoCodeCredits
                                ? `${order?.promoCodeCredits ?? ''} ${translate('Credits')}`
                                : `-${formatAsCurrencyNumber(order?.promoCodeDiscount ?? '', { country: order.country as any })}`
                        }
                    />
                    <OrderPriceInfoRow title={translate('Promo Code type:')} orderInfo={translate(`RewardTypes.${order.promoCodeRewardType ?? ''}`)} />
                </>
            )}
            {order.promotionsDiscount && (
                <OrderPriceInfoRow title={translate('Promotions discount:')} orderInfo={`-${formatAsCurrencyNumber(order.promotionsDiscount ?? 0, { country: order.country as any })}`} />
            )}
            {order.usedRestaurantCashbackCredits && (
                <OrderPriceInfoRow title={translate('Cashback:')} orderInfo={formatAsCurrencyNumber(order.usedRestaurantCashbackCredits, { country: order.country as any })} />
            )}
            {orderHasDeliveryEarning && isIntegrationOrder && (
                <OrderPriceInfoRow title={translate('Delivery Cost')} orderInfo={formatAsCurrencyNumber(order.deliveryEarnings, { country: order.country as any })} />
            )}
            {order.extraDrivingDistance && <OrderPriceInfoRow title={translate('Extra Driving Distance')} orderInfo={formatAsDistance(order.extraDrivingDistance)} />}
            {isIntegrationOrder && (
                <OrderPriceInfoRow title={translate('Total (Included Discount):')} orderInfo={formatAsCurrencyNumber(order.amountWithDiscount, { country: order.country as any })} />
            )}
            {isIntegrationOrder && !orderHasDeliveryCost && (
                <OrderPriceInfoRow title={translate('External Delivery Cost:')} orderInfo={formatAsCurrencyNumber(order.deliveryCost, { country: order.country as any })} />
            )}
            {!BigNumber(order.usedCredits ?? 0).isZero() && (
                <OrderPriceInfoRow title={translate('Credits:')} orderInfo={`-${formatAsCurrencyNumber(order?.usedCredits ?? 0, { country: order.country as any })}`} />
            )}
            {showDeliveryInternalCost && <OrderPriceInfoRow title={translate('Delivery Cost:')} orderInfo={getDeliveryCostLabel()} />}

            {orderHasSubsidizedDeliveryCost && (
                <OrderPriceInfoRow
                    title={translate('Subsidized delivery cost:')}
                    orderInfo={`-${formatAsCurrencyNumber(order.restaurantSubsidizedDeliveryCostAmount, { country: order.country as any })}`}
                />
            )}
            {showDriverPaysRestaurant && (
                <OrderPriceInfoRow title={translate('Charge Delivery Driver:')} orderInfo={formatAsCurrencyNumber(order.driverPaysRestaurant, { country: order.country as any })} />
            )}
            {showDirectoProtectionCost && (
                <OrderPriceInfoRow title={translate('Directo Protection (Paid by customer):')} orderInfo={formatAsCurrencyNumber(order.pideDirectoProtectionCost, { country: order.country as any })} />
            )}
            {showDynamicEarning && (
                <OrderPriceInfoRow title={translate('Dynamic Delivery Earning:')} orderInfo={formatAsCurrencyNumber(order.dynamicDeliveryEarnings, { country: order.country as any })} />
            )}
            {showBonusDeliveryCost && <OrderPriceInfoRow title={translate('Delivery Cost Bonus:')} orderInfo={formatAsCurrencyNumber(deliveryCostBonus, { country: order.country as any })} />}
            {showDriverTip && <OrderPriceInfoRow title={`${translate('Driver tips')}:`} orderInfo={formatAsCurrencyNumber(order.driverTip, { country: order.country as any })} />}
            {showIva && (
                <OrderPriceInfoRow
                    title={`${translate('IVA')}:`}
                    orderInfo={formatAsCurrencyNumber(calculateIva(order.total, order.country), { country: order.country as any })}
                    tooltip={translate('Included in the total')}
                />
            )}
            {!!order.orderTaxes?.length &&
                order.orderTaxes?.map((tax: OrderTaxVm) => (
                    <OrderPriceInfoRow title={`${tax.name}:`} orderInfo={formatAsCurrencyNumber(tax.taxAmount, { country: order.country as any })} tooltip={translate('Included in the total')} />
                ))}
            {(showOrderCost || !isIntegrationOrder) && <OrderPriceInfoRow title={'Total:'} orderInfo={formatAsCurrencyNumber(orderCost ?? 0, { country: order.country as any })} />}
        </Grid>
    );
}

type Props = {
    order: OrderVm;
};
