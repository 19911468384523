import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { NubefactCreditNoteTypes } from 'src/constants/NubefactCreditNoteType';
import { translate } from 'src/i18n/translate';

export function FormCreditNoteTypeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const options = Object.values(NubefactCreditNoteTypes).map((NubefactCreditNoteType) => ({ label: translate(`NubefactCreditNoteTypes.${NubefactCreditNoteType}`), value: NubefactCreditNoteType }));
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: number | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
