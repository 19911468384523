import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findActiveCustomAlertDialogApi } from 'src/api/letseatmanager/customAlertDialog/findActiveCustomAlertDialogApi';
import { CustomAlertDialog } from 'src/components/dialog/CustomAlertDialog';
import { getLastActiveCustomAlertDialogIdInLocalStorage } from 'src/localStorage/getLastActiveCustomAlertDialogIdInLocalStorage';
import { setLastActiveCustomAlertDialogIdInLocalStorage } from 'src/localStorage/setLastActiveCustomAlertDialogIdInLocalStorage';
import { isRecurrentCustomAlertDialog } from 'src/utils/customAlertDialog/isRecurrentCustomAlertDialog';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomAlertDialogHandler(): React.ReactElement | null {
    const [open, setOpen] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);

    const [loading, customAlertDialog] = useLoadApi(findActiveCustomAlertDialogApi, { restaurantId }, { requiredValues: [restaurantId] });

    useEffect(() => {
        if (customAlertDialog) load();
    }, [customAlertDialog, loading]);

    const load = async () => {
        if (loading) return;
        if (isRecurrentCustomAlertDialog(customAlertDialog?.customAlertDialogType)) return setOpen(true);
        const customAlertDialogId = getLastActiveCustomAlertDialogIdInLocalStorage();
        if (customAlertDialogId !== customAlertDialog?.customAlertDialogId) {
            setOpen(true);
            setLastActiveCustomAlertDialogIdInLocalStorage(customAlertDialog?.customAlertDialogId);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!customAlertDialog) return null;
    if (adminUser) return null;

    return (
        <CustomAlertDialog
            open={open}
            title={customAlertDialog?.title}
            text={customAlertDialog?.text}
            secondsToAccept={BigNumber(customAlertDialog?.secondsToAccept).toNumber()}
            onClose={handleClose}
        />
    );
}
