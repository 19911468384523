export const FilterReportTypes = {
    DAY: 'DAY',
    YESTERDAY: 'YESTERDAY',
    WEEK: 'WEEK',
    FIFTEEN_DAYS: 'FIFTEEN_DAYS',
    MONTH: 'MONTH',
    PERIOD: 'PERIOD',
} as const;

export type FilterReportType = (typeof FilterReportTypes)[keyof typeof FilterReportTypes];
