export const en: {
    [key: string]: string;
} = {
    'Sign In Account': ' ', // Change translation of aws amplify login header
    'QPAY': 'Qpay',
    'CONEKTA': 'Conekta',
    'STRIPE': 'Stripe',
    'SPEI': 'SPEI and OXXO',
    'CARD': 'Card',
    'SPEI_INTERNAL': 'SPEI Internal',
    'TIME_30_40_MIN': 'Driver arrives at store in 30 - 40 min',
    'TIME_40_50_MIN': 'Driver arrives at store in 40 - 50 min',
    'TIME_50_60_MIN': 'Driver arrives at store in 50 - 60 min',
    'MX': 'Mexico',
    'AR': 'Argentina',
    'UY': 'Uruguay',
    'SE': 'Sweden',
    'US': 'USA',

    'Countries.MX': 'Mexico',
    'Countries.SE': 'Sweden',
    'Countries.US': 'USA',
    'Countries.UY': 'Uruguay',
    'Countries.AR': 'Argentina',
    'Countries.BR': 'Brazil',
    'Countries.PE': 'Peru',
    'Countries.CL': 'Chile',
    'Countries.PY': 'Paraguayan',

    'States.QUERETARO': 'Queretaro',
    'States.AGUASCALIENTES': 'Aguascalientes',
    'States.BAJA_CALIFORNIA': 'Baja California',
    'States.BAJA_CALIFORNIA_SUR': 'Baja California sur',
    'States.CAMPECHE': 'Campeche',
    'States.CDMX': 'Ciudad de México',
    'States.CHIAPAS': 'Chiapas',
    'States.CHIHUAHUA': 'Chihuahua',
    'States.COAHUILA': 'Coahuila',
    'States.COLIMA': 'Colima',
    'States.DURANGO': 'Durango',
    'States.GUANAJUATO': 'Guanajuato',
    'States.GUERRERO': 'Guerrero',
    'States.HIDALGO': 'Hidalgo',
    'States.JALISCO': 'Jalisco',
    'States.MICHOACAN': 'Michoacán',
    'States.MORELOS': 'Morelos',
    'States.ESTADO_DE_MEXICO': 'Estado de México',
    'States.NAYARIT': 'Nayarit',
    'States.NUEVO_LEON': 'Nuevo León',
    'States.OAXACA': 'Oaxaca',
    'States.PUEBLA': 'Puebla',
    'States.QUINTANA_ROO': 'Quintana Roo',
    'States.SAN_LUIS_POTOSI': 'San Luis Potosí',
    'States.SINALOA': 'Sinaloa',
    'States.SONORA': 'Sonora',
    'States.TABASCO': 'Tabasco',
    'States.TAMAULIPAS': 'Tamaulipas',
    'States.TLAXCALA': 'Tlaxcala',
    'States.VERACRUZ': 'Veracruz',
    'States.YUCATAN': 'Yucatán',
    'States.ZACATECAS': 'Zacatecas',

    'PaymentMethods.CREDIT_CARD': 'Card',
    'PaymentMethods.CARD_ON_DELIVERY': 'Card on delivery',
    'PaymentMethods.CASH': 'Cash',
    'PaymentMethods.PENDING': 'Pending',
    'PaymentMethods.PAYMENT_LINK': 'Payment link',
    'PaymentMethods.CREDITS': 'Credits',
    'PaymentMethods.TRANSFER': 'Transfer',
    'PaymentMethods.PAYMENT_TERMINAL': 'Payment terminal',

    'MexicanPaymentMethodCodes.CASH': '01 - Cash',
    'MexicanPaymentMethodCodes.NOMINAL_CHECK': '02 - Nominal check',
    'MexicanPaymentMethodCodes.ELECTRONIC_FUNDS_TRANSFER': '03 - Electronic funds transfer',
    'MexicanPaymentMethodCodes.CREDIT_CARD': '04 - Credit card',
    'MexicanPaymentMethodCodes.ELECTRONIC_WALLET': '05 - Electronic wallet',
    'MexicanPaymentMethodCodes.ELECTRONIC_MONEY': '06 - Electronic money',
    'MexicanPaymentMethodCodes.DEBIT_CARD': '28 - Debit card',
    'MexicanPaymentMethodCodes.SERVICE_CARD': '29 - Service card',
    'MexicanPaymentMethodCodes.TO_DEFINE': '99 - To define',

    'MexicanPaymentMethodCodes.01': '01 - Cash',
    'MexicanPaymentMethodCodes.02': '02 - Nominal check',
    'MexicanPaymentMethodCodes.03': '03 - Electronic funds transfer',
    'MexicanPaymentMethodCodes.04': '04 - Credit card',
    'MexicanPaymentMethodCodes.05': '05 - Electronic wallet',
    'MexicanPaymentMethodCodes.06': '06 - Electronic money',
    'MexicanPaymentMethodCodes.28': '28 - Debit card',
    'MexicanPaymentMethodCodes.29': '29 - Service card',
    'MexicanPaymentMethodCodes.99': '99 - To define',

    'TAKE_AWAY_ORDER': 'Take Away',
    'DELIVERY_ORDER': 'Delivery',
    'TABLE_ORDER': 'Eat Here',
    'CREDIT_CARD': 'Credit Card',
    'TRANSFER': 'Transfer',
    'CASH': 'Cash',

    'PLANNED': 'Planned',
    'ASAP': 'ASAP',
    'PLANNED_MENU_ITEMS': 'Delivery date',
    'CashRegisterDepositReasons.CHANGE': 'Change',
    'CashRegisterWithdrawReasons.DRIVERS': 'Drivers',
    'CashRegisterWithdrawReasons.DELIVERY': 'Delivery',
    'ProductCancellationReasons.CUSTOMER_DIDNT_LIKE_PRODUCT': 'The client did not like it',
    'ProductCancellationReasons.DEFECTIVE_PRODUCT': 'Defective product',
    'ProductCancellationReasons.CAPTURE_ERROR': 'Capture Error',
    'LETSEATAPP': "Let's Eat",
    'PIDEDIRECTO': 'AmbitOne Web menu',
    'LETSEATMANAGER': 'Manual Order',
    'PIDEDIRECTOAPP': 'AmbitOne menu App',
    'PIDEDIRECTOPOS': 'AmbitOne POS',
    'SMALL': 'Small',
    'MEDIUM': 'Medium',
    'LARGE': 'Large',
    'SQUARE': 'Square',
    'CIRCLE': 'Circle',
    'RECTANGLE': 'Rectangle',
    'UnitOfMeasurements.KILOGRAM': 'Kilogram',
    'UnitOfMeasurements.GRAM': 'Gram',
    'UnitOfMeasurements.LITRE': 'Litre',
    'UnitOfMeasurements.MILLILITER': 'Milliliter',
    'UnitOfMeasurements.BOX': 'Box',
    'UnitOfMeasurements.BAG': 'Bag',
    'UnitOfMeasurements.PIECE': 'Piece',
    'UnitOfMeasurements.PACKAGE': 'Package',
    'UnitOfMeasurements.STICK': 'Stick',
    'UnitOfMeasurements.ROLL': 'Roll',
    'UnitOfMeasurements.CAN': 'Can',
    'UnitOfMeasurements.SLICE': 'Slice',
    'UnitOfMeasurements.POUND': 'Pound',
    'UnitOfMeasurements.OUNCE': 'Ounce',
    'UnitOfMeasurements.TABLESPOON': 'Tablespoon',
    'UnitOfMeasurements.CUP': 'Cup',
    'UnitOfMeasurements.GALLON': 'Gallon',
    'UnitOfMeasurements.QUART': 'Quart',
    'InventoryTransactionTypes.IN': 'Input',
    'InventoryTransactionTypes.OUT': 'Output',
    'InventoryLogTypes.INVENTORY_RECEIPT': 'Receipt',
    'InventoryLogTypes.INVENTORY_ISSUE': 'Issue',
    'InventoryLogIssues.TRANSFERENCE': 'Transference',
    'InventoryLogIssues.WASTE': 'Waste',
    'InventoryLogIssues.ACCIDENT': 'Accident',
    'InventoryLogReceipts.TRANSFERENCE': 'Transference',
    'InventoryLogReceipts.DELIVERY': 'Delivery',
    'InventoryLogReceipts.BUY_ORDER': 'Buy order',
    'InventoryTransactionReasons.TRANSFERENCE': 'Transference',
    'InventoryTransactionReasons.WASTE': 'Waste',
    'InventoryTransactionReasons.ACCIDENT': 'Accident',
    'InventoryTransactionReasons.ORDER': 'Purchase Order',
    'InventoryTransactionReasons.PURCHASE': 'Purchase',
    'InventoryTransactionReasons.ADJUSTMENT': 'Ajustment',
    'InventoryTransactionReasons.CANCEL': 'Cancel',
    'InventoryTransactionReasons.COUNT_UPDATE': 'Count Update',
    'SupplyTypes.NORMAL': 'Normal',
    'SupplyTypes.COMPOUND': 'Compound',
    'InventoryTransactionReasons.BUY_ORDER': 'Buy order',
    'Days.Mo': 'Monday',
    'Days.Tu': 'Tuesday',
    'Days.We': 'Wednesday',
    'Days.Th': 'Thursday',
    'Days.Fr': 'Friday',
    'Days.Sa': 'Saturday',
    'Days.Su': 'Sunday',
    'ScheduleTaskIntervals.EACH_MONTH': 'Each month',
    'ScheduleTaskIntervals.EACH_WEEK': 'Each week',
    'ScheduleTaskIntervals.EACH_DAY': 'Each day',
    'ScheduleTaskIntervals.NO_REPEAT': 'No repeat',
    'PERCENT': 'Percent',
    'AMOUNT': 'Amount',
    'CustomerActivities.D1': "Haven't ordered since 1 day ago",
    'CustomerActivities.D3': "Haven't ordered since 3 days ago",
    'CustomerActivities.D5': "Haven't ordered since 5 days ago",
    'CustomerActivities.D7': "Haven't ordered since 7 days ago",
    'CustomerActivities.D10': "Haven't ordered since 10 days ago",
    'CustomerActivities.D15': "Haven't ordered since 15 days ago",
    'CustomerActivities.D30': "Haven't ordered since 30 days ago",
    'CustomerActivities.D60': "Haven't ordered since 60 days ago",
    'CustomerActivities.D90': "Haven't ordered since 90 days ago",
    'DefaultSupplyCategories.DRINKS': 'Drinks',
    'DefaultSupplyCategories.PERISHABLES': 'Persishables',
    'DefaultSupplyCategories.FROZEN': 'Frozen',
    'DefaultSupplyCategories.PROTEINS': 'Proteins',
    'DefaultSupplyCategories.VEGETABLES': 'Vegetables',
    'DefaultSupplyCategories.DRESSINGS': 'Dressings',
    'DefaultSupplyCategories.PACKING': 'Packing',
    'DefaultSupplyCategories.DAIRIES': 'Dairies',
    'UserTypes.ADMIN': 'Admin',
    'UserTypes.SUPPORT': 'Support',
    'UserTypes.OPERATIONS': 'Operations',
    'UserTypes.VIEW': 'View',
    'UserTypes.KITCHENS': 'Kitchens',
    'UserTypes.RESTAURANT': 'Restaurant',
    'UserTypes.CASHIER': 'Cashier',
    'UserTypes.WAITER': 'Waiter',
    'UserTypes.KIOSK': 'Kiosk',
    'UserTypes.KITCHEN_DISPLAY_SCREEN': 'Kitchen Display',
    'ClientFrequency.NEW': 'New',
    'ClientFrequency.RECURRENT': 'Recurrent',
    'ClientFrequency.LOST': 'Lost',
    'ClientFrequency.RECOVERED': 'Recovered',
    'ClientFrequency.HEAVY_USER': 'Heavy User',
    'ClientFrequency.GHOST_USER': 'Ghost User',
    'ClientFrequency.UNTRACKED': 'Untracked',
    'PrinterContents.COMMAND': 'Command',
    'PrinterContents.TICKET': 'Ticket',
    'PrinterContents.BOTH': 'Both',
    'PrinterTypes.WIFI': 'WiFi/Ethernet',
    'PrinterTypes.USB': 'USB',
    'PrinterTypes.BLUETOOTH': 'Bluetooth',
    'PrinterTypes.PAYMENT_TERMINAL_PRINTER': 'Terminal Printer',
    'PrinterTypes.SDK': 'SDK',
    'ModifierTypes.SINGLE': 'Single',
    'ModifierTypes.MULTIPLE': 'Multiple',
    'ManagerUserActions.ORDER_CANCELLATION': 'Order cancellation',
    'ManagerUserActions.MANUAL_ASSIGNMENT': 'Manual assignment',
    'ManagerUserActions.MANUAL_EARNING': 'Manual earning',
    'Apps.UBER_EATS': 'Uber Eats',
    'Apps.DIDI_FOOD': 'Didi',
    'Apps.RAPPI': 'Rappi',
    'Apps.PIDEDIRECTO': 'AmbitOne',
    'Apps.PIDEDIRECTOAPP': 'AmbitOne MarketPlace',
    'Apps.LETSEATAPP': 'LetsEat',
    'Apps.PIDEDIRECTOPOS': 'AmbitOne POS',
    'Apps.PIDEDIRECTOKIOSK': 'AmbitOne Kiosk',
    'Apps.LETSEATMANAGER': 'Manual Order',
    'RestaurantPaymentMethodApps.PIDEDIRECTO': 'AmbitOne',
    'RestaurantPaymentMethodApps.PIDEDIRECTOAPP': 'AmbitOne MarketPlace',
    'RestaurantPaymentMethodApps.PIDEDIRECTOPOS': 'AmbitOne POS',
    'RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK': 'AmbitOne Kiosk',
    'RestaurantPaymentMethodApps.PIDEDIRECTOADMIN': 'Pide Directo Admin',
    'RestaurantPaymentMethodApps.LETSEATAPP': 'LetsEat',
    'DashboardReports.GENERAL': 'General',
    'DashboardReports.MENU': 'By menu',
    'DashboardReports.CUSTOMER': 'By customers',
    'DashboardReports.PROMO_CODE': 'By promo code',
    'DashboardReports.DAY': 'By day',
    'DashboardReports.FINANCE': 'Finance',
    'DashboardReports.SMART_BI': 'Smart BI',
    'DashboardReports.SALES': 'Sales',
    'DashboardReports.ABC': 'ABC Analysis',
    'DashboardReports.ISSUES': 'Issues',
    'DashboardReports.DELIVERY': 'Delivery',
    'LogEvents.ORDERS': 'Orders',
    'LogEvents.DELIVERY': 'Delivery',
    'FilterReportTypes.DAY': 'Today',
    'FilterReportTypes.YESTERDAY': 'Yesterday',
    'FilterReportTypes.WEEK': 'Last 7 days',
    'FilterReportTypes.FIFTEEN_DAYS': 'Last 15 days',
    'FilterReportTypes.MONTH': 'Last 30 days',
    'FilterReportTypes.PERIOD': 'Period',
    'OfflineReasons.OUT_OF_MENU_HOURS': 'Out of menu hours',
    'OfflineReasons.INVISIBLE': 'Not visible',
    'OfflineReasons.PAUSED_BY_UBER': 'Paused by uber',
    'OfflineReasons.PAUSED_BY_RESTAURANT': 'Paused by restaurant',
    'Ticket.Payment Methods': 'Payment methods',
    'PrinterBrands.STAR': 'Star',
    'PrinterBrands.XPRINTER': 'Xprinter',
    'PrinterBrands.EPSON': 'Epson',
    'PrinterBrands.GHIA': 'Ghia',
    'PrinterBrands.PIDEDIRECTO': 'PideDirecto',
    'PrinterBrands.IMIN': 'iMin',
    'PrinterBrands.SUNMI': 'Sunmi',
    'PrinterBrands.OTHER': 'Otra',

    'PosBusinessSales.ORDERS': 'Orders by day',
    'PosBusinessSales.PRODUCTS': 'Products',
    'PosBusinessSales.PROMO_CODES': 'Promo Codes',

    'OwnFleetDriverStatuses.PENDING': 'Pending',
    'OwnFleetDriverStatuses.REJECTED': 'Rejected',
    'OwnFleetDriverStatuses.ACCEPTED': 'Accepted',
    'RegistrationStatuses.BASIC_INFO': 'Basic information',
    'RegistrationStatuses.BUSINESS_INFO': 'Business information',
    'RegistrationStatuses.BANK_INFO': 'Bank information',
    'RegistrationStatuses.COMPLETED': 'Completed',

    'RegistrationStatuses.STARTED': 'Incomplete',
    'RegistrationStatuses.FREE_TRIAL': 'Free Trial',
    'RegistrationStatuses.SUBSCRIBED': 'Subscribed',

    'OrderTypes.TABLE_ORDER': 'Eat here',
    'OrderTypes.TAKE_AWAY_ORDER': 'Take away',
    'OrderTypes.DELIVERY_ORDER': 'Delivery',
    'OrderTypes.SELF_SERVICE_ORDER': 'Self service',
    'OrderTypes.ROOM_SERVICE_ORDER': 'Room №',
    'OrderTypes.PICKUP_STATION_ORDER': 'Pick up order station',

    'DeliveryProviders.UBER_DIRECT': 'Uber Direct',
    'DeliveryProviders.UBER_DAAS': 'Uber Daas',
    'DeliveryProviders.RAPPI_CARGO': 'Rappi Cargo',
    'DeliveryProviders.RESTAURANT': 'Restaurant',
    'DeliveryProviders.PIDEDIRECTO': 'Pide Directo',

    'PickupTimeTypes.ASAP': 'ASAP',
    'PickupTimeTypes.PLANNED': 'Planned',
    'PickupTimeTypes.PLANNED_MENU_ITEMS': 'Delivery date',

    'OrderRejectReasonCategories.ESTABLISHMENT': 'Establishment',
    'OrderRejectReasonCategories.CUSTOMER': 'Customer',
    'OrderRejectReasonCategories.OPERATION': 'Operation',
    'OrderRejectReasonCategories.TECH': 'Tech',
    'OrderRejectReasonCategories.RESTAURANT': 'Restaurant',
    'OrderRejectReasonCategories.RAPPICARGO': 'Cancelation by Rappi Cargo',
    'OrderRejectReasonCategories.WITHOUTASSIGNMENT': 'With out Assignment',
    'OrderRejectReasonCategories.EXTRA': '(EXTRA)',
    'OrderRejectReasonCategories.OTHER': 'Other',

    'OrderRejectReasons.CLOSING_SOON': 'Closing soon',
    'OrderRejectReasons.PROBLEM_IN_RESTAURANT': 'Problem in the restaurant',
    'OrderRejectReasons.SOLD_OUT': 'Sold out',
    'OrderRejectReasons.INCORRECT_PRICE': 'The price is incorrect',
    'OrderRejectReasons.DRIVER_NOT_FOUND': 'Driver not found',
    'OrderRejectReasons.DRIVER_DID_NOT_ARRIVE': 'Driver did not arrive',
    'OrderRejectReasons.REJECTED_BY_ADMIN': 'Reject by Admin',
    'OrderRejectReasons.EXTERNAL_COURIER_CANCEL': 'Contact your support team for more information.',
    'OrderRejectReasons.UNASSIGNED_COURIER': 'Unassigned Courier',
    'OrderRejectReasons.CANCELLED_BY_CLIENT_DUE_TO_WAITING_TIME': 'Cancelled by Client Due to Waiting Time',
    'OrderRejectReasons.CANCELLED_DUE_TO_CLIENT_ERROR': 'Cancelled Due to Client Error',
    'OrderRejectReasons.CANCELLED_BY_LACK_OF_CLIENT_CONTACT': 'Cancelled by Lack of Client Contact',
    'OrderRejectReasons.WANT_MODIFY_ORDER': 'I want to modify the order',
    'OrderRejectReasons.WAITING_DELAY_IN_PREPARATION': 'Waiting delay in preparation',
    'OrderRejectReasons.TECH_ERROR_WITH_THE_PLATFORM': 'Tech error with the platform',
    'OrderRejectReasons.ERROR_WITH_CUSTOMER_DATA': 'Error with customer data',
    'OrderRejectReasons.NO_CONTACT_WITH_ALLY': 'No contact with ally',
    'OrderRejectReasons.CHURNED_RESTAURANT': 'Churned Restaurant',
    'OrderRejectReasons.CLOSED_STORE': 'Closed store',
    'OrderRejectReasons.POORLY_PACK_PRODUCT': 'Poorly packaged product (Damaged)',
    'OrderRejectReasons.EXCEEDED_VOLUME': 'Exceeded volume/weight',
    'OrderRejectReasons.NO_CUSTOMER_CONTACT': 'No customer contact',
    'OrderRejectReasons.FRAUD': 'Fraud',
    'OrderRejectReasons.CAN_NOT_PAY_ORDER': "Can't pay the order",
    'OrderRejectReasons.COURIER_ACCIDENT': 'Courier accident',
    'OrderRejectReasons.WEATHER_CONDITIONS': 'Weather conditions',
    'OrderRejectReasons.OUT_OF_COVERAGE': 'Out of coverage',
    'OrderRejectReasons.DUPLICATED_ASSIGMENT': 'Duplicate assignment',
    'OrderRejectReasons.ORDER_HIGH_AMOUNT_LARGE_SIZE': 'Order high amount/large size',
    'OrderRejectReasons.RED_ZONE': 'Red Zone (High risk)',
    'OrderRejectReasons.PROBLEMS_WITH_MANAGER': 'Problems with the Manager',
    'OrderRejectReasons.THE_ORDER_DOESNOT_APPEAR_SYSTEM': 'The order does not appear in the system',
    'OrderRejectReasons.DUPLICATED_ORDER': 'Duplicated order',
    'OrderRejectReasons.MISPLACED_PIN': 'Misplaced pin',
    'OrderRejectReasons.EDIT_ORDER': 'Edit order',
    'OrderRejectReasons.COMENSAL_NOT_RECEIVE': 'Customer did not go to collection',
    'OrderRejectReasons.WRONG_ADDRESS': 'Wrong address',
    'OrderRejectReasons.DELIVERY_ACCIDENT': 'Delivery man accident',
    'OrderRejectReasons.LOW_SUPPLY': 'Low supply',
    'OrderRejectReasons.HIGH_DEMAND': 'High demand',
    'OrderRejectReasons.SATURATION': 'Saturation',
    'OrderRejectReasons.REFUND': 'Refund',
    'OrderRejectReasons.TICKET': 'Ticket',
    'OrderRejectReasons.ORDER_THEFT': 'Incomplete Driver Process',
    'OrderRejectReasonExtras.REFUND': 'Refund',
    'OrderRejectReasonExtras.TICKET': 'Ticket',
    'OrderRejectReasons.SK_DOESNT_ANSWER': 'Restaurant doesnt anser',
    'OrderRejectReasons.PROBLEMS_WITH_CLIENT_PAYMENT': 'Problems with customer payment',
    'OrderRejectReasons.PRODUCT_NOT_AVAILABLE': 'Product not avaible',
    'OrderRejectReasons.SK_DOESNT_WANT_DELIVERY_ORDER_WITH_PRODUCT': 'Restaurant had problems with delivery',
    'OrderRejectReasons.ANOTHER_SK_TOOK_ORDER': 'Orden took by other Restaurant',
    'OrderRejectReasons.SK_COULDNT_COMPLETE_ORDER': 'Restaurant could not complete the order',
    'OrderRejectReasons.INCOMPLETE_ORDER_BAD_STATE': 'The order was messed up',
    'OrderRejectReasons.DOES_NOT_WORK_WITH_RAPPI': 'Establishment does not work with Rappi',
    'OrderRejectReasons.DIDNT_CANCEL_BY_AUTOMATION': 'Order was not canceled by automation',
    'OrderRejectReasons.RELEASED_WITH_PRODUCT': 'Order released with product',
    'OrderRejectReasons.CLIENT_WHIM_NOT_ALLOWED_FAVOR': 'Order stop not allowed',
    'OrderRejectReasons.BILLING_ERROR': 'Billing Error',
    'OrderRejectReasons.INNER_TEST': 'Inner Test',
    'OrderRejectReasons.REVIEW': 'Under Review',
    'OrderRejectReasons.CANCEL_BY_USER': 'Cancelled By Restaurant',
    'OrderRejectReasons.OTHER': 'Other',
    'OrderRejectReasons.MANAGE_UBER_EATS_ORDERS_NOT_ALLOWED': 'Manage uber eats orders not allowed',

    'BannerVisibilities.EVERYWHERE': 'Everywhere',
    'BannerVisibilities.DELIVERY': 'Delivery',
    'BannerVisibilities.PICK_UP': 'PickUp',
    'BannerVisibilities.COMPANY': 'Company',

    'PaymentStatuses.UNPAID': 'Unpaid',
    'PaymentStatuses.PAID': 'Paid',
    'PaymentStatuses.PAYED': 'Paid',
    'PaymentStatuses.REFUNDED': 'Refunded',
    'PaymentStatuses.FAILED': 'Failed',

    'PaymentLinkStatuses.UNPAID': 'Unpaid',
    'PaymentLinkStatuses.PAID': 'Paid',
    'PaymentLinkStatuses.PAYED': 'Paid',
    'PaymentLinkStatuses.REFUNDED': 'Refunded',
    'PaymentLinkStatuses.FAILED': 'Failed',
    'PaymentLinkStatuses.CANCELLED': 'Cancelled',

    'BannerImageTypes.RESTAURANT': 'Restaurant',
    'BannerImageTypes.GENERAL': 'General',
    'BannerImageTypes.CUSTOM': 'Custom',

    'RolePermissions.POS_PAGE': 'ACCESS',
    'RolePermissions.ADVANCED_REPORTS_PAGE': 'ACCESS',
    'RolePermissions.REPORTS_PAGE': 'ACCESS',
    'RolePermissions.PENDING_ORDERS_PAGE': 'ACCESS',
    'RolePermissions.KITCHEN_DISPLAY_SCREEN_PAGE': 'ACCESS',
    'RolePermissions.ALL_ORDERS_PAGE': 'ACCESS',
    'RolePermissions.INVENTORY_PAGE': 'ACCESS',
    'RolePermissions.ORDER_INVOICES_PAGE': 'ACCESS',
    'RolePermissions.SURVEYS_PAGE': 'ACCESS',
    'RolePermissions.CONFIGURATION': 'ACCESS',
    'RolePermissions.PROMO_CODES_PAGE': 'ACCESS',
    'RolePermissions.MARKETING_PAGE': 'ACCESS',
    'RolePermissions.MENU_EDIT_PAGE': 'ACCESS',
    'RolePermissions.PAYMENT_LINKS_PAGE': 'ACCESS',
    'RolePermissions.OWN_FLEET_PAGE': 'ACCESS',
    'RolePermissions.CASH_REGISTER_PAGE': 'ACCESS',
    'RolePermissions.RESTAURANT_ZONES_PAGE': 'ACCESS',
    'RolePermissions.ADD_TIP_ON_CLOSED_ORDERS': 'Add tip on completed orders',
    'RolePermissions.APPLY_DISCOUNT_ORDERS': 'Apply discount orders',
    'RolePermissions.APPLY_PROMO_CODE_ORDERS': 'Apply Promo Codes',
    'RolePermissions.REMOVE_POS_CART_ITEM': 'Remove pos cart item',
    'RolePermissions.ADVANCED_REPORTS_MENU': 'Menu reports',
    'RolePermissions.ADVANCED_REPORTS_PROMO_CODES': 'Promo codes reports',
    'RolePermissions.ADVANCED_REPORTS_CUSTOMERS': 'Customer reports',
    'RolePermissions.ASK_DRIVER': 'Ask driver',
    'RolePermissions.REJECT_ORDERS': 'Reject',
    'RolePermissions.VIEW_CHECK_DELIVERY_PAID_BY_RESTAURANT': 'View restaurant paid delivery option',
    'RolePermissions.ALL_ORDERS': 'All orders',
    'RolePermissions.ALL_ORDERS_REPORTS': 'All orders reports',
    'RolePermissions.EDIT_ORDERS': 'Edit orders',
    'RolePermissions.CANCEL_ORDERS': 'Cancel orders',
    'RolePermissions.CUSTOMER_INFO': 'Customer info',
    'RolePermissions.COPY_INVENTORY': 'Copy inventory',
    'RolePermissions.INVENTORY_RECORDS': 'Inventory record',
    'RolePermissions.INVENTORY_QUANTITY': 'Inventory quantity',
    'RolePermissions.CREATE_INVENTORY_LOG': 'Create inventory log',
    'RolePermissions.UPDATE_INVENTORY': 'Update inventory',
    'RolePermissions.SUPPLIERS_PAGE': 'Suppliers',
    'RolePermissions.SUPPLIES_PAGE': 'Supplies',
    'RolePermissions.RECIPES_PAGE': 'Recipes',
    'RolePermissions.SUBSCRIPTION_PAGE': 'Subscriptions',
    'RolePermissions.RESTAURANT_PAGE': 'The restaurant',
    'RolePermissions.TICKET_COMMAND_PAGE': 'Prints',
    'RolePermissions.REPRINT_ORDER_COMMAND': 'Reprint command',
    'RolePermissions.INTEGRATIONS_PAGE': 'Integrations',
    'RolePermissions.PRINTERS_PAGE': 'Printers',
    'RolePermissions.DEVICES_PAGE': 'Devices',
    'RolePermissions.USER_MANAGEMENT_PAGE': 'User management',
    'RolePermissions.CREATE_PROMO_CODES': 'Create promo code',
    'RolePermissions.UPLOAD_MARKETING_DATA': 'Upload data',
    'RolePermissions.CREATE_PAYMENT_LINKS': 'Create payment links',
    'RolePermissions.OPEN_CASH_REGISTER': 'Open cash register',
    'RolePermissions.DEPOSIT_CASH_REGISTER': 'Deposit',
    'RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER': 'Only see the orders in your own cash register',
    'RolePermissions.WITHDRAW_CASH_REGISTER': 'Withdraw',
    'RolePermissions.CASH_REGISTER_SHIFT': 'Close cash register',
    'RolePermissions.CASH_REGISTER_CLOSE': 'Close business day',
    'RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT': 'Print partial cash register shift',
    'RolePermissions.BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY': 'Perform blind cash register shift for cash only',
    'RolePermissions.BLIND_CASH_REGISTER_SHIFT': 'Perform blind cash register shift',
    'RolePermissions.CHARGE_ORDERS': 'Charge orders',
    'RolePermissions.MANAGE_CASH_REGISTERS': 'Open personal cash register',
    'RolePermissions.OPEN_PERSONAL_CASH_REGISTER': 'Manage cash register',
    'RolePermissions.EDIT_RESTAURANT_ZONES': 'Edit restaurant zones',
    'RolePermissions.PIN_CODE_SIGN_IN': 'Pin code sign in',
    'RolePermissions.PASSWORD_SIGN_IN': 'Password sign in',
    'RolePermissions.MENU_EDIT': 'Edit menu',
    'RolePermissions.OPENING_HOURS_PAGE': 'Opening hours',
    'RolePermissions.SERVE_ORDERS': 'Register orders in the name of the user',
    'RolePermissions.ASSIGN_ORDERS': 'Assign orders',
    'RolePermissions.VIEW_ONLY_ASSIGNED_ORDERS': 'View only assigned orders',
    'RolePermissions.SEPARATE_ORDERS': 'Separate accounts',
    'RolePermissions.MOVE_ORDER_ITEMS_BETWEEN_ORDERS': 'Move item to another order',
    'RolePermissions.OPEN_CLOSED_ORDERS': 'Unlock closed orders',
    'RolePermissions.GENERAL_REPORT': 'General report',
    'RolePermissions.CUSTOMERS_REPORT': 'Customers report',
    'RolePermissions.PROMO_CODE_REPORT': 'Promo code report',
    'RolePermissions.ORDER_ISSUES_REPORT': 'Order issues report',
    'RolePermissions.INTEGRATIONS_REPORT': 'Integrations report',
    'RolePermissions.DELIVERIES_REPORT': 'Delivery report',
    'RolePermissions.WEB_ORDERS_CONVERSIONS_REPORT': 'Web menu',
    'RolePermissions.SALES_BY_DAY_REPORT': 'Sales by day report',
    'RolePermissions.SALES_BY_MENU_REPORT': 'Sales by menu report',
    'RolePermissions.ABC_REPORT': 'ABC report',
    'RolePermissions.FINANCE_REPORT': 'Finance report',
    'RolePermissions.SMART_BI_REPORT': 'Delivery report',
    'RolePermissions.HEAT_MAP_DELIVERY_ORDERS_REPORT': 'Heat map delivery orders',
    'RolePermissions.POS_BUSINESS_DAY_REPORT': 'Business day Report',
    'RolePermissions.CASH_REGISTER_REPORT': 'Cash registers report',
    'RolePermissions.TRANSACTIONS_REPORT': 'Transactions report',
    'RolePermissions.POS_USERS_REPORT': 'Users report',
    'RolePermissions.POS_USER_SALES_REPORT': 'Sales by user report',
    'RolePermissions.EMPLOYEE_REPORT': 'Employee report',
    'RolePermissions.SUPPLIES_HISTORY_REPORT': 'Supplies history report',
    'RolePermissions.KARDEX_REPORT': 'Kardex report',
    'RolePermissions.INVENTORY_CONSUMPTION_REPORT': 'Inventory consumption report',
    'RolePermissions.INVENTORY_ADJUSTMENTS_REPORT': 'Inventory adjustments report',
    'RolePermissions.INVENTORY_SUPPLIERS_REPORT': 'Inventory suppliers report',
    'RolePermissions.VIEW_INCOMING_ORDERS_ALERT': 'View incoming orders alert',
    'RolePermissions.BRAND': 'Enable master view for stores',
    'RolePermissions.LOKI': 'ACCESS',
    'RolePermissions.PROMOTIONS_PAGE': 'ACCESS',
    'InitialRoutePaths.HOME': 'Home',
    'InitialRoutePaths.RESTAURANT_DASHBOARD': 'Restaurant dashboard',
    'InitialRoutePaths.ONGOING_ORDERS': 'Ongoing orders',
    'InitialRoutePaths.ORDERS': 'All orders',
    'InitialRoutePaths.POS': 'POS',
    'InitialRoutePaths.POS_TABLES': 'Tables',
    'InitialRoutePaths.POS_CASH_REGISTERS': 'Cash register',
    'InitialRoutePaths.THE_RESTAURANT': 'The restaurant',
    'InitialRoutePaths.THE_MENU': 'Menu',
    'InitialRoutePaths.INVENTORY': 'Inventory',
    'InitialRoutePaths.ORDER_INVOICES': 'Order invoices',
    'InitialRoutePaths.PROMO_CODES': 'Promo codes',
    'InitialRoutePaths.SURVEYS': 'Surveys',
    'InitialRoutePaths.PAYMENT_LINKS': 'Payment links',
    'InitialRoutePaths.MARKETING': 'Marketing',
    'InitialRoutePaths.ADVANCED_REPORTS': 'Advanced reports',

    'RegimesFiscal.601': '601 - General de Ley Personas Morales',
    'RegimesFiscal.603': '603 - Personas Morales con Fines no Lucrativos',
    'RegimesFiscal.606': '606 - Arrendamiento',
    'RegimesFiscal.612': '612 - Personas Físicas con Actividades Empresariales y Profesionales',
    'RegimesFiscal.620': '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    'RegimesFiscal.621': '621 - Incorporación Fiscal',
    'RegimesFiscal.622': '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    'RegimesFiscal.623': '623 - Opcional para Grupos de Sociedades',
    'RegimesFiscal.624': '624 - Coordinados',
    'RegimesFiscal.625': '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    'RegimesFiscal.626': '626 - Régimen Simplificado de Confianza',
    'RegimesFiscal.628': '628 - Hidrocarburos',
    'RegimesFiscal.629': '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    'RegimesFiscal.630': '630 - Enajenación de acciones en bolsa de valores',
    'Regime Fiscal': 'Régimen Fiscal',

    'ConsecutiveOrderIdRanges.DAILY': 'Daily',
    'ConsecutiveOrderIdRanges.WEEKLY': 'Weekly',
    'ConsecutiveOrderIdRanges.MONTHLY': 'Monthly',
    'ConsecutiveOrderIdRanges.POS_BUSINESS_DAY': 'At the end of day',
    'ConsecutiveOrderIdRanges.NOT_RESET': 'Never restart',

    'REJECTED': 'Rejected',
    'CANCELLED': 'Cancelled',

    'OrderStatuses.NEW': 'New Order',
    'OrderStatuses.CANCELLED': 'Order Cancelled',
    'OrderStatuses.REJECTED': 'Order Rejected',
    'OrderStatuses.ACCEPTED': 'Order Accepted',
    'OrderStatuses.COMPLETE': 'Order Completed',
    'OrderStatuses.COMPLETED': 'Order Completed',
    'OrderStatuses.PENDING': 'Order Pending',
    'OrderStatuses.CLOSED': 'Order Pending',
    'OrderStatuses.ARRIVED_AT_CLIENT': 'Arrived at Client',
    'OrderStatuses.RETURNED': 'Order Returned',

    'KitchenStatuses.PREPARING': 'Order in Kitchen',
    'KitchenStatuses.PREPARED': 'Order Prepared',

    'DeliveryStatuses.REQUESTED': 'Looking for driver',
    'DeliveryStatuses.ACCEPTED': 'Driver on the way',
    'DeliveryStatuses.REJECTED': 'Rejected',
    'DeliveryStatuses.PICKED_UP': 'Driver on the way to customer',
    'DeliveryStatuses.DELIVERED': 'Delivered',
    'DeliveryStatuses.CANCELLED': 'Cancelled',
    'DeliveryStatuses.WITHDRAWN': 'Withdrawn',

    'DeliveryTimeCategories.LESS_THAN_30_MINUTES': 'Less than 30 minutes',
    'DeliveryTimeCategories.BETWEEN_30_AND_45_MINUTES': 'Between 30 and 45 minutes',
    'DeliveryTimeCategories.BETWEEN_45_AND_60_MINUTES': 'Between 45 and 60 minutes',
    'DeliveryTimeCategories.MORE_THAN_60_MINUTES': 'More than 60 minutes',
    'DeliveryTimeCategories.ALL': 'All',

    'ServiceTypes.TABLE_SERVICE': 'Table service',
    'ServiceTypes.SELF_SERVICE': 'Self service',

    'RewardTypes.DISCOUNT': 'Discount',
    'RewardTypes.CREDITS': 'Credits in cashback',
    'RewardTypes.PRODUCTS': 'Discount by product',

    'CardTypes.CREDIT': 'Credit',
    'CardTypes.DEBIT': 'Debit',
    'Zip code': 'Código Postal',

    'RestaurantSettingTabs.GENERAL': 'General',
    'RestaurantSettingTabs.ECOMMERCE': 'Ecommerce',
    'RestaurantSettingTabs.ORDERS': 'Orders',
    'RestaurantSettingTabs.DELIVERY': 'Delivery',
    'RestaurantSettingTabs.POS': 'Pos',
    'RestaurantSettingTabs.MARKETING': 'Marketing',
    'RestaurantSettingTabs.PAYMENTS': 'Payments',
    'RestaurantSettingTabs.APPS': 'Apps',

    'FiscalEntities.FISIC': 'Fisic Person',
    'FiscalEntities.BUSINESS': 'Business',
    'Select the Fiscal Entity': 'Select the fiscal entity',

    'OrderReturnedTypes.PARTIAL': 'Partial',
    'OrderReturnedTypes.TOTAL': 'Total',
    'Rappi Version': 'Rappi Version',

    'TicketSections.LOGO': 'Logo',
    'TicketSections.HEADER_ORDER_TYPE': 'Order type',
    'TicketSections.CUSTOM_HEADER_TEXT': 'Custom text',
    'TicketSections.BUSINESS_NAME': 'Business name',
    'TicketSections.RFC': 'RFC',
    'TicketSections.FISCAL_ADDRESS': 'Restaurant Address',
    'TicketSections.RESTAURANT_EMAIL': 'Email',
    'TicketSections.RESTAURANT_PHONE': 'Phone',
    'TicketSections.RESTAURANT_SECONDARY_PHONE': 'Secondary phone',
    'TicketSections.ORDER_ID': 'Order Id',
    'TicketSections.CONSECUTIVE_ID': 'Folio',
    'TicketSections.ORDER_TYPE': 'Order type',
    'TicketSections.TABLE_NUMBER': 'Table',
    'TicketSections.CREATED_AT': 'Date',
    'TicketSections.CUSTOMER_NAME': 'Name',
    'TicketSections.CUSTOMER_PHONE': 'Phone',
    'TicketSections.CUSTOMER_ADDRESS': 'Customer Address',
    'TicketSections.SALES_INFO': 'Show information',
    'TicketSections.CANCELLED_ITEMS': 'Cancelled products',
    'TicketSections.SPLIT_GROUPED_ORDER_ITEMS': 'Print items separately',
    'TicketSections.PAYMENT_METHOD': 'Payment method',
    'TicketSections.PAYMENT_CHANGE': 'Cambio',
    'TicketSections.DATE_OF_PRINT': 'Printed Date',
    'TicketSections.INVOICE_LINK': 'Invoice online link',
    'TicketSections.INVOICE_QR': 'Show QR',
    'TicketSections.CUSTOM_FOOTER_TEXT': 'Custom text',

    'CommandSections.ORDER_ID': 'Order',
    'CommandSections.CONSECUTIVE_ID': 'Folio',
    'CommandSections.ORDER_TYPE': 'Order Type',
    'CommandSections.TABLE_NUMBER': 'Table number',
    'CommandSections.CREATED_AT': 'Date and time',
    'CommandSections.CUSTOMER_NAME': 'Name',
    'CommandSections.SALES_INFO': 'Show information',
    'CommandSections.SPLIT_GROUPED_ORDER_ITEMS': 'Print items separately',
    'CommandSections.ITEMS_COST': 'Show products cost',

    'Clone': 'Clone',
    'Cloning': 'Cloning',

    'PrinterPaperSizes.80_MM': '80 mm',
    'PrinterPaperSizes.58_MM': '58 mm',
    'DriverManualEarningReasons.BACK_TO_RESTAURANT': 'Back to Restaurant',
    'DriverManualEarningReasons.KM_ADJUSTMENT': 'KM Adjustment',
    'DriverManualEarningReasons.REFUND': 'Refund',
    'DriverManualEarningReasons.RETURN_RESTAURANT': 'Return to Restaurant',
    'DriverManualEarningReasons.UNPAID_DELIVERY': 'Unpaid Delivery',
    'DriverManualEarningReasons.WAIT_FOR_RESTAURANT': 'Wait for Restaurant',
    'DriverManualEarningReasons.DAILY_BONUS': 'Daily Bonus',
    'DriverManualEarningReasons.QA_NOT_APPLIED': 'QA Not Applied',
    'DriverManualEarningReasons.NOT_APPLIED_DYNAMICS': 'Not applied dynamics',
    'DriverManualEarningReasons.ACCOUNT_UNBLOCKING': 'Account unblocking',
    'DriverManualEarningReasons.EXCESSIVE_WEIGHT': 'Excessive weight',
    'DriverManualEarningReasons.FIXED': 'Fixed',
    'DriverManualEarningReasons.NON_REFLECTED_BONUS': 'Non reflected bonus',
    'DriverManualEarningReasons.NON_COMPLIANCE_IN_BONUS': 'Non compliance in bonus',
    'DriverManualEarningReasons.DRIVER_REFERRAL': 'Driver Referral',
    'DriverManualEarningReasons.OTHER': 'Other',
    'Select the Reason': 'Select the Reason',

    'SupplyValueTypes.FIXED': 'Fixed',
    'SupplyValueTypes.AVERAGE': 'Average',
    'SupplyValueTypes.LAST': 'Last',
    'This is the name of the product': 'This is the name of the product',

    'CustomDiscountComments.EMPLOYEE_DISCOUNT': 'employee discount',
    'CustomDiscountComments.PARTNER_DISCOUNT': 'Partner discount',
    'CustomDiscountComments.COURTESY': 'Courtesy',
    'CustomDiscountComments.PROMOTION': 'Promotion',

    'PosBusinessDaySections.RESTAURANT_NAME': 'Name',
    'PosBusinessDaySections.BUSINESS_NAME': 'Business Name',
    'PosBusinessDaySections.RFC': 'RFC',
    'PosBusinessDaySections.RESTAURANT_STREET': 'Address',
    'PosBusinessDaySections.DATE_OF_PRINT': 'Printed Date',
    'PosBusinessDaySections.OPENING_DATE': 'Opening Date',
    'PosBusinessDaySections.CLOSING_DATE': 'Closing Date',
    'PosBusinessDaySections.SALES': 'Sales',
    'PosBusinessDaySections.TIP': 'Tips',
    'PosBusinessDaySections.ORDERS': 'Orders',
    'PosBusinessDaySections.DINNERS': 'Dinners',
    'PosBusinessDaySections.CASH_REGISTER_MOVEMENTS': 'Show information',
    'PosBusinessDaySections.SALES_BY_CHANNEL': 'Sales by channel',
    'PosBusinessDaySections.SALES_BY_PAYMENT': 'Sales by payment',
    'PosBusinessDaySections.SALES_BY_ORDER_TYPE': 'Sales by order type',
    'PosBusinessDaySections.TOP_SALES_PRODUCT': 'Sales by product',
    'PosBusinessDaySections.TOP_SALES_CATEGORY': 'Top sales by category',
    'PosBusinessDaySections.TOP_SALES_BY_HOUR': 'Top sales by hour',
    'PosBusinessDaySections.PAYMENTS_RECEIVED': 'Payments received',
    'PosBusinessDaySections.CASH_REGISTER_COUNTING': 'Show information',
    'PosBusinessDaySections.PROMO_CODES_SUMMARY': 'Promotions',
    'PosBusinessDaySections.SALES_BY_MANAGER_USER': 'Sales by manager user',
    'PosBusinessDaySections.CANCELLED_ORDERS': 'Cancellations',
    'PosBusinessDaySections.CARD_BREAKDOWN': 'Card breakdown',
    'PosBusinessDaySections.INTEGRATION_BREAKDOWN': 'Integrations breakdown',
    'PosBusinessDaySections.PAYMENTS_BREAKDOWN': 'Payments breakdown',

    'ColumnTypes.COST_BY_AVERAGE_WEIGHTED_COST': 'Cost by average weighted cost',
    'ColumnTypes.COST_BY_LAST_PURCHASE_COST': 'Cost by last purchase cost',
    'ColumnTypes.VALUE': 'Value',

    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'UnitsOfMeasurement.BOX': 'box',
    'UnitsOfMeasurement.BAG': 'bag',
    'UnitsOfMeasurement.PIECE': 'piece',
    'UnitsOfMeasurement.PACKAGE': 'pack',
    'UnitsOfMeasurement.STICK': 'stick',
    'UnitsOfMeasurement.ROLL': 'roll',
    'UnitsOfMeasurement.CAN': 'can',
    'UnitsOfMeasurement.SLICE': 'slice',

    'LogEventTypes.RESTAURANT_EMPLOYEE_CLOCKED_IN': 'Clock in',
    'LogEventTypes.RESTAURANT_EMPLOYEE_CLOCKED_OUT': 'Clock out',
    'Omit supply in orders': 'Omit supply in orders',
    'Omitted in order types': 'Omitted in order types',

    'Verb.Order': 'Order',

    'PrintTypes.COMMAND': 'Comand',
    'PrintTypes.TICKET': 'Ticket',
    'PrintTypes.POS_BUSINESS_DAY_REPORT': 'Pos business day report',
    'PrintTypes.SHIFT_CASH_REGISTER_REPORT': 'Shift cash register report',
    'PrintTypes.PRINTER_INFO': 'Printer infos',

    'ProductLayouts.LARGE': 'Big',
    'ProductLayouts.MEDIUM': 'Medium',
    'ProductLayouts.SMALL': 'Little',

    'InternalReports.GENERAL_OPERATIONS_REPORT': 'General operations report',
    'InternalReports.GENERAL_SALES_REPORT': 'General sales report',
    'InternalReports.LAS_ALITAS_SALES_REPORT': 'Las Alitas sales report',
    'InternalReports.BONUS_BILLING_REPORT': 'Bonus billing report',
    'InternalReports.OPS_ORDERS_AND_TIMES_REPORT': 'Orders and delivery times report',
    'InternalReports.OPS_RAPPI_1000_Orders_Report': 'Rappi orders over $1000 report',
    'InternalReports.OPS_DELIVERY_PROVIDERS_REPORT': 'Delivery providers report',
    'InternalReports.ORDERS_BREAKDOWN_BY_PAYMENTS_PROVIDER': 'Orders breakdown by payments provider',
    'InternalReports.KFC_DELIVERY_ORDERS_REPORT': 'KFC delivery orders report',
    'InternalReports.INVOICED_DELIVERIES_MONTHLY_REPORT': 'Monthly invoiced deliveries report',
    'InternalReports.CHARGEBACKS_REPORT': 'Chargebacks report',

    'SubscriptionStatuses.NEW': 'New',
    'SubscriptionStatuses.PAID': 'Paid',
    'SubscriptionStatuses.PENDING': 'Pending',
    'SubscriptionStatuses.CANCELLED': 'Cancelled',
    'SubscriptionStatuses.TEST': 'Test',
    'SubscriptionStatuses.INACTIVE': 'Inactive',

    'SubscriptionTabName.NEW': 'New',
    'SubscriptionTabName.PAID': 'Paid',
    'SubscriptionTabName.PENDING': 'Pending',
    'SubscriptionTabName.CANCELLED': 'Cancelled',
    'SubscriptionTabName.TEST': 'Test',
    'SubscriptionTabName.INACTIVE': 'Inactive',
    'SubscriptionTabName.PAY_PER_USE': 'Pay Per Use',

    'QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NOT FOUND',
    'QpayTerminalPaymentInstructionResults.UNKNOWN': 'UNKNOWN',
    'QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCTION RECEIVED',
    'QpayTerminalPaymentInstructionResults.TERMINAL_BUSY': 'TERMINAL BUSY',
    'PaymentTerminalInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NOT FOUND',
    'PaymentTerminalInstructionResults.UNKNOWN': 'UNKNOWN',
    'PaymentTerminalInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCTION RECEIVED',
    'PaymentTerminalInstructionResults.TERMINAL_BUSY': 'TERMINAL BUSY',
    'PaymentTerminalInstructionResults.INSTRUCTION_COMPLETED': 'INSTRUCTION COMPLETED',

    'ShortenedDays.Mo': 'M',
    'ShortenedDays.Tu': 'T',
    'ShortenedDays.We': 'W',
    'ShortenedDays.Th': 'T',
    'ShortenedDays.Fr': 'F',
    'ShortenedDays.Sa': 'S',
    'ShortenedDays.Su': 'S',
    'SmsServiceProviders.AWS': 'Aws',
    'SmsServiceProviders.VOICES': 'Voices',
    'SmsServiceProviders.INFOBIP': 'InfoBip',

    'VehicleTypes.CAR': 'Car',
    'VehicleTypes.MOTORCYCLE': 'Motorcycle',
    'VehicleTypes.BICYCLE': 'Bicycle',
    'VehicleTypes.PEDESTRIAN': 'Pedestrian',

    'WeekSchedules.ALWAYS': 'Always',
    'WeekSchedules.WEEKDAY': 'Weekday',
    'WeekSchedules.WEEKEND': 'Weekend',
    'WeekSchedules.CUSTOM': 'Custom',

    'ArgentinianTaxes.IVA': 'IVA',
    'ArgentinianTaxes.IIBB': 'IIBB',

    'MexicanTaxes.IVA': 'IVA',
    'MexicanTaxes.IVA_RET': 'IVA Ret',
    'MexicanTaxes.IVA_EXENTO': 'IVA Exento',
    'MexicanTaxes.ISR': 'ISR',
    'MexicanTaxes.IEPS': 'IEPS',

    'UruquayanTaxes.IVA': 'IVA',
    'UruquayanTaxes.IRPF': 'IRPF',
    'UruquayanTaxes.IRNR': 'IRNR',

    'RestaurantPsps.PLEXO': 'Plexo',
    'RestaurantPsps.MERCADO_PAGO': 'Mercado Pago',
    'RestaurantPsps.AUTO': 'Automatic',

    'Sales by ticket': 'Ventas por ticket',

    'CreatePrinterSteps.PRINTER_TYPE': 'Printer type',
    'CreatePrinterSteps.PRINTER_NAME': 'Name',
    'CreatePrinterSteps.IP_ADDRESS': 'IP Address',
    'CreatePrinterSteps.PORT': 'Port',
    'CreatePrinterSteps.DOWNLOAD_DRIVERS': 'Download Drivers',
    'CreatePrinterSteps.PRINTER_BRAND': 'Printer Brand',
    'CreatePrinterSteps.CHANNELS': 'Channels',
    'CreatePrinterSteps.SERIAL_NUMBER': 'Serial Number',
    'CreatePrinterSteps.PRINTER_PAPER_SIZE': 'Printer Paper Size',
    'CreatePrinterSteps.PRINTER_CONTENT': 'Printer Content',
    'CreatePrinterSteps.CONFIRMATION': 'Confirm',

    'PromotionTypes.BUY_ONE_GET_ONE': 'Buy one get one (BOGO)',

    'DeliveryManagerTypes.DELIVERY_MANAGER_LINEAL': 'Delivery manager lineal',
    'DeliveryManagerTypes.DELIVERY_MANAGER_CYCLICAL': 'Delivery manager cyclical',
    'DeliveryManagerTypes.DELIVERY_MANAGER_ORCHESTRATOR': 'Delivery manager orchestrator',
    'DeviceTypes.MOBILE': 'Mobile',
    'DeviceTypes.MACINTOSH': 'Macintosh',
    'DeviceTypes.TABLET': 'Tablet',
    'DeviceTypes.PC': 'PC',
    'DeviceTypes.UNKNOWN': 'Unknown',

    'CustomerOriginTypes.ORGANIC': 'Organic',
    'CustomerOriginTypes.UNKNOWN': 'Unknown',
    'CustomerOriginTypes.FACEBOOK': 'Facebook',
    'CustomerOriginTypes.INSTAGRAM': 'Instagram',
    'CustomerOriginTypes.TWITTER': 'Twitter',

    'SurveyAnswers.FOOD_WAS_COLD': 'Food was cold',
    'SurveyAnswers.MISSING_OR_INCORRECT_ITEMS': 'Missing or incorrect items',
    'SurveyAnswers.FOOD_WAS_NOT_PREPARED': 'Food was not prepared',
    'SurveyAnswers.FOOD_ARRIVED_LATE': 'Food arrived late',
    'SurveyAnswers.CONFUSED_PICK_UP_PLACE': 'Confused pick-up place',
    'SurveyAnswers.BAD_SERVICE': 'Bad customer service',
    'SurveyAnswers.PUNCTUALITY': 'Punctuality',
    'SurveyAnswers.APPLICATION': 'Application',
    'SurveyAnswers.FOOD': 'Food',
    'SurveyAnswers.PRESENTATION': 'Presentation',
    'SurveyAnswers.SERVICE': 'Good customer service',

    'OrderIncidentTypes.ORDER_NOT_RECEIVED': 'Order not received',
    'OrderIncidentTypes.ORDER_NOT_RETURNED_TO_RESTAURANT': 'Order not returned to restaurant',
    'OrderIncidentTypes.INCORRECT_ORDER': 'Incorrect order',
    'OrderIncidentTypes.INCOMPLETE_ORDER': 'Incomplete order',
    'OrderIncidentTypes.ORDER_CANCELLED_AND_DELIVERED': 'Order cancelled and delivered',
    'OrderIncidentTypes.ORDER_CHANGE_STOLEN': 'Order change stolen',
    'OrderIncidentTypes.DAMAGED_ORDER_ITEM_RETURNED_TO_RESTAURANT': 'Damaged order item returned to restaurant',
    'OrderIncidentTypes.DAMAGED_ORDER_ITEMS': 'Damaged order items',
    'OrderIncidentTypes.COURTESY': 'Courtesy',
    'OrderIncidentTypes.RESTAURANT_WASTE': 'Restaurant waste',
    'OrderIncidentTypes.ORDER_DELIVERY_DELAYED': 'Delivery delayed',
    'OrderIncidentTypes.ORDER_REJECTED_BY_CUSTOMER': 'Order rejected by customer',
    'OrderIncidentTypes.BUG': 'Bug',
    'OrderIncidentTypes.ADMIN_APPROVAL': 'Admin approval',
    'OrderIncidentTypes.MONEY_RECOVERED_FROMM_DRIVER': 'Money recovered - Driver',

    'RefundMethods.CREDITS': 'Credits',
    'RefundMethods.CREDIT_CARD': 'Credit card',
    'RefundMethods.PAYROLL': 'Payroll',
};
