import { BigNumber } from 'bignumber.js';
import { useEffect } from 'react';
import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { TipTypes } from 'src/constants/TipType';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { useOrderTipActions, useOrderTipStore } from 'src/modules/pos/tip/orderTipStore';
import { useAddPosTip } from 'src/services/pos/useAddPosTip';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { DeviceGroupId, type RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useTipOrderUpdatesListener() {
    const addTipDialogState = useOrderTipStore((state) => state.addTipDialogState);
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();
    const addTip = useAddPosTip();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const customerDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenEnabled);
    const customerDisplayScreenTouchEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenTouchEnabled);
    const payments = useSelector((state) => state.pos.payments);
    const total = useSelector((state) => state.pos.payment?.total);
    const tips = useSelector((state) => state.pos.tips) ?? [];
    const customers = useSelector((state) => state.pos.customers);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);

    const openAddTipDialog = useOrderTipActions((actions) => actions.openAddTipDialog);

    const tipsAmount = tips
        .map((tip) => tip.tipAmount)
        .reduce(sum, BigNumber(0))
        .toString();
    const orderTotal = BigNumber(total).minus(tipsAmount).toString();

    useEffect(() => {
        const tipOrderChangedWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CUSTOMER_DISPLAY_SCREEN_TIP_ORDER_UPDATED, handleTipOrderUpdatesWebSocketEvent);

        return () => {
            tipOrderChangedWebSocketEvent.remove();
        };
    }, [restaurantId, customerDisplayScreenEnabled, customerDisplayScreenTouchEnabled, customers, payments]);

    const handleTipOrderUpdatesWebSocketEvent = async (event: WebSocketEvent<Params>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        if (!customerDisplayScreenEnabled) return;
        if (!customerDisplayScreenTouchEnabled) return;
        if (customers?.length > 0) return;
        if (deviceGroup?.deviceGroupId !== event.data?.deviceGroupId) return;

        const tipPercentage = event.data.tipPercentage;

        if (addTipDialogState.open) {
            return;
        }

        if (BigNumber(tipPercentage ?? 0).isZero()) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.ORDER_SUMMARY);
            return;
        }

        if (payments?.length && payments?.length > 1) {
            openAddTipDialog(tipPercentage);
            return;
        }

        addTip({
            tipType: TipTypes.PERCENT,
            tipPercentage: BigNumber(tipPercentage).multipliedBy(100).toString(),
            tipAmount: BigNumber(tipPercentage).dividedBy(100).multipliedBy(orderTotal).toString(),
            paymentMethod: payments?.[0].paymentMethod,
            customPaymentMethod: payments?.[0].customPaymentMethod,
        });
    };
}

type Params = {
    restaurantId: RestaurantId;
    deviceGroupId: DeviceGroupId;
    tipPercentage: number;
};
