import { useEffect, useState } from 'react';
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CashRegisterShiftReport } from 'src/scenes/letseatmanager/posCashRegister/CashRegisterShiftReport';
import { ClosePosBusinessDayCashRegisterReport } from 'src/scenes/letseatmanager/posCashRegister/ClosePosBusinessDayCashRegisterReport';
import { EmployeesClockedInReport } from 'src/scenes/letseatmanager/posCashRegister/EmployeesClockedInReport';
import { PosBusinessDayCashRegisters } from 'src/scenes/letseatmanager/posCashRegister/PosBusinessDayCashRegisters';
import { PosCashRegister } from 'src/scenes/letseatmanager/posCashRegister/PosCashRegister';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosCashRegistersPage(): React.ReactElement {
    const clearPos = useClearPos();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [showCurrentEmployeesClockedIn, setShowCurrentEmployeesClockedIn] = useState(false);

    const cashRegister = useSelector((state) => state.pos.cashRegister);
    const clockInEmployeeDayShiftEnabled = useSelector((state) => state.app.restaurant?.clockInEmployeeDayShiftEnabled);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);
    const isEndOfDayReport = useSelector((state) => state.pos.isEndOfDayReport);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS) && posMultipleCashRegistersEnabled;

    const shouldRenderEmployeeClockedInSummary =
        cashRegister && clockInEmployeeDayShiftEnabled && !!currentEmployeesClockedIn?.length && isEndOfDayReport && (!posMultipleCashRegistersEnabled || hasManageCashRegisterPermission);

    useEffect(() => {
        return () => clearPos();
    }, []);

    useEffect(() => {
        if (shouldRenderEmployeeClockedInSummary) {
            setShowCurrentEmployeesClockedIn(true);
        }
    }, [cashRegister, clockInEmployeeDayShiftEnabled]);

    if (cashRegister && !showCurrentEmployeesClockedIn && !isEndOfDayReport) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.CASH_REGISTER_PAGE} title={translate('AmbitOne POS')}>
                <CashRegisterShiftReport />
            </SecuredAndSubscribedPage>
        );
    }

    if (cashRegister && !showCurrentEmployeesClockedIn && isEndOfDayReport) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.CASH_REGISTER_PAGE} title={translate('AmbitOne POS')}>
                <ClosePosBusinessDayCashRegisterReport />
            </SecuredAndSubscribedPage>
        );
    }

    if (cashRegister && showCurrentEmployeesClockedIn) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.CASH_REGISTER_PAGE} title={translate('AmbitOne POS')}>
                <EmployeesClockedInReport onContinue={() => setShowCurrentEmployeesClockedIn(false)} />
            </SecuredAndSubscribedPage>
        );
    }

    if (!posMultipleCashRegistersEnabled) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.CASH_REGISTER_PAGE} title={translate('AmbitOne POS')}>
                <PosCashRegister />
            </SecuredAndSubscribedPage>
        );
    }

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.CASH_REGISTER_PAGE} title={translate('AmbitOne POS')}>
            <PosBusinessDayCashRegisters />
        </SecuredAndSubscribedPage>
    );
}
