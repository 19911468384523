import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosCartPrimaryButton } from 'src/components/pos/posCart/PosCartPrimaryButton';
import { PosCartSecondaryButton } from 'src/components/pos/posCart/PosCartSecondaryButton';
import { PosChangeOrderTypeButton } from 'src/components/pos/posCart/PosChangeOrderTypeButton';
import { PosChargeOrderButton } from 'src/components/pos/posCart/PosChargeOrderButton';
import { PosPrintBillButton } from 'src/components/pos/posCart/PosPrintBillButton';
import { PosPrintCommandButton } from 'src/components/pos/posCart/PosPrintCommandButton';
import { PosTakeOrderByCourseButton } from 'src/components/pos/posCart/PosTakeOrderByCourseButton';
import { PosTakeOrderByCustomerButton } from 'src/components/pos/posCart/PosTakeOrderByCustomerButton';
import { SeparateOrderButton } from 'src/components/pos/posCart/SeparateOrderButton';
import { SwipeDrawer } from 'src/components/SwipeDrawer';
import { RolePermissions } from 'src/constants/RolePermission';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { MoveOrderItemsBetweenOrdersButton } from 'src/modules/pos/order/components/MoveOrderItemsBetweenOrdersButton';
import { PlannedPosOrderButton } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PlannedPosOrderButton';
import { UndoPlannedPosOrderButton } from 'src/scenes/letseatmanager/pos/posMenu/posCart/UndoPlannedPosOrderButton';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isPlannedPickupTime } from 'src/utils/pickupTimeType/isPlannedPickupTime';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosCartActions(): React.ReactElement {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const [userHasRolePermission] = useUserHasRolePermission();

    const orderType = useSelector((state) => state.pos.orderType);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant.plannedOrdersEnabled);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const items = useSelector((state) => state.pos.items);

    const showPlannedOrdersButton = isTakeAwayOrder(orderType) && plannedOrdersEnabled && !hasPendingOrder && !isPlannedPickupTime(pickupTimeType);
    const showUndoPlannedOrdersButton = isPlannedPickupTime(pickupTimeType);
    const showPrintCommandButton = isEatHereOrder(orderType) && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled;
    const hideSecondaryButton = ((isEatHereOrder(orderType) && hasPendingOrder && posPendingOrdersEnabled) || isTakeAwayOrder(orderType)) && !userHasRolePermission(RolePermissions.CHARGE_ORDERS);
    const shouldRenderMoveOrderItemsButton = isEatHereOrder(orderType) && pendingOrder && items.length > 1;
    const hasSecondaryButtons =
        hasPendingOrder ||
        isEatHereOrder(orderType) ||
        (showPrintCommandButton && !isTakeAwayOrder(orderType)) ||
        showPlannedOrdersButton ||
        showUndoPlannedOrdersButton ||
        shouldRenderMoveOrderItemsButton;

    return (
        <SwipeDrawer
            classes={{ puller: classes.puller }}
            visibleContent={
                <div
                    className={classNames(
                        classes.buttonsContainer,
                        { [classes.buttonContent]: !posPendingOrdersEnabled || acceptManuallyAllOrdersEnabled },
                        { [classes.deliveryButton]: isDeliveryOrder(orderType) },
                        posInterfaceLargerEnabled || hideSecondaryButton ? classes.buttonsContainerLarger : '',
                    )}
                >
                    <PosCartSecondaryButton />
                    <PosCartPrimaryButton />
                </div>
            }
            hideBackdrop
        >
            {hasSecondaryButtons && (
                <div className={classNames(classes.buttonsContainer, posInterfaceLargerEnabled ? classes.buttonsContainerLarger : '')}>
                    <PosChargeOrderButton />
                    <PosTakeOrderByCustomerButton />
                    <PosChangeOrderTypeButton />
                    <PosPrintBillButton />
                    <MoveOrderItemsBetweenOrdersButton />
                    <PosPrintCommandButton />
                    <SeparateOrderButton />
                    <PosTakeOrderByCourseButton />
                    <PlannedPosOrderButton />
                    <UndoPlannedPosOrderButton />
                </div>
            )}
        </SwipeDrawer>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        width: '100%',
    },
    buttonContent: {
        display: 'flex',
    },
    deliveryButton: {
        gridTemplateColumns: '1fr',
    },
    buttonsContainerLarger: {
        gridTemplateColumns: '1fr',
    },
    puller: {
        height: 3,
        backgroundColor: theme.palette.surface.brand,
    },
    divider: {
        height: '1px',
        width: '95%',
        margin: '18px auto',
        borderTop: '1px solid #D9D9D9',
    },
}));
