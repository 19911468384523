export const RolePermissions = {
    POS_PAGE: 'POS_PAGE',
    REPORTS_PAGE: 'REPORTS_PAGE',
    OWN_FLEET_PAGE: 'OWN_FLEET_PAGE',
    KITCHEN_DISPLAY_SCREEN_PAGE: 'KITCHEN_DISPLAY_SCREEN_PAGE',
    INVENTORY_PAGE: 'INVENTORY_PAGE',
    BUY_ORDERS_PAGE: 'BUY_ORDERS_PAGE',
    CREATE_BUY_ORDERS: 'CREATE_BUY_ORDERS',
    EDIT_BUY_ORDERS: 'EDIT_BUY_ORDERS',
    CREATE_MERCHANDISE_ENTRY: 'CREATE_MERCHANDISE_ENTRY',
    SEND_BUY_ORDERS: 'SEND_BUY_ORDERS',
    PRODUCE_COMPOUND_SUPPLY: 'PRODUCE_COMPOUND_SUPPLY',
    INVENTORY_RECORDS: 'INVENTORY_RECORDS',
    INVENTORY_PRODUCTION: 'INVENTORY_PRODUCTION',
    INVENTORY_QUANTITY: 'INVENTORY_QUANTITY',
    CREATE_INVENTORY_LOG: 'CREATE_INVENTORY_LOG',
    UPDATE_INVENTORY: 'UPDATE_INVENTORY',
    COPY_INVENTORY: 'COPY_INVENTORY',
    SUPPLIERS_PAGE: 'SUPPLIERS_PAGE',
    SUPPLIES_PAGE: 'SUPPLIES_PAGE',
    RECIPES_PAGE: 'RECIPES_PAGE',
    REMOVE_POS_CART_ITEM: 'REMOVE_POS_CART_ITEM',
    OPEN_CLOSED_ORDERS: 'OPEN_CLOSED_ORDERS',
    ADVANCED_REPORTS_PAGE: 'ADVANCED_REPORTS_PAGE',
    ADVANCED_REPORTS_MENU: 'ADVANCED_REPORTS_MENU',
    ADVANCED_REPORTS_CUSTOMERS: 'ADVANCED_REPORTS_CUSTOMERS',
    ADVANCED_REPORTS_PROMO_CODES: 'ADVANCED_REPORTS_PROMO_CODES',
    PENDING_ORDERS_PAGE: 'PENDING_ORDERS_PAGE',
    ASK_DRIVER: 'ASK_DRIVER',
    REJECT_ORDERS: 'REJECT_ORDERS',
    VIEW_CHECK_DELIVERY_PAID_BY_RESTAURANT: 'VIEW_CHECK_DELIVERY_PAID_BY_RESTAURANT',
    ALL_ORDERS_PAGE: 'ALL_ORDERS_PAGE',
    ALL_ORDERS: 'ALL_ORDERS',
    ALL_ORDERS_REPORTS: 'ALL_ORDERS_REPORTS',
    EDIT_ORDERS: 'EDIT_ORDERS',
    CANCEL_ORDERS: 'CANCEL_ORDERS',
    CUSTOMER_INFO: 'CUSTOMER_INFO',
    ORDER_INVOICES_PAGE: 'ORDER_INVOICES_PAGE',
    MOVE_ORDER_ITEMS_BETWEEN_ORDERS: 'MOVE_ORDER_ITEMS_BETWEEN_ORDERS',
    SURVEYS_PAGE: 'SURVEYS_PAGE',
    PROMO_CODES_PAGE: 'PROMO_CODES_PAGE',
    CREATE_PROMO_CODES: 'CREATE_PROMO_CODES',
    PAYMENT_LINKS_PAGE: 'PAYMENT_LINKS_PAGE',
    CREATE_PAYMENT_LINKS: 'CREATE_PAYMENT_LINKS',
    MARKETING_PAGE: 'MARKETING_PAGE',
    UPLOAD_MARKETING_DATA: 'UPLOAD_MARKETING_DATA',
    MENU_EDIT_PAGE: 'MENU_EDIT_PAGE',
    MENU_EDIT: 'MENU_EDIT',
    CONFIGURATION: 'CONFIGURATION',
    SUBSCRIPTION_PAGE: 'SUBSCRIPTION_PAGE',
    RESTAURANT_PAGE: 'RESTAURANT_PAGE',
    TICKET_COMMAND_PAGE: 'TICKET_COMMAND_PAGE',
    REPRINT_ORDER_COMMAND: 'REPRINT_ORDER_COMMAND',
    OPENING_HOURS_PAGE: 'OPENING_HOURS_PAGE',
    INTEGRATIONS_PAGE: 'INTEGRATIONS_PAGE',
    DEVICES_PAGE: 'DEVICES_PAGE',
    PRINTERS_PAGE: 'PRINTERS_PAGE',
    DELIVERY_ZONES_PAGE: 'DELIVERY_ZONES_PAGE',
    USER_MANAGEMENT_PAGE: 'USER_MANAGEMENT_PAGE',
    CASH_REGISTER_PAGE: 'CASH_REGISTER_PAGE',
    OPEN_CASH_REGISTER: 'OPEN_CASH_REGISTER',
    DEPOSIT_CASH_REGISTER: 'DEPOSIT_CASH_REGISTER',
    WITHDRAW_CASH_REGISTER: 'WITHDRAW_CASH_REGISTER',
    CASH_REGISTER_SHIFT: 'CASH_REGISTER_SHIFT',
    CASH_REGISTER_CLOSE: 'CASH_REGISTER_CLOSE',
    OPEN_PERSONAL_CASH_REGISTER: 'OPEN_PERSONAL_CASH_REGISTER',
    VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER: 'VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER',
    MANAGE_CASH_REGISTERS: 'MANAGE_CASH_REGISTERS',
    BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY: 'BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY',
    BLIND_CASH_REGISTER_SHIFT: 'BLIND_CASH_REGISTER_SHIFT',
    PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT: 'PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT',
    CHARGE_ORDERS: 'CHARGE_ORDERS',
    RESTAURANT_ZONES_PAGE: 'RESTAURANT_ZONES_PAGE',
    EDIT_RESTAURANT_ZONES: 'EDIT_RESTAURANT_ZONES',
    APPLY_DISCOUNT_ORDERS: 'APPLY_DISCOUNT_ORDERS',
    ADD_TIP_ON_CLOSED_ORDERS: 'ADD_TIP_ON_CLOSED_ORDERS',
    APPLY_PROMO_CODE_ORDERS: 'APPLY_PROMO_CODE_ORDERS',
    PIN_CODE_SIGN_IN: 'PIN_CODE_SIGN_IN',
    PASSWORD_SIGN_IN: 'PASSWORD_SIGN_IN',
    SERVE_ORDERS: 'SERVE_ORDERS',
    ASSIGN_ORDERS: 'ASSIGN_ORDERS',
    VIEW_ONLY_ASSIGNED_ORDERS: 'VIEW_ONLY_ASSIGNED_ORDERS',
    VIEW_INCOMING_ORDERS_ALERT: 'VIEW_INCOMING_ORDERS_ALERT',
    GENERAL_REPORT: 'GENERAL_REPORT',
    CUSTOMERS_REPORT: 'CUSTOMERS_REPORT',
    PROMO_CODE_REPORT: 'PROMO_CODE_REPORT',
    ORDER_ISSUES_REPORT: 'ORDER_ISSUES_REPORT',
    INTEGRATIONS_REPORT: 'INTEGRATIONS_REPORT',
    DELIVERIES_REPORT: 'DELIVERIES_REPORT',
    WEB_ORDERS_CONVERSIONS_REPORT: 'WEB_ORDERS_CONVERSIONS_REPORT',
    SALES_BY_DAY_REPORT: 'SALES_BY_DAY_REPORT',
    SALES_BY_MENU_REPORT: 'SALES_BY_MENU_REPORT',
    ABC_REPORT: 'ABC_REPORT',
    FINANCE_REPORT: 'FINANCE_REPORT',
    POS_BUSINESS_DAY_REPORT: 'POS_BUSINESS_DAY_REPORT',
    SMART_BI_REPORT: 'SMART_BI_REPORT',
    HEAT_MAP_DELIVERY_ORDERS_REPORT: 'HEAT_MAP_DELIVERY_ORDERS_REPORT',
    CASH_REGISTER_REPORT: 'CASH_REGISTER_REPORT',
    TRANSACTIONS_REPORT: 'TRANSACTIONS_REPORT',
    POS_USERS_REPORT: 'POS_USERS_REPORT',
    POS_USER_SALES_REPORT: 'POS_USER_SALES_REPORT',
    EMPLOYEE_REPORT: 'EMPLOYEE_REPORT',
    SUPPLIES_HISTORY_REPORT: 'SUPPLIES_HISTORY_REPORT',
    KARDEX_REPORT: 'KARDEX_REPORT',
    INVENTORY_CONSUMPTION_REPORT: 'INVENTORY_CONSUMPTION_REPORT',
    INVENTORY_ADJUSTMENTS_REPORT: 'INVENTORY_ADJUSTMENTS_REPORT',
    INVENTORY_SUPPLIERS_REPORT: 'INVENTORY_SUPPLIERS_REPORT',
    BRAND: 'BRAND',
    LOKI: 'LOKI',
    TAXES_PAGE: 'TAXES_PAGE',
    PROMOTIONS_PAGE: 'PROMOTIONS_PAGE',
    SEPARATE_ORDERS: 'SEPARATE_ORDERS',
} as const;

export type RolePermission = (typeof RolePermissions)[keyof typeof RolePermissions];
