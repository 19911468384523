import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DeliveryEstimateVm } from 'src/types/DeliveryEstimateVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { RestaurantId } from 'src/types/Id';

export async function getDeliveryEstimateApi(request: GetDeliveryEstimateApiRequest): ApiSauceResponse<DeliveryEstimateVm> {
    return letseatmanagerApiMethod('order/getDeliveryEstimateApi', request);
}

export type GetDeliveryEstimateApiRequest = {
    restaurantId: RestaurantId;
    deliveryLocation: GeoJsonPoint;
    appVersion?: string;
};
