import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import { Apps } from 'src/constants/App';
import { Countries } from 'src/constants/Country';
import { OrderTypes } from 'src/constants/OrderType';
import { PrinterPaperSize, PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { TicketSection, TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';
import { mapCfeTypeToInvoiceType } from 'src/services/printer/prints/utils/mapCfeTypeToInvoiceType';
import { BuilderPrintColumn, printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import { type PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isEmpty } from 'src/utils/object/isEmpty';
import { calculateIva } from 'src/utils/order/calculateIva';
import { formatShortExternalOrderId } from 'src/utils/order/formatShortExternalOrderId';
import { getTotalItem } from 'src/utils/order/getTotalItems';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { splitGroupedItems } from 'src/utils/order/splitGroupedItems';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { mapFreeModifiersByModifierGroups } from 'src/utils/pos/mapFreeModifiersByModifierGroups';
import { formatAsNationalPhoneNumber } from 'src/utils/string/formatAsNationalPhoneNumber';
import { translateOrderPaymentMethod } from 'src/utils/translate/translateOrderPaymentMethod';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function createOrderTicketPrint({ order, restaurant, printerPaperSize, ticketSections, openCashBox }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    addHeaderTicketInfo();
    addPhoneRestaurantInfo();
    addOrderInfo();
    if (ticketSections?.includes(TicketSections.SALES_INFO)) addOrderItemsInfo();
    addTotalOrderInfo();
    addPaymentMethodsInfo();
    addPrintDate();
    addInvoiceInfo();
    addOrderInvoiceData();
    addTicketFooter();

    const openCashbox = !isCancelledOrder(order.orderStatus) && !isRejectedOrder(order.orderStatus) && isCashPayment(order.paymentMethod) && openCashBox;
    if (openCashbox) builder.openCashbox();

    return builder.build();

    function addHeaderTicketInfo(): void {
        if (!ticketSections?.includes(TicketSections.CUSTOM_HEADER_TEXT)) {
            builder.addCenteredBoldText(isClosedOrder(order.orderStatus) ? translate('Partial account') : translate('Buy ticket'));
        }

        if (ticketSections?.includes(TicketSections.CUSTOM_HEADER_TEXT) && restaurant.ticketCustomHeader) {
            builder.addCenteredBoldText(restaurant.ticketCustomHeader);
        }

        if (restaurant.ticketLogoImage && ticketSections?.includes(TicketSections.LOGO)) {
            builder.addLogoImage(restaurant.ticketLogoImage);
        }

        if (ticketSections?.includes(TicketSections.HEADER_ORDER_TYPE)) {
            builder.addCenteredText(translate('Sell for @orderType', { orderType: translate(`OrderTypes.${order.orderType}`) }));
        }

        builder.addLineSeparator();
    }

    function addPhoneRestaurantInfo(): void {
        const { restaurant: orderRestaurant } = order;

        const businessName = (orderRestaurant as any)?.bussinessName ?? restaurant?.businessName;
        const rfc = orderRestaurant?.rfc ?? restaurant?.rfc;
        const restaurantStreet = orderRestaurant?.street ?? restaurant?.restaurantStreet;
        const phoneNumber = orderRestaurant?.phoneNumber ?? restaurant?.phoneNumber;
        const secondaryPhoneNumber = orderRestaurant?.secondaryPhoneNumber ?? restaurant?.secondaryPhoneNumber;
        const email = orderRestaurant?.email ?? restaurant?.email;

        builder.addCenteredBoldText(orderRestaurant?.name ?? restaurant?.name);

        if (businessName && ticketSections?.includes(TicketSections.BUSINESS_NAME)) {
            builder.addCenteredText(businessName);
        }

        if (rfc && ticketSections?.includes(TicketSections.RFC)) builder.addCenteredText(rfc);

        if (restaurantStreet && ticketSections?.includes(TicketSections.FISCAL_ADDRESS)) {
            builder.addCenteredText(restaurantStreet);
        }

        if (phoneNumber && ticketSections?.includes(TicketSections.RESTAURANT_PHONE)) {
            builder.addSeparatedBoldLeftTexts(translate('Phone'), formatAsNationalPhoneNumber(phoneNumber, restaurant.country));
        }
        if (secondaryPhoneNumber && ticketSections?.includes(TicketSections.RESTAURANT_SECONDARY_PHONE)) {
            builder.addSeparatedBoldLeftTexts(translate('Phone'), formatAsNationalPhoneNumber(secondaryPhoneNumber));
        }

        if (email && ticketSections?.includes(TicketSections.RESTAURANT_EMAIL)) {
            builder.addSeparatedBoldLeftTexts(translate('Email'), email ?? '');
        }

        builder.addLineSeparator();
    }

    function addOrderInfo(): void {
        const isExternalOrder = [Apps.DIDI_FOOD, Apps.RAPPI, Apps.UBER_EATS].includes(order.app as any);

        if (order.shortOrderId && ticketSections?.includes(TicketSections.ORDER_ID)) {
            builder.addBoldSeparatedTexts(translate('Order'), `#${order.shortOrderId}`);
        }

        if (order.pedidosYaShortOrderId) {
            builder.addBoldSeparatedTexts(translate('PedidosYa Order Code Id:'), `#${order.pedidosYaShortOrderId}`);
        }

        if (order.consecutiveOrderId && ticketSections?.includes(TicketSections.CONSECUTIVE_ID)) {
            builder.addBoldSeparatedTexts(translate('Folio'), `#${order.consecutiveOrderId ?? ''}`);
        }

        if (ticketSections?.includes(TicketSections.ORDER_TYPE)) {
            builder.addSeparatedBoldLeftTexts(`${translate('Order type')}`, translate(`OrderTypes.${order.orderType}`));
        }

        if (ticketSections?.includes(TicketSections.CREATED_AT)) {
            builder.addSeparatedBoldLeftTexts(translate('Date and time'), formatDateTimeString(order.createdAt, order.timeZone));
        }

        if (order.table && ticketSections?.includes(TicketSections.TABLE_NUMBER)) {
            builder.addSeparatedBoldLeftTexts(translate('Table'), order.table ?? '');
        }

        if (isEatHereOrder(order.orderType) && order.waiterName && ticketSections?.includes(TicketSections.WAITER_NAME)) {
            builder.addSeparatedBoldLeftTexts(translate('Waiter'), order.waiterName);
        }

        if (isExternalOrder) {
            builder.addSeparatedBoldLeftTexts(translate('App'), translate(`Apps.${order.app ?? ''}`));
        }

        if (isExternalOrder) {
            builder.addSeparatedBoldLeftTexts(translate(`${translate(`Apps.${order.app ?? ''}`)} Id`), formatShortExternalOrderId(order) ?? '');
        }

        if (order.rappiTurboOrder) {
            builder.addBoldRightText(translate('Rappi turbo'));
        }

        if (order.customerName && ticketSections?.includes(TicketSections.CUSTOMER_NAME)) {
            const didiFoodOrderIndex = order.didiFoodOrderIndex ? `${order.didiFoodOrderIndex}` : '';
            const customerName = `${order.customerName} ${didiFoodOrderIndex}`;

            builder.addSeparatedBoldLeftTexts(translate('Customer'), customerName.trim());
        }

        if (order.customerMobileNumber && ticketSections?.includes(TicketSections.CUSTOMER_PHONE)) {
            builder.addSeparatedBoldLeftTexts(translate('Phone'), formatAsNationalPhoneNumber(order.customerMobileNumber, order.country));
        }

        if (order.address?.street && ticketSections?.includes(TicketSections.CUSTOMER_ADDRESS)) {
            if (order.address?.formattedAddress) {
                builder.addBoldText(translate('Address'));
                builder.addText(order.address?.formattedAddress ?? '');
            } else if (order.address?.street) {
                builder.addBoldText(translate('Address'));
                builder.addText(order.address?.street ?? '');
            }

            if (order.address?.other) {
                builder.addText(translate('Apto'));
                builder.addText(order.address?.other ?? '');
            }

            if (order.address?.instructions) {
                builder.addText(translate('Instructions'));
                builder.addText(order.address?.instructions ?? '');
            }
        }

        if (order.customerNote || order.notes) {
            builder.addLineSeparator();
            if (order.customerNote) {
                builder.addBoldText(translate('Customer notes'));
                builder.addText(order.customerNote ?? '');
            }

            if (order.notes) {
                builder.addBoldText(translate('Order notes'));
                builder.addText(order.notes ?? '');
            }
        }

        if (restaurant.printRappiQrCodeOnTicketEnabled && order.rappiValidationCode) {
            builder.addQrCode(order.rappiValidationCode);
        }

        builder.addNewLine();
    }

    function addOrderItemsInfo(): void {
        addOrderItemsSectionTitle();

        if (order.customers?.length) {
            order.customers?.forEach((customer: any) => {
                if (!customer.orderItems?.length) return;
                builder.addBoldText(translate('Customer @customerNumber', { customerNumber: customer.customerNumber }));
                addOrderItems(customer.orderItems);
            });
        }

        if (order.courses?.length) {
            order.courses?.forEach((course: any) => {
                if (!course.orderItems?.length) return;

                builder.addBoldText(course.name);
                addOrderItems(course.orderItems);
            });
        }

        if (!order.customers?.length && !order.courses?.length) {
            addOrderItems(order.orderItems);
        }

        if (ticketSections?.includes(TicketSections.CANCELLED_ITEMS)) addCancelledOrderItems();

        builder.addBoldText(translate('@number Items', { number: getTotalItem(order) }));
    }

    function addCancelledOrderItems(): void {
        if (!order.cancelledItems?.length) return;

        if (order.orderItems.length) builder.addNewLine();

        const allModifiersHasQuantityEqualToOne = order.cancelledItems.every((orderItem) =>
            orderItem.modifierGroups.every((modifierGroup) => modifierGroup.modifiers.every((modifier) => modifier.quantity === 1)),
        );

        builder.addBoldText(translate('CANCELLED ITEMS'));
        order.cancelledItems?.forEach((orderItem) => {
            addOrderItemInfo({
                quantity: orderItem.quantity.toString(),
                name: orderItem.name,
                amount: '',
            });

            orderItem.modifierGroups.forEach((modifierGroup) => {
                modifierGroup.modifiers.forEach((modifier) => {
                    addOrderItemInfo({
                        quantity: '',
                        name: `${allModifiersHasQuantityEqualToOne ? '' : modifier.quantity} ${modifier.name}`,
                        amount: '',
                    });
                });
            });
        });
    }

    function addOrderItems(orderItems: Array<OrderItemVm>): void {
        if (!orderItems?.length) return;
        let orderItemsToPrint = orderItems;
        if (ticketSections?.includes(TicketSections.SPLIT_GROUPED_ORDER_ITEMS)) {
            orderItemsToPrint = splitGroupedItems(orderItemsToPrint);
        }

        const allSubModifiersHasQuantityEqualToOne = orderItemsToPrint.every((orderItem) => {
            const subModifiers = orderItem.modifierGroups.reduce<Array<any>>((subModifiers, modifierGroup) => {
                if (!modifierGroup.modifiers || modifierGroup.modifiers.length === 0) {
                    return subModifiers;
                }
                subModifiers.push(...modifierGroup.modifiers);
                return subModifiers;
            }, []);

            const subModifiersFiltered = subModifiers.filter((subModifier) => subModifier.quantity > 0);
            return subModifiersFiltered.every((subModifier) => subModifier.quantity === 1);
        });

        const allModifiersHasQuantityEqualToOne = orderItemsToPrint.every((orderItem) => {
            const modifiers = orderItem.modifierGroups.reduce<Array<any>>((modifiers, modifierGroup) => {
                if (!modifierGroup.modifiers || modifierGroup.modifiers.length === 0) {
                    return modifiers;
                }
                modifiers.push(...modifierGroup.modifiers);
                return modifiers;
            }, []);

            const modifiersFiltered = modifiers.filter((modifier) => modifier.quantity > 0);
            return modifiersFiltered.every((modifier) => modifier.quantity === 1);
        });

        orderItemsToPrint.forEach((orderItem) => {
            const getQuantityLabel = () => {
                if (orderItem.currency) return `${orderItem.quantity}${orderItem.currency}`;
                return !!orderItem.salesUnit ? `${orderItem.quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : orderItem.quantity.toString();
            };

            const subtotalOrderItem = BigNumber(orderItem.promoUnitPrice ?? orderItem.unitPrice)
                .multipliedBy(orderItem.quantity)
                .toString();

            addOrderItemInfo({
                quantity: getQuantityLabel(),
                name: orderItem.name,
                amount: formatAsCurrencyNumber(subtotalOrderItem, { country: order.country as any }),
            });

            orderItem.modifierGroups.forEach((modifierGroup) => {
                if (modifierGroup.showModifierGroupNameInCommand) {
                    addOrderItemInfo({
                        quantity: '',
                        name: modifierGroup.name,
                        amount: '',
                    });
                }

                const freeModifiers = mapFreeModifiersByModifierGroups(modifierGroup);
                freeModifiers.forEach((modifier) => {
                    const modifierPrice = BigNumber(modifier.nonFreeQuantity ?? modifier.quantity ?? 0).isZero()
                        ? undefined
                        : formatAsCurrencyNumber(
                              BigNumber(modifier.price)
                                  .multipliedBy(modifier.nonFreeQuantity ?? modifier.quantity)
                                  .multipliedBy(orderItem.quantity)
                                  .toString(),
                              { country: order.country as any },
                          );

                    addModifierItemInfo({
                        quantity: `${modifierGroup.showModifierGroupNameInCommand ? '* ' : ''}${allModifiersHasQuantityEqualToOne ? '' : modifier.quantity}`,
                        name: modifier.name,
                        amount: !BigNumber(modifier.price ?? 0).isZero() ? modifierPrice : undefined,
                    });

                    if (modifier.subModifierGroups?.length) {
                        modifier.subModifierGroups?.forEach((subModifierGroup) => {
                            subModifierGroup.subModifiers?.forEach((subModifier) => {
                                addOrderItemSubModifier({
                                    quantity: '',
                                    name: `${allSubModifiersHasQuantityEqualToOne ? '' : subModifier.quantity} ${subModifier.name}`,
                                    amount: '',
                                });
                            });
                        });
                    }
                });
            });
            if (orderItem.note) {
                addOrderItemSubModifier({
                    quantity: '',
                    name: `"${orderItem.note}"`,
                    amount: '',
                });
            }
        });
    }

    function addOrderItemsSectionTitle(): void {
        addOrderItemHeaderInfo({
            quantity: translate('Quant.'),
            name: translate('Description'),
            amount: translate('Ticket.Amount'),
        });
    }

    function addOrderItemHeaderInfo(orderItemColumn: OrderItemColumn): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.15,
                text: orderItemColumn.quantity,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.65,
                text: orderItemColumn.name,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.2,
                text: orderItemColumn.amount,
                textAlign: 'right',
            },
        ]);
    }

    function addOrderItemSubModifier(orderItemColumn: OrderItemColumn): void {
        builder.addColumns([
            {
                percentageWidth: 0.2,
                text: orderItemColumn.quantity,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.65,
                text: orderItemColumn.name,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.15,
                text: orderItemColumn.amount,
                textAlign: 'right',
            },
        ]);
    }

    function addOrderItemInfo(orderItemColumn: OrderItemColumn): void {
        builder.addColumns([
            {
                percentageWidth: 0.15,
                text: orderItemColumn.quantity,
                textAlign: 'left',
                fontWeight: 'bold',
            },
            {
                percentageWidth: 0.65,
                text: orderItemColumn.name,
                textAlign: 'left',
                fontWeight: 'bold',
            },
            {
                percentageWidth: 0.2,
                text: orderItemColumn.amount,
                textAlign: 'right',
                fontWeight: 'regular',
            },
        ]);
    }

    function addModifierItemInfo(orderItemColumn: OrderItemColumn): void {
        const columns: Array<BuilderPrintColumn> = [
            {
                percentageWidth: isEmpty(orderItemColumn.quantity) ? 0.2 : 0.15,
                text: '',
                textAlign: 'left',
                fontWeight: 'bold',
            },
            {
                percentageWidth: orderItemColumn.amount ? 0.65 : 0.75,
                text: orderItemColumn.name,
                textAlign: 'left',
            },
        ];

        if (!isEmpty(orderItemColumn.quantity)) {
            columns.splice(1, 0, {
                percentageWidth: 0.1,
                text: orderItemColumn.quantity,
                textAlign: 'left',
                fontWeight: 'bold',
            });
        }

        if (orderItemColumn.amount) {
            columns.push({
                percentageWidth: isEmpty(orderItemColumn.quantity) ? 0.15 : 0.1,
                text: orderItemColumn.amount ?? '',
                textAlign: 'right',
            });
        }

        builder.addColumns(columns);
    }

    function addTotalOrderInfo(): void {
        const hideIvaToTakeAwayOrder = order.orderType === OrderTypes.TAKE_AWAY_ORDER && !!restaurant.invoicesWithZeroTaxForTakeAwayOrdersEnabled;
        const hasOrderTax = !!order.tax;
        const printIva = !hideIvaToTakeAwayOrder && !hasOrderTax && !restaurant.ordersWithoutIvaEnabled && !order.orderTaxes;

        if (Number(order.discount ?? 0) !== 0) {
            builder.addSeparatedTexts(translate('Discount'), formatAsCurrencyNumber(order.discount, { country: order.country as any }));
        }
        if (Number(order.customerDeliveryCost ?? 0) !== 0 && !restaurant.hideDeliveryCostPaidByRestaurantOnTicketEnabled) {
            builder.addSeparatedTexts(translate('Delivery'), formatAsCurrencyNumber(order.customerDeliveryCost, { country: order.country as any }));
        }

        const totalBeforeTips = BigNumber(order.total)
            .minus(order.posTipAmount ?? 0)
            .minus(order.driverTip ?? 0)
            .toString();

        builder.addBoldSeparatedTexts(translate('Total'), formatAsCurrencyNumber(totalBeforeTips, { country: order.country as any }));
        if (order.posTipAmount) {
            builder.addSeparatedTexts(translate('Tip'), formatAsCurrencyNumber(order.posTipAmount, { country: order.country as any }));
        }
        if (order.driverTip) {
            builder.addSeparatedTexts(translate('Driver tips'), formatAsCurrencyNumber(order.driverTip, { country: order.country as any }));
        }
        if (printIva) {
            builder.addLineSeparator();
            builder.addSeparatedTexts(translate('IVA'), formatAsCurrencyNumber(calculateIva(order.total, order.country), { country: order.country as any }));
        }

        if (order.orderTaxes?.length) {
            order.orderTaxes.forEach((tax) => {
                builder.addSeparatedTexts(tax.name, formatAsCurrencyNumber(tax.taxAmount, { country: order.country as any }));
            });
        }
        builder.addLineSeparator();
    }

    function addPaymentMethodsInfo(): void {
        if (!ticketSections?.includes(TicketSections.PAYMENT_METHOD) || isClosedOrder(order.orderStatus)) return;
        const hasChange = order.posChange || order.change || order.kioskChange;
        builder.addCenteredBoldText(translate('Payment ways'));
        if (order.payments) {
            order.payments?.forEach((posPayment: any) => {
                const paymentLabel = posPayment.restaurantCurrency
                    ? `${translatePaymentMethod({ paymentMethod: posPayment.customPaymentMethod ?? posPayment.paymentMethod, app: order.app })} (${posPayment.restaurantCurrency.currency})`
                    : translatePaymentMethod({ paymentMethod: posPayment.customPaymentMethod ?? posPayment.paymentMethod, app: order.app });

                builder.addSeparatedTexts(paymentLabel, formatAsCurrencyNumber(posPayment.amount, { country: order.country as any }));
            });
        } else {
            builder.addSeparatedTexts(translateOrderPaymentMethod(order), formatAsCurrencyNumber(order.mandatoryCashAmount || order.total, { country: order.country as any }));
        }

        if (hasChange && ticketSections?.includes(TicketSections.PAYMENT_CHANGE)) builder.addSeparatedTexts(translate('change'), formatAsCurrencyNumber(hasChange, { country: order.country as any }));
        builder.addLineSeparator();
    }

    function addPrintDate(): void {
        if (!ticketSections?.includes(TicketSections.DATE_OF_PRINT)) return;

        if (printerPaperSize === PrinterPaperSizes['58_MM']) {
            builder.addText(translate('Print date'));
            builder.addText(formatDateTimeString(new Date(), order.timeZone));
            builder.addLineSeparator();
        } else {
            builder.addSeparatedTexts(translate('Print date'), formatDateTimeString(new Date(), order.timeZone));
            builder.addLineSeparator();
        }
    }

    function addInvoiceInfo(): void {
        const showInvoiceLink = ticketSections?.includes(TicketSections.INVOICE_LINK);
        const showInvoiceQr = ticketSections?.includes(TicketSections.INVOICE_QR);
        if ((!showInvoiceQr && !showInvoiceLink) || isClosedOrder(order.orderStatus)) return;

        const isMexicanOrder = order.country === Countries.MX;

        if (!isMexicanOrder) return;
        const hasCsdCertificateInfo = restaurant.csdCertificateUploaded || restaurant.csdCertificateRestaurantId;

        const showInvoiceInfo = hasCsdCertificateInfo && restaurant.urlSubdomain && restaurant.urlPathname && order.shortOrderId;
        if (!showInvoiceInfo) return;

        builder.addCenteredBoldText(translate('Online invoice'));
        builder.addCenteredText(translate('Remember you have the current month'));
        builder.addCenteredText(translate('to do your invoice'));
        builder.addCenteredText(translate('Go to the next link'));

        if (showInvoiceLink) {
            builder.addCenteredText(`https://${restaurant.urlSubdomain ?? ''}.ambit.la/${restaurant.urlPathname ?? ''}/factura`);
        }

        if (ticketSections?.includes(TicketSections.INVOICE_QR)) {
            builder.addQrCode(`https://${restaurant.urlSubdomain ?? ''}.ambit.la/${restaurant.urlPathname ?? ''}/factura`);
        }

        builder.addCenteredText(translate('with the order id @orderId', { orderId: order.shortOrderId }));
        builder.addLineSeparator();
    }

    function addOrderInvoiceData(): void {
        if (!order?.invoiced) return;

        const country = order.country;
        if (country !== Countries.UY) return;

        builder.addCenteredText(translate('FIN ADENDA'));
        builder.addNewLine();
        if (order.invoice?.bussinessName) builder.addSeparatedTexts(translate('Business Name'), order.invoice?.bussinessName ?? '');
        if (order.invoice?.rfc) builder.addSeparatedTexts(translate('RUC'), order.invoice?.rfc ?? '');
        if (mapCfeTypeToInvoiceType(order.invoice?.cfeType)) builder.addSeparatedTexts(translate('Type'), mapCfeTypeToInvoiceType(order.invoice?.cfeType));
        if (order.invoice?.series) builder.addSeparatedTexts(translate('Serie'), order.invoice?.series ?? '');
        if (order.invoice?.cfeNumber) builder.addSeparatedTexts(translate('CFE Number'), order.invoice?.cfeNumber ?? '');
        if (order.invoice?.uruwareInvoiceId) builder.addSeparatedTexts(translate('CAE'), order.invoice?.uruwareInvoiceId ?? '');
        if (order.invoice?.invoiceLegalInfo?.fiscalId) builder.addSeparatedTexts(translate('RUC Buyer'), order.invoice?.invoiceLegalInfo?.fiscalId ?? '');
        if (order.invoice?.invoiceLegalInfo?.businessName) builder.addSeparatedTexts(translate('Business Name Buyer'), order.invoice?.invoiceLegalInfo?.businessName ?? '');
        if (order.invoice?.invoiceLegalInfo?.address) builder.addSeparatedTexts(translate('Address'), order.invoice?.invoiceLegalInfo?.address ?? '');
        if (order.invoice?.securityCode) builder.addSeparatedTexts(translate('Security Code'), order.invoice?.securityCode ?? '');
        builder.addCenteredText(translate('I.V.A to the day'));
        builder.addNewLine();
        builder.addBoldSeparatedTexts(translate('You can review the receipt at'), '');
        builder.addCenteredBoldText('www.efactura.dgi.gub.uy/principal/verificacioncfe?es');
        builder.addLineSeparator();
    }

    function addTicketFooter(): void {
        if (ticketSections?.includes(TicketSections.CUSTOM_FOOTER_TEXT) && restaurant.ticketCustomFooter) {
            builder.addCenteredBoldText(restaurant.ticketCustomFooter ?? '');
        }
        builder.addCenteredText(translate('With technology from Ambit.la'));
    }
}

type Params = {
    order: OrderVm;
    restaurant: RestaurantVm;
    printerPaperSize: PrinterPaperSize;
    internalUser: boolean;
    openCashBox?: boolean;
    ticketSections: Array<TicketSection>;
};

type OrderItemColumn = {
    quantity: string;
    name: string;
    amount: string | undefined;
};
