import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { CommandSection, CommandSections } from 'src/constants/CommandSection';
import { translate } from 'src/i18n/translate';
import { BuilderPrintColumn, printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import { type PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { OrderItemVm, OrderVm, SubModifierGroupVm, SubModifierVm } from 'src/types/OrderVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isEmpty } from 'src/utils/object/isEmpty';
import { formatShortExternalOrderId } from 'src/utils/order/formatShortExternalOrderId';
import { getTotalItem } from 'src/utils/order/getTotalItems';
import { isPendingPayment } from 'src/utils/order/isPendingPayment';
import { splitGroupedItems } from 'src/utils/order/splitGroupedItems';
import { isPlannedPickupTime } from 'src/utils/pickupTimeType/isPlannedPickupTime';
import { mapFreeModifiersByModifierGroups } from 'src/utils/pos/mapFreeModifiersByModifierGroups';
import { translateOrderPaymentMethod } from 'src/utils/translate/translateOrderPaymentMethod';

export function createOrderCommandPrint({ order, restaurant, commandSections, increaseCommandFontSizeEnabled }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    if (isPlannedPickupTime(order.pickupTimeType)) {
        builder.setLargerFont();
        builder.addCenteredBoldText(translate('Planned order'));
        builder.addCenteredBoldText(`${moment.tz(order.pickupTime, order.timeZone).format('DD MMMM HH:mm')}`);
        builder.setRegularFont();
    }

    addOrderInfo();
    if (commandSections?.includes(CommandSections.SALES_INFO)) addOrderItemsInfo();

    return builder.build();

    function addOrderInfo(): void {
        if (order.shortOrderId && commandSections?.includes(CommandSections.ORDER_ID)) {
            builder.addBoldSeparatedTexts(translate('Order'), `#${order.shortOrderId}`);
        }

        if (order.consecutiveOrderId && commandSections?.includes(CommandSections.CONSECUTIVE_ID)) {
            builder.addBoldSeparatedTexts(translate('Folio'), `#${order.consecutiveOrderId ?? ''}`);
        }

        if (commandSections?.includes(CommandSections.ORDER_TYPE)) {
            builder.addSeparatedBoldLeftTexts(`${translate('Type')}`, translate(`OrderTypes.${order.orderType}`));
        }

        if (commandSections?.includes(CommandSections.PAYMENT_METHOD)) {
            const singlePaymentMethod = order.payments && order.payments?.length === 1 ? order.payments[0] : undefined;
            const singlePaymentMethodLabel = singlePaymentMethod
                ? (singlePaymentMethod?.customPaymentMethod ?? translate(singlePaymentMethod.paymentMethod))
                : !order.payments?.length
                  ? translateOrderPaymentMethod(order)
                  : translate('Multiple payments');
            builder.addSeparatedBoldLeftTexts(translate('Payment method'), isPendingPayment(order.paymentMethod!) ? translateOrderPaymentMethod(order) : singlePaymentMethodLabel);
        }

        if (commandSections?.includes(CommandSections.CREATED_AT)) {
            builder.addSeparatedBoldLeftTexts(translate('Date'), formatDateTimeString(order.modifiedAt || new Date(), order.timeZone));
        }
        if (order.customerName && commandSections?.includes(CommandSections.CUSTOMER_NAME)) {
            const didiCustomerId = order.didiFoodOrderIndex ? `${order.didiFoodOrderIndex}` : '';
            const customerName = `${order.customerName} ${didiCustomerId}`;

            builder.addSeparatedBoldLeftTexts(translate('Customer'), customerName.trim());
        }
        if (order.table && commandSections?.includes(CommandSections.TABLE_NUMBER)) {
            builder.addSeparatedBoldLeftTexts(translate('Table'), order.table ?? '');
        }
        if (order.externalOrder) {
            builder.addSeparatedBoldLeftTexts(translate('App'), translate(`Apps.${order.app ?? ''}`));
        }
        if (order.externalOrder) {
            builder.addSeparatedBoldLeftTexts(translate(`${translate(`Apps.${order.app ?? ''}`)} Id`), formatShortExternalOrderId(order) ?? '');
        }

        if (order.customerNote || order.notes) {
            builder.addLineSeparator();
            if (order.customerNote) {
                builder.addBoldText(translate('Customer notes'));
                builder.addText(order.customerNote ?? '');
            }

            if (order.notes) {
                builder.addBoldText(translate('Order notes'));
                builder.addText(order.notes ?? '');
            }
        }

        builder.addNewLine();
    }

    function addOrderItemsInfo(): void {
        const printItemsCost = commandSections?.includes(CommandSections.ITEMS_COST) && !increaseCommandFontSizeEnabled;
        if (increaseCommandFontSizeEnabled) builder.setLargerFont();

        if (!increaseCommandFontSizeEnabled) {
            const columnHeaders: Array<BuilderPrintColumn> = [
                {
                    percentageWidth: 0.15,
                    text: translate('Quant.'),
                    textAlign: 'left',
                },
                {
                    percentageWidth: printItemsCost ? 0.65 : 0.85,
                    text: translate('Desc'),
                    textAlign: 'left',
                },
            ];

            if (printItemsCost) {
                columnHeaders.push({
                    percentageWidth: 0.2,
                    text: translate('Ticket.Amount'),
                    textAlign: 'right',
                });
            }
            builder.addBoldColumns(columnHeaders);
        }

        if (order.customers?.length) {
            order.customers?.forEach((customer: any) => {
                if (!customer.orderItems?.length) return;

                builder.addBoldText(translate('Customer @customerNumber', { customerNumber: customer.customerNumber }));
                addOrderItems(customer.orderItems, printItemsCost);
            });
        }

        if (order.courses?.length) {
            order.courses?.forEach((course) => {
                if (!course.orderItems?.length) return;

                builder.addBoldText(course.name);
                addOrderItems(course.orderItems, false);
            });
        }

        if (!order.customers?.length && !order.courses?.length) {
            addOrderItems(order.orderItems, printItemsCost);
        }

        addCancelledOrderItems();

        const totalCost = commandSections?.includes(CommandSections.TOTAL_COST) ? formatAsCurrencyNumber(order.total, { country: restaurant.country as any }) : '';
        if (!increaseCommandFontSizeEnabled) builder.addSeparatedBoldLeftTexts(translate('@number Items', { number: getTotalItem(order) }), totalCost);
        if (increaseCommandFontSizeEnabled) builder.addText(translate('@number Items', { number: getTotalItem(order) }));
    }

    function addOrderItems(orderItems: Array<OrderItemVm>, printItemsCost: boolean): void {
        if (!orderItems?.length) return;
        let orderItemsToPrint = orderItems;
        if (commandSections?.includes(CommandSections.SPLIT_GROUPED_ORDER_ITEMS)) {
            orderItemsToPrint = splitGroupedItems(orderItemsToPrint);
        }

        const allSubModifiersHasQuantityEqualToOne = orderItemsToPrint.every((orderItem) => {
            const subModifiers = orderItem.modifierGroups.reduce<Array<any>>((subModifiers, modifierGroup) => {
                if (!modifierGroup.modifiers || modifierGroup.modifiers.length === 0) {
                    return subModifiers;
                }
                subModifiers.push(...modifierGroup.modifiers);
                return subModifiers;
            }, []);

            const subModifiersFiltered = subModifiers.filter((subModifier) => subModifier.quantity > 0);
            return subModifiersFiltered.every((subModifier) => subModifier.quantity === 1);
        });

        const allModifiersHasQuantityEqualToOne = orderItemsToPrint.every((orderItem) => {
            const modifiers = orderItem.modifierGroups.reduce<Array<any>>((modifiers, modifierGroup) => {
                if (!modifierGroup.modifiers || modifierGroup.modifiers.length === 0) {
                    return modifiers;
                }
                modifiers.push(...modifierGroup.modifiers);
                return modifiers;
            }, []);

            const modifiersFiltered = modifiers.filter((modifier) => modifier.quantity > 0);
            return modifiersFiltered.every((modifier) => modifier.quantity === 1);
        });

        orderItemsToPrint.forEach((orderItem) => {
            const quantity = !!orderItem.salesUnit ? `${orderItem.quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : orderItem.quantity.toString();

            const subtotalOrderItem = BigNumber(orderItem.promoUnitPrice ?? orderItem.unitPrice)
                .multipliedBy(orderItem.quantity)
                .toString();

            addOrderItemInfo({
                quantity: quantity,
                name: orderItem.name,
                amount: printItemsCost ? formatAsCurrencyNumber(subtotalOrderItem, { country: restaurant.country as any }) : undefined,
            });

            orderItem.modifierGroups.forEach((modifierGroup) => {
                if (modifierGroup.showModifierGroupNameInCommand) {
                    addOrderItemInfo({
                        quantity: '',
                        name: modifierGroup.name,
                    });
                }

                const freeModifiers = mapFreeModifiersByModifierGroups(modifierGroup);
                freeModifiers.forEach((modifier) => {
                    const modifierPrice = BigNumber(modifier.nonFreeQuantity ?? modifier.quantity ?? 0).isZero()
                        ? undefined
                        : formatAsCurrencyNumber(
                              BigNumber(modifier.price)
                                  .multipliedBy(modifier.nonFreeQuantity ?? modifier.quantity)
                                  .multipliedBy(orderItem.quantity ?? 1)
                                  .toString(),
                              { country: order.country as any },
                          );

                    addModifierItemInfo(
                        {
                            quantity: `${modifierGroup.showModifierGroupNameInCommand ? '* ' : ''}${allModifiersHasQuantityEqualToOne ? '' : modifier.quantity}`,
                            name: modifier.name,
                            amount: !BigNumber(modifier.price ?? 0).isZero() && printItemsCost ? modifierPrice : undefined,
                        },
                        increaseCommandFontSizeEnabled,
                    );

                    modifier.subModifierGroups?.forEach((subModifierGroup: SubModifierGroupVm) => {
                        subModifierGroup.subModifiers?.forEach((subModifier: SubModifierVm) => {
                            addOrderItemSubModifier({
                                quantity: '',
                                name: `${allSubModifiersHasQuantityEqualToOne ? '' : subModifier.quantity} ${subModifier.name}`,
                                amount: '',
                            });
                        });
                    });
                });
            });

            if (orderItem.note) {
                addOrderItemSubModifier({
                    quantity: '',
                    name: `"${orderItem.note}"`,
                });
            }
        });
    }

    function addOrderItemInfo(orderItemColumn: OrderItemColumn): void {
        const columns: Array<BuilderPrintColumn> = [
            {
                percentageWidth: 0.15,
                text: orderItemColumn.quantity,
                textAlign: 'left',
                fontWeight: 'bold',
            },
            {
                percentageWidth: orderItemColumn.amount ? 0.65 : 0.85,
                text: orderItemColumn.name,
                textAlign: 'left',
                fontWeight: 'bold',
            },
        ];

        if (orderItemColumn.amount) {
            columns.push({
                percentageWidth: 0.2,
                text: orderItemColumn.amount,
                textAlign: 'right',
                fontWeight: 'regular',
            });
        }

        builder.addColumns(columns);
    }

    function addModifierItemInfo(orderItemColumn: OrderItemColumn, increaseCommandFontSizeEnabled: boolean | undefined): void {
        const quantityColumnSize = !increaseCommandFontSizeEnabled ? 0.1 : orderItemColumn.quantity?.length > 3 ? 0.2 : 0.15;
        const descriptionColumnSize = BigNumber(1)
            .minus(0.15)
            .minus(quantityColumnSize)
            .minus(!!orderItemColumn.amount ? 0.15 : 0)
            .toNumber();

        const columns: Array<BuilderPrintColumn> = [
            {
                percentageWidth: 0.15,
                text: '',
                textAlign: 'left',
                fontWeight: 'bold',
            },
            {
                percentageWidth: descriptionColumnSize,
                text: orderItemColumn.name,
                textAlign: 'left',
            },
        ];

        if (!isEmpty(orderItemColumn.quantity)) {
            columns.splice(1, 0, {
                percentageWidth: quantityColumnSize,
                text: orderItemColumn.quantity,
                textAlign: 'left',
                fontWeight: 'bold',
            });
        }

        if (orderItemColumn.amount) {
            columns.push({
                percentageWidth: 0.15,
                text: orderItemColumn.amount ?? '',
                textAlign: 'right',
            });
        }

        builder.addColumns(columns);
    }

    function addOrderItemSubModifier(orderItemColumn: OrderItemColumn): void {
        const columns: Array<BuilderPrintColumn> = [
            {
                percentageWidth: 0.2,
                text: orderItemColumn.quantity,
                textAlign: 'left',
            },
            {
                percentageWidth: orderItemColumn.amount ? 0.7 : 0.8,
                text: orderItemColumn.name,
                textAlign: 'left',
            },
        ];

        if (orderItemColumn.amount) {
            columns.push({
                percentageWidth: 0.1,
                text: orderItemColumn.amount,
                textAlign: 'right',
            });
        }

        builder.addColumns(columns);
    }

    function addCancelledOrderItems(): void {
        if (!order.cancelledItems?.length) return;

        if (order.orderItems.length) builder.addNewLine();

        builder.addBoldText(translate('CANCELLED ITEMS'));
        order.cancelledItems?.forEach((orderItem) => {
            addOrderItemInfo({
                quantity: orderItem.quantity.toString(),
                name: orderItem.name,
                amount: '',
            });

            orderItem.modifierGroups.forEach((modifierGroup) => {
                modifierGroup.modifiers.forEach((modifier) => {
                    addOrderItemInfo({
                        quantity: '',
                        name: `${modifier.quantity} ${modifier.name}`,
                        amount: '',
                    });
                });
            });
        });
    }
}

type Params = {
    order: OrderVm;
    restaurant: RestaurantVm;
    internalUser: boolean;
    increaseCommandFontSizeEnabled?: boolean;
    commandSections: Array<CommandSection>;
};

type OrderItemColumn = {
    quantity: string;
    name: string;
    amount?: string;
};
