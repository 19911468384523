import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Button } from '@pidedirecto/ui';
import { BellIcon, CircleCheckIcon, ThumbsUpIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { markOrderPickedUpByExternalDeliveryApi } from 'src/api/letseatmanager/order/markOrderPickedUpByExternalDeliveryApi';
import { markOrderReadyForPickUpApi } from 'src/api/letseatmanager/order/markOrderReadyForPickupApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { ReturnedInvoicedOrderStatus } from 'src/components/orderCard/ReturnedInvoicedOrderStatus';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { OrderRejectReasonExtra } from 'src/constants/OrderRejectReasonExtra';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { PotIcon } from 'src/icons/PotIcon';
import { AssignDriverDialog } from 'src/scenes/letseatadmin/driver/AssignDriverDialog';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderStatus } from 'src/utils/translate/translateOrderStatus';

/** @deprecated */
export function DeprecatedOrderCardStatusInfo({ order, updateLoadingOrder, onError, onChange, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const [printOrderTicket] = usePrintOrderTicket();

    const [assignDriverDialogState, setAssignDriverDialogState] = useState({ open: false });

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);

    const isOrderPrepared = order.kitchenStatus === KitchenStatuses.PREPARED;
    const isOrderPreparing = order.kitchenStatus === KitchenStatuses.PREPARING;
    const showOrderStatus = order.orderStatus !== OrderStatuses.ACCEPTED && order.orderStatus !== OrderStatuses.NEW;
    const isOrderFinished = [OrderStatuses.REJECTED, OrderStatuses.COMPLETE, OrderStatuses.CANCELLED].includes(order.orderStatus as any);
    const isKioskOrderMarkedReadyForPickedUp = isKioskApp(order.app) && !order.markedReadyForPickupAt;
    const isInternalDeliveryOrder = orderIsInternalDeliveryOrder(order);
    const autoInvoiceOrdersEnabled = useSelector((state) => state.app.restaurant.autoInvoiceOrdersEnabled);
    const isAssignDriverOrderState = isDeliveryOrder(order.orderType) && externalOwnFleetEnabled && !order.driverId;
    const isLookingForDriverOrderState = isDeliveryOrder(order.orderType) && !order.deliveryStatus && !order.externalOwnFleet;
    const hasDeliveryStatus = (isInternalDeliveryOrder || externalDelivery) && order.deliveryStatus;
    const isMarkedReadyForPickupAt = (!isInternalDeliveryOrder && order.markedReadyForPickupAt) || !markReadyForPickupButtonEnabled;
    const shouldMarkOrderReady = !isAssignDriverOrderState && !isLookingForDriverOrderState && !order.arrivedAtClientAt && !hasDeliveryStatus && !isMarkedReadyForPickupAt;
    const orderHasCreditNote = order.invoiceUruwareCreditNoteId || order.invoiceNubefactCreditNoteId;

    const closeAssignDriverDialog = () => {
        setAssignDriverDialogState({ open: false });
        updateLoadingOrder(false);
    };

    const openAssignDriverDialog = () => {
        setAssignDriverDialogState({ open: true });
        updateLoadingOrder(true);
    };

    const markOrderReadyForPickup = async () => {
        updateLoadingOrder(true);
        const request = {
            restaurantId,
            orderId: order.orderId,
        } as const;
        const response = await markOrderReadyForPickUpApi(request);
        updateLoadingOrder(false);
        if (!response.ok) {
            SentryService.logError('Failed to markOrderReadyForPickup', { markOrderReadyForPickupRequest: request, response });
            onError?.(`Failed to mark order ready for pickup order cause of ${getErrorDescriptionFromResponse(response)}`);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully markedOrderReadyForPickup', { markOrderReadyForPickupRequest: request });
        const orderVm = response.data;
        onChange?.(orderVm);
    };

    const markOrderPickedUpByExternalDelivery = async () => {
        updateLoadingOrder(true);
        const request = {
            orderId: order.orderId,
        } as const;
        const response = await markOrderPickedUpByExternalDeliveryApi(request);
        updateLoadingOrder(false);
        if (!response.ok) {
            SentryService.logError('Failed to markOrderPickedUpByExternalDelivery', { markOrderPickedUpByExternalDeliveryRequest: request, response });
            onError?.(`Failed to mark order picked up by external delivery cause of ${getErrorDescriptionFromResponse(response)}`);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully markOrderPickedUpByExternalDelivery', { markOrderPickedUpByExternalDeliveryRequest: request });
        const orderVm = response.data;
        onChange?.(orderVm);
    };

    async function completeOrder() {
        const request = {
            orderId: order.orderId,
        } as const;
        updateLoadingOrder(true);
        const response = await letseatmanagerApiDeprecated.currentOrders.completeOrder(request);
        updateLoadingOrder(false);
        if (!response.ok) {
            SentryService.logError('Failed to complete order', { completeOrderRequest: request, response });
            onError?.(`Failed to complete order cause of ${getErrorDescriptionFromResponse(response)}`);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully completed order', { completeOrderRequest: request });
        const orderVm = response.data;

        if (!order.invoiced && orderVm.invoiced && isUruguay(orderVm.country) && autoInvoiceOrdersEnabled) {
            await printOrderTicket(orderVm);
        }
        onChange?.(orderVm);
    }

    const getStatusColor = () => {
        switch (order.orderStatus) {
            case OrderStatuses.REJECTED:
                return '#E82D5A';
            case OrderStatuses.CANCELLED:
                return '#E82D5A';
            case OrderStatuses.COMPLETE:
                return '#5268F4';
            case OrderStatuses.PENDING:
                return '#EC9C23';
            default:
                return '#6A7575';
        }
    };

    const handleOrderState = () => {
        if (isInternalDeliveryOrder) return;
        if (isAssignDriverOrderState) return openAssignDriverDialog();
        if (isDeliveryOrder(order.orderType) && externalDelivery && !order.deliveryStatus && !order.externalOwnFleet) return markOrderPickedUpByExternalDelivery();
        if (order.markedReadyForPickupAt || !markReadyForPickupButtonEnabled || (order.externalOwnFleet && order.driverId) || (isDeliveryOrder(order.orderType) && externalDelivery))
            return completeOrder();
        return markOrderReadyForPickup();
    };

    const getOrderStatusButtonLabel = () => {
        if (isAssignDriverOrderState) return translate('Assign a Driver');
        if (isLookingForDriverOrderState) return translate('Looking for driver');
        if (order.arrivedAtClientAt) return translate(`OrderStatuses.${OrderStatuses.ARRIVED_AT_CLIENT}`);
        if (hasDeliveryStatus) return translate(`DeliveryStatuses.${order.deliveryStatus}`);
        if (isMarkedReadyForPickupAt) return translateOrderStatus(OrderStatuses.COMPLETED, order.orderType);
        return translate('Mark order ready');
    };
    const getOrderStatusLabel = () => {
        if (order.orderStatus !== OrderStatuses.REJECTED) return translate(`OrderStatuses.${order.orderStatus}`);

        const rejectedOrderLabel = translate('. Reason: ') + translate(`OrderRejectReasons.${order.orderRejectReason ?? ''}`);
        const showRejectOrderReasonExtras = !restaurantUser && order?.orderRejectReasonExtras?.length;
        const rejectedOrderReasonExtras = `${translate('Extras:')} ${
            order?.orderRejectReasonExtras?.map((orderRejectReasonExtra: OrderRejectReasonExtra) => translate(`OrderRejectReasonExtras.${orderRejectReasonExtra}`))?.join(', ') ?? ''
        }`;

        return `${translate(`OrderStatuses.${order.orderStatus}`)}${rejectedOrderLabel} ${showRejectOrderReasonExtras ? rejectedOrderReasonExtras : ''}`;
    };

    return (
        <>
            <AssignDriverDialog open={assignDriverDialogState.open} onClose={closeAssignDriverDialog} orderId={order.orderId} onSuccess={onChange} disabled={order.demoOrder} />
            <div style={{ width: '100%' }}>
                {showOrderStatus && !orderHasCreditNote && (
                    <>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                            <div
                                className={classNames(classes.orderStatusContainer, isCompletedOrder(order.orderStatus) ? classes.completedOrder : '')}
                                style={{ justifyContent: isPendingOrder(order.orderStatus) ? 'space-between' : 'center' }}
                            >
                                <div className={classes.orderStatus} style={{ color: getStatusColor() }}>
                                    {isCompletedOrder(order.orderStatus) && <CircleCheckIcon />}
                                    {getOrderStatusLabel()}
                                </div>
                                {order.kitchenStatus && !isOrderFinished && (
                                    <div className={classNames(classes.kitchenText, isOrderPreparing ? classes.warningText : classes.orderPrepared)}>
                                        <PotIcon color={isOrderPrepared ? '#03D09F' : '#EC9C23'} />
                                        <span>{translate(`KitchenStatuses.${order.kitchenStatus ?? ''}`)}</span>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <div className={classes.separator}></div>
                    </>
                )}
                {orderHasCreditNote && (
                    <>
                        <ReturnedInvoicedOrderStatus />
                        <div className={classes.separator}></div>
                    </>
                )}
                {isAcceptedOrder(order.orderStatus) && !orderHasCreditNote && (
                    <>
                        {isKioskOrderMarkedReadyForPickedUp && (
                            <Button disabled={disabled} onClick={markOrderReadyForPickup} variant='outline' classes={{ button: classes.button }}>
                                <BellIcon />
                                {translate('Mark order ready')}
                            </Button>
                        )}
                        {!isKioskOrderMarkedReadyForPickedUp && (
                            <Button
                                disabled={disabled || viewUser}
                                onClick={handleOrderState}
                                classes={{ button: shouldMarkOrderReady ? classes.button : classes.servedOrderButton }}
                                variant={shouldMarkOrderReady ? 'outline' : 'text'}
                            >
                                {!isInternalDeliveryOrder && !shouldMarkOrderReady && <ThumbsUpIcon />}
                                {shouldMarkOrderReady && <BellIcon />}
                                {getOrderStatusButtonLabel()}
                            </Button>
                        )}
                        <div className={classes.separator}></div>
                    </>
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    button: {
        width: '100%',
    },
    servedOrderButton: {
        width: '100%',
        border: `1px solid #0FA489`,
        backgroundColor: 'transparent',
        color: '#0FA489',
        padding: '8px 12px',
    },
    orderStatusContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    completedOrder: {
        justifyContent: 'center',
        border: `1px solid #5268F4`,
        backgroundColor: 'transparent',
        color: '#5268F4',
        padding: '8px 12px',
        borderRadius: 6,
    },
    orderStatus: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: 6,
        justifyContent: 'center',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: '#6A7575',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    orderPrepared: {
        color: '#03D09F',
    },
    kitchenText: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
    },
    warningText: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: theme.palette.warning.light,
    },
}));

type Props = {
    order: OrderVm;
    updateLoadingOrder: any;
    onError?: (arg1: string) => any;
    onChange?: (arg1: OrderVm) => any;
    disabled: boolean;
};
