import { useEffect } from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';

export function usePideDirectoAdminAppMessages(): void {
    const setPideDirectoAdminAppVersion = useAction(appReducer.actions.setPideDirectoAdminAppVersion);
    const setDeviceMemory = useAction(appReducer.actions.setDeviceMemory);
    const setDeviceMemoryUsed = useAction(appReducer.actions.setDeviceMemoryUsed);
    const setAppBuildType = useAction(appReducer.actions.setAppBuildType);
    const setQpayPosTerminalDeviceId = useAction(appReducer.actions.setQpayPosTerminalDeviceId);

    useEffect(() => {
        function handlePideDirectoAdminAppMessages(event: any) {
            if (event.data?.type === 'pideDirectoAdminAppVersion') {
                setPideDirectoAdminAppVersion(event.data.data.pideDirectoAdminAppVersion);
            }

            if (event.data?.type === 'deviceMemory') {
                setDeviceMemory(event.data.data.deviceMemory);
            }

            if (event.data?.type === 'deviceMemoryUsed') {
                setDeviceMemoryUsed(event.data.data.deviceMemoryUsed);
            }

            if (event.data?.type === 'appBuildType') {
                setAppBuildType(event.data.data.appBuildType);
            }

            if (event.data?.type === 'qpayTerminalDeviceId') {
                setQpayPosTerminalDeviceId(event.data.data.qpayTerminalDeviceId);
            }

            if (event.data?.type === 'consoleLog') {
                (console as any)[event.data.data.logLevel](...event.data.data.args); // Bridge console logs to Metro bundler console
            }
        }

        window.addEventListener('message', handlePideDirectoAdminAppMessages);
        return () => {
            window.removeEventListener('message', handlePideDirectoAdminAppMessages);
        };
    }, []);
}
