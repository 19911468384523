import { makeStyles } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { updateOrderTicketRequestedApi } from 'src/api/letseatmanager/order/updateOrderTicketRequestedApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { useGetCustomerInfo } from 'src/modules/pos/customer/useGetCustomerInfo';
import { useHasCustomerInfoAnyError } from 'src/modules/pos/customer/useHasCustomerInfoAnyError';
import { posReducer } from 'src/reducers/posReducer';
import { useClearPos } from 'src/services/pos/useClearPos';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { useAction } from 'src/utils/react/useAction';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';
import { requireValue } from 'src/utils/require/requireValue';

export const PosPaymentPrintOrderButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const [printOrderTicket] = usePrintOrderTicket();
    const hasAnyError = useHasCustomerInfoAnyError();
    const getCustomerInfo = useGetCustomerInfo();
    const notification = useNotification();
    const clearPos = useClearPos();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const [existPrintersConfigured, setExistPrintersConfigured] = useState(false);

    const { updatePosPendingOrder } = useUpdatePosPendingOrder();
    const { saveNewPosOrder } = useSaveNewPosOrder();

    const total = useSelector((state) => state.pos.payment?.total);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const table = useSelector((state) => state.pos.table);
    const items = useSelector((state) => state.pos.items);
    const orderType = useSelector((state) => state.pos.orderType);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const payments = useSelector((state) => state.pos.payments);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const posNumberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);
    const updateOrder = useAction(app2.actions.updateOrder);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const disabled = disabledPosPaymentActions || paying;

    const { hasPrintersToPrint } = useHasPrintersToPrint();

    useEffect(() => {
        setExistPrintersConfigured(hasPrintersToPrint);
    }, []);

    const savePendingOrder = async () => {
        if (hasAnyError) {
            notification({ message: translate('There is pending data or an error in the customer data') });
            return;
        }

        if (hasPaymentMissing && (!posPendingOrdersEnabled || isDeliveryOrder(orderType))) {
            alert(translate('There is pending balance.'));
            return;
        }

        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        const { response, request } = await saveNewPosOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
            numberOfCustomers: numberOfCustomers ?? table?.numberOfCustomers ?? posNumberOfCustomers,
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request as any);
                notification({ message: translate('Connection error, the order will be created when the connection is restored') });
                clearPos();
                if (request.orderStatus !== OrderStatuses.PENDING) await printOrderTicket(request as any);
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setPendingOrder(response.data);
        await printOrderTicket(response.data as any);

        updateOrder({ order: response.data });
    };

    const updatePendingOrder = async () => {
        if (hasPaymentMissing && (!posPendingOrdersEnabled || isDeliveryOrder(orderType))) {
            alert(translate('There is pending balance.'));
            return;
        }

        if (isDeliveryOrder(orderType)) requireValue(driverRequest);

        if (isCompletedOrder(pendingOrder?.orderStatus)) {
            await printOrderTicket(pendingOrder);
            return;
        }

        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        const { response, request } = await updatePosPendingOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request as any);
                notification({ message: translate('Connection error, the order will be updated when the connection is restored') });
                clearPos();
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        const updatedOrder = response.data;
        setPendingOrder(updatedOrder);
        updateOrder({ order: updatedOrder });

        if (!updatedOrder?.ticketRequestedAt && isEatHereOrder(updatedOrder.orderType)) {
            updateOrderTicketRequestedApi({ orderId: updatedOrder?.orderId });
        }

        await printOrderTicket(response.data as any);
    };

    const handlePrintOrder = async () => {
        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), cancelledItems ?? []);

        const hasPosPaymentChanges = pendingOrder?.payments?.length !== payments?.length;
        const hasItemsChanges = !!itemsToPrint.length || !!removedItemsToPrint.length;

        if (pendingOrder && !hasItemsChanges && !hasPosPaymentChanges) {
            if (!pendingOrder?.ticketRequestedAt && isEatHereOrder(pendingOrder.orderType)) {
                updateOrderTicketRequestedApi({ orderId: pendingOrder?.orderId });
            }
            return printOrderTicket(pendingOrder);
        }

        if (pendingOrder) return await updatePendingOrder();

        await savePendingOrder();
    };

    if (!existPrintersConfigured || isDeliveryOrder(orderType)) return null;

    return (
        <Button secondary larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} onClick={handlePrintOrder} disabled={disabled}>
            {translate('Print Bill')}
        </Button>
    );
});

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
