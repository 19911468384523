import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PartialRefundOngoingOrderDialog } from 'src/scenes/letseatadmin/refundOngoingOrder/PartialRefundOngoingOrderDialog';
import type { OrderVm } from 'src/types/OrderVm';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';

export function RefundOrderButton({ order, onSuccess }: Props): React.ReactElement | null {
    const [refundOngoingOrderDialogState, setRefundOngoingOrderDialogState] = useState({ open: false });

    const chargeAmount = BigNumber(order.amountWithPromoPriceDiscount)
        .plus(isCashPayment(order.paymentMethod) ? (order.marketplaceServiceFee ?? 0) : 0)
        .minus((isCashPayment(order.paymentMethod) ? order.promoCodeDiscount : order.restaurantPromoCodeCost) ?? 0)
        .minus(order.restaurantDeliveryCost ?? 0)
        .toString();

    const openRefundOngoingOrderDialog = () => setRefundOngoingOrderDialogState({ open: true });

    const closeRefundOngoingOrderDialog = () => setRefundOngoingOrderDialogState({ open: false });

    if (order.driver || order.driverId || isClosedOrder(order.orderStatus) || order.isCourtesyOrder) return null;

    return (
        <div>
            <PartialRefundOngoingOrderDialog
                open={refundOngoingOrderDialogState.open}
                orderId={order.orderId}
                maximumAmountToRefund={chargeAmount}
                handleClose={closeRefundOngoingOrderDialog}
                onSuccess={onSuccess}
            />
            <Button secondary onClick={openRefundOngoingOrderDialog}>
                {translate('Refund')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: any;
};
