import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { AssignDriverToOrderButton } from 'src/components/button/order/AssignDriverToOrderButton';
import { AssignWaiterToOrderButton } from 'src/components/button/order/AssignWaiterToOrderButton';
import { CancelOrderButton } from 'src/components/button/order/CancelOrderButton';
import { ChangeOrderDeliveryAddressButton } from 'src/components/button/order/ChangeOrderDeliveryAddressButton';
import { ChangeOrderDynamicEarningsButton } from 'src/components/button/order/ChangeOrderDynamicEarningsButton';
import { ChangeOrderPaymentMethodButton } from 'src/components/button/order/ChangeOrderPaymentMethodButton';
import { ChargeKioskOrderButton } from 'src/components/button/order/ChargeKioskOrderButton';
import { ChargeOrderButton } from 'src/components/button/order/ChargeOrderButton';
import { CompleteAndInvoiceOrderButton } from 'src/components/button/order/CompleteAndInvoiceOrderButton';
import { CompleteAndInvoiceRucUruguayanOrderButton } from 'src/components/button/order/CompleteAndInvoiceRucUruguayanOrderButton';
import { CompleteOrderButton } from 'src/components/button/order/CompleteOrderButton';
import { CreateOrderIncidentButton } from 'src/components/button/order/CreateOrderIncidentButton';
import { EditOrderButton } from 'src/components/button/order/EditOrderButton';
import { InvoiceOrderButton } from 'src/components/button/order/InvoiceOrderButton';
import { MarkOrderPickedUpButton } from 'src/components/button/order/MarkOrderPickedUpButton';
import { MarkOrderReadyForPickUpButton } from 'src/components/button/order/MarkOrderReadyForPickUpButton';
import { OpenOrderButton } from 'src/components/button/order/OpenOrderButton';
import { OrderVerificationPhotosButton } from 'src/components/button/order/OrderVerificationPhotosButton';
import { PrintOrderCommandButton } from 'src/components/button/order/PrintOrderCommandButton';
import { PrintOrderTicketButton } from 'src/components/button/order/PrintOrderTicketButton';
import { RefundOrderButton } from 'src/components/button/order/RefundOrderButton';
import { ReturnOrderButton } from 'src/components/button/order/ReturnOrderButton';
import { ShowOrderQrCodeButton } from 'src/components/button/order/ShowOrderQrCodeButton';
import { SwitchOrderToPideDirectoDeliveryButton } from 'src/components/button/order/SwitchOrderToPideDirectoDeliveryButton';
import { SwitchOrderToRappiCargoDeliveryButton } from 'src/components/button/order/SwitchOrderToRappiCargoDeliveryButton';
import { SwitchOrderToUberDaasDeliveryButton } from 'src/components/button/order/SwitchOrderToUberDaasDeliveryButton';
import { SwitchOrderToUberDirectDeliveryButton } from 'src/components/button/order/SwitchOrderToUberDirectDeliveryButton';
import { TrackRappiCargoOrderButton } from 'src/components/button/order/TrackRappiCargoOrderButton';
import { TrackUberDaasOrderButton } from 'src/components/button/order/TrackUberDaasOrderButton';
import { TrackUberOrderButton } from 'src/components/button/order/TrackUberOrderButton';
import { Dialog } from 'src/components/Dialog';
import { OrderCard } from 'src/components/orderCard/OrderCard';
import { AddTipToPosOrderButton } from 'src/components/orderCard/orderCardActions/AddTipToPosOrderButton';
import { GroupedOrderItems } from 'src/components/orderCard/orderItems/GroupedOrderItems';
import { GroupedOrderItemsByCustomer } from 'src/components/orderCard/orderItems/GroupedOrderItemsByCustomer';
import { OrderItems } from 'src/components/orderCard/orderItems/OrderItems';
import { OrderPriceInfo } from 'src/components/orderCard/OrderPriceInfo';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { WarningIcon } from 'src/icons/WarningIcon';
import { CloseOrderButton } from 'src/modules/order/components/CloseOrderButton';
import { OpenClosedOrderButton } from 'src/modules/order/components/OpenClosedOrderButton';
import { useNotification } from 'src/services/notification/useNotification';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isApiOrder } from 'src/utils/order/isApiOrder';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isManualOrder } from 'src/utils/order/isManualOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';

export function OrderDetailsDialog({ open, order, onClose, onChange, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const orderIsPickedUp = (order: OrderVm) => {
        const orderIsArrivedAtClient = moment(order.arrivedAtClientAt).add(5, 'minutes').isBefore(moment());
        const orderIsPickedUp = orderIsArrivedAtClient && !isCompletedOrder(order.orderStatus) && !isRejectedOrder(order.orderStatus) && !isCancelledOrder(order.orderStatus);

        return orderIsPickedUp;
    };

    const handleChange = (updatedOrder?: any) => {
        notification({ message: translate('The order was successfully updated') });
        onChange?.(updatedOrder);
        onClose?.();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} title={translate('Order details #@shortOrderId', { shortOrderId: order.shortOrderId })} onClose={onClose}>
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} bottom />
                <div className={classes.orderInfoContainer}>
                    <OrderCard order={order} classes={{ container: orderIsPickedUp(order) ? classesProp?.container : '' }} onChange={handleChange} />
                </div>
                <div className={classes.orderResumeContainer}>
                    <div className={classes.buttonsContainer}>
                        <OpenOrderButton order={order} />
                        <ChangeOrderDeliveryAddressButton order={order} onSuccess={handleChange} />
                        <ChangeOrderDynamicEarningsButton order={order} onSuccess={handleChange} />
                        <SwitchOrderToUberDirectDeliveryButton order={order} onUpdating={setLoading} onSuccess={handleChange} disabled={order.demoOrder} />
                        <SwitchOrderToPideDirectoDeliveryButton order={order} onUpdating={setLoading} onSuccess={handleChange} />
                        <SwitchOrderToRappiCargoDeliveryButton order={order} onUpdating={setLoading} onSuccess={handleChange} disabled={order.demoOrder} />
                        <SwitchOrderToUberDaasDeliveryButton order={order} onUpdating={setLoading} onSuccess={handleChange} disabled={order.demoOrder} />
                        <AssignDriverToOrderButton order={order} onSuccess={handleChange} />
                        <AssignWaiterToOrderButton order={order} onSuccess={handleChange} />
                        <ChangeOrderPaymentMethodButton order={order} onSuccess={handleChange} />
                        <TrackUberOrderButton order={order} />
                        <TrackUberDaasOrderButton order={order} />
                        <TrackRappiCargoOrderButton order={order} />
                        <PrintOrderTicketButton order={order} />
                        <PrintOrderCommandButton order={order} />
                        <InvoiceOrderButton order={order} onSuccess={handleChange} />
                        <ShowOrderQrCodeButton order={order} />
                        <MarkOrderReadyForPickUpButton order={order} onSuccess={handleChange} />
                        <MarkOrderPickedUpButton order={order} onSuccess={handleChange} />
                        <OrderVerificationPhotosButton order={order} />
                        <AddTipToPosOrderButton order={order} onChange={handleChange} />
                        <CompleteAndInvoiceRucUruguayanOrderButton order={order} onSuccess={handleChange} />
                        <CompleteAndInvoiceOrderButton order={order} onSuccess={handleChange} />
                        <CompleteOrderButton order={order} onSuccess={handleChange} />
                        <ChargeOrderButton order={order} />
                        <ChargeKioskOrderButton order={order} onSuccess={handleChange} />
                        <CloseOrderButton order={order} onSuccess={handleChange} />
                    </div>
                    <h2 className={classes.subtitle}>{translate('Resume')}</h2>
                    {!isManualOrder(order) && !isApiOrder(order) && (
                        <>
                            {isPosApp(order.app) && !order.customers?.length && <GroupedOrderItems orderItems={order.orderItems} cancelledItems={order.cancelledItems} />}
                            {isPosApp(order.app) && !!order.customers?.length && <GroupedOrderItemsByCustomer customers={order.customers} />}
                            {!isPosApp(order.app) && <OrderItems orderItems={order.orderItems} />}
                        </>
                    )}
                    <div className={classes.separator}></div>
                    <OrderPriceInfo order={order} />
                    <div className={classes.separator}></div>
                    {order.hasPaymentMethodChangedToCard && (
                        <div>
                            <div className={classes.clarification}>
                                <WarningIcon />
                                <span className={classes.text}>{translate('Payment method changed: it will not be included in your weekly deposit')}</span>
                            </div>
                            <div className={classes.separator}></div>
                        </div>
                    )}
                    <div className={classes.buttonsContainer}>
                        <CancelOrderButton order={order} onSuccess={handleChange} />
                        <RefundOrderButton order={order} onSuccess={handleChange} />
                        <ReturnOrderButton order={order} onSuccess={handleChange} />
                        <CreateOrderIncidentButton order={order} onSuccess={handleChange} />
                        <EditOrderButton order={order} />
                        <OpenClosedOrderButton order={order} onSuccess={handleChange} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '70vw',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
        height: '70vh',
        justifyContent: 'space-around',
        position: 'relative',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: 20,
            width: '100%',
            padding: 25,
        },
    },
    orderInfoContainer: {
        width: '50%',
        minWidth: 280,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
        },
    },
    orderResumeContainer: {
        width: '50%',
        minWidth: 280,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    dialog: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    subtitle: {
        color: '#0D3037',
        fontFamily: theme.typography.medium,
        fontSize: 18,
        marginTop: 30,
        marginBottom: 0,
    },
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #8A8F9B',
        margin: '12px 0px',
        borderStyle: 'dashed',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
        marginLeft: 10,
    },
    clarification: {
        display: 'flex',
        alignItems: 'center',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose?: any;
    onChange?: any;
    classes?: {
        container?: string;
    };
};
