import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, Radio } from '@pidedirecto/ui';
import { Form, FormEmailField, FormRadioGroup, FormSwitch, FormTextField } from '@pidedirecto/ui/form';
import { useForm, useLoadApi } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCustomerApi } from 'src/api/letseatadmin/customer/getCustomerApi';
import { changeRestaurantCustomerApi } from 'src/api/letseatadmin/restaurantCustomer/changeRestaurantCustomerApi';
import { getRestaurantCustomerApi } from 'src/api/letseatadmin/restaurantCustomer/getRestaurantCustomerApi';
import { FormCompanySelect } from 'src/components/form/FormCompanySelect';
import { FormRefundMethodSelect } from 'src/components/form/FormRefundMethodSelect';
import { RefundMethod } from 'src/constants/RefundMethod';
import { translate } from 'src/i18n/translate';
import { CompanyId, CustomerId, EmailAddress, RestaurantCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeRestaurantCustomerDialog({ open, restaurantCustomerId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [editing, setEditing] = useState(false);

    const [loadingRestaurantCustomer, restaurantCustomer] = useLoadApi(
        getRestaurantCustomerApi,
        { restaurantCustomerId: restaurantCustomerId! },
        { dependencies: [restaurantCustomerId], requiredValues: [restaurantCustomerId, open || undefined] },
    );
    const [loadingCustomer, customer] = useLoadApi(
        getCustomerApi,
        { customerId: restaurantCustomer?.customerId! },
        { dependencies: [restaurantCustomer?.customerId], requiredValues: [restaurantCustomer?.customerId, open || undefined] },
    );

    const loading = editing || loadingCustomer || loadingRestaurantCustomer;
    const lastName = customer?.lastName ?? restaurantCustomer?.lastName;

    useEffect(() => {
        form.reset({
            firstName: customer?.firstName ?? restaurantCustomer?.firstName ?? restaurantCustomer?.name,
            lastName: customer?.lastName ?? restaurantCustomer?.lastName,
            customerId: customer?.customerId,
            email: customer?.email ?? restaurantCustomer?.email,
            companyName: customer?.companyName,
            companyId: customer?.companyId,
            employeeIdAtCompany: customer?.employeeIdAtCompany,
            employeeNumberAtCompany: customer?.employeeNumberAtCompany,
            refundMethod: customer?.refundMethod,
            payrollPaymentsEnabled: !!customer?.payrollPaymentsEnabled,
            unsubscribed: !!customer?.unsubscribed || !!restaurantCustomer?.unsubscribed,
            banned: !!customer?.banned,
        });
    }, [customer, restaurantCustomer]);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const changeRestaurantCustomer = async (form: FormType) => {
        setEditing(true);
        const response = await changeRestaurantCustomerApi({
            restaurantCustomerId: restaurantCustomerId!,
            firstName: form.firstName,
            lastName: form.lastName,
            customerId: form.customerId,
            email: form.email,
            companyName: form.companyName,
            companyId: form.companyId,
            employeeIdAtCompany: form.employeeIdAtCompany,
            employeeNumberAtCompany: form.employeeNumberAtCompany,
            refundMethod: form.refundMethod,
            payrollPaymentsEnabled: form.payrollPaymentsEnabled,
            unsubscribed: form.unsubscribed,
            banned: form.banned,
        });
        setEditing(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose();
    };

    return (
        <Dialog open={open} title={translate('Edit customer')} loading={loading} onClose={handleClose} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={changeRestaurantCustomer}>
                <DialogContent className={classes.container}>
                    <div className={!!customer ? classes.formContainer : classes.formContainerRestaurantCustomer}>
                        <FormTextField
                            name='firstName'
                            label={translate('Name')}
                            required={{
                                value: true,
                                message: translate('This field is required'),
                            }}
                            disabled={!customer}
                        />
                        <FormTextField
                            name='lastName'
                            label={translate('Last Name')}
                            required={{
                                value: !!lastName,
                                message: translate('This field is required'),
                            }}
                            disabled={!customer}
                        />
                        {!!customer && <FormTextField name='customerId' label={translate('Customer Id')} required disabled />}
                        <FormEmailField name='email' label={translate('Email')} disabled={!customer} />
                        {!!customer && <FormTextField name='companyName' label={translate('Company Name')} />}
                        {!!customer && <FormCompanySelect name='companyId' label={translate('Company')} />}
                        {!!customer && <FormTextField name='employeeIdAtCompany' label={translate('Employee Id (At Company)')} />}
                        {!!customer && <FormTextField name='employeeNumberAtCompany' label={translate('Employee Number (At Company)')} />}
                    </div>
                    {!!customer && <FormRefundMethodSelect name='refundMethod' label={translate('Refund Method')} />}
                    <div className={classes.formContainer}>
                        {!!customer && <FormSwitch name='payrollPaymentsEnabled' label={translate('Payroll Payments Enabled')} />}
                        <FormSwitch name='unsubscribed' label={translate('Cancel subscription')} tooltip={translate('Cancel email and sms notifications')} />
                    </div>
                    {!!customer && (
                        <FormRadioGroup name='banned' label={translate('Customer ban')} orientation='horizontal'>
                            <Radio value={false} label='Not banned' />
                            <Radio value={true} label='Banned' />
                        </FormRadioGroup>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' disabled={loading} onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Edit')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: '100vw',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    formContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '50vw',
        gap: 10,
    },
    formContainerRestaurantCustomer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
}));

type FormType = {
    firstName: string;
    lastName: string;
    customerId: CustomerId;
    email: EmailAddress;
    companyName?: string;
    companyId?: CompanyId;
    employeeIdAtCompany?: string;
    employeeNumberAtCompany?: string;
    refundMethod?: RefundMethod;
    payrollPaymentsEnabled?: boolean;
    unsubscribed: boolean;
    banned?: boolean;
};

type Props = {
    open: boolean;
    restaurantCustomerId: RestaurantCustomerId | undefined;
    onClose: () => void;
    onSuccess?: () => void | Promise<void>;
};
