import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { Currency } from 'src/constants/Currency';
import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { PosPaymentActions } from 'src/scenes/letseatmanager/pos/posPayment/posPaymentResumeButtons/PosPaymentActions';
import { useNotification } from 'src/services/notification/useNotification';
import { useCustomPaymentMethodSelected } from 'src/services/pos/posPaymentMethods/useCustomPaymentMethodSelected';
import { usePaymentMethodSelected } from 'src/services/pos/posPaymentMethods/usePaymentMethodSelected';
import { useAddPosTip } from 'src/services/pos/useAddPosTip';
import { usePosChange } from 'src/services/pos/usePosChange';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PosPayment } from 'src/types/PosPayment';
import { PosTip } from 'src/types/PosTip';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentSummaryActions(): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();
    const addTip = useAddPosTip();
    const paymentMethod = usePaymentMethodSelected();
    const customPaymentMethod = useCustomPaymentMethodSelected();
    const change = usePosChange();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const total = useSelector((state) => state.pos.payment?.total);
    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const tips = useSelector((state) => state.pos.tips);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const customers = useSelector((state) => state.pos.customers);

    console.log('totalPaid = ', totalPaid);
    console.log('total = ', total);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const hasPaymentCompleted = BigNumber(totalPaid).isEqualTo(total);
    const hasMultiplePaymentMethods = payments?.length > 1;
    const isPaymentLinkMethodSelected = isPaymentLinkPayment(paymentMethod);
    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const hasPaymentByCustomer = payments?.some((payment: PosPayment) => payment.customerNumber);
    const hasChangeTakedAsTip = tips?.some((tip: PosTip) => tip.isTipFromChange);
    const missingPayment = BigNumber(total).minus(totalPaid).toString();
    const isCashPayment = payments?.length === 1 && isCardPayment(payments?.[0].paymentMethod);
    const hasCurrencies = payments?.reduce((currencies: Array<Currency> | Array<undefined | Currency>, payment: PosPayment) => {
        if (!payment.restaurantCurrency) {
            return [...currencies, mainCurrency];
        }

        if (currencies?.includes(payment.restaurantCurrency?.currency)) {
            return currencies;
        }

        return [...currencies, payment.restaurantCurrency?.currency];
    }, []);
    const renderTakeChangeAsTip = !hasPaymentMissing && !hasPaymentCompleted && !hasChangeTakedAsTip && !hasPaymentByCustomer && hasCurrencies?.length === 1;

    useEffect(() => {
        if (hasPaymentCompleted && !tips?.length && !isCashPayment && !customers?.length) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.ADD_TIP);
        }
    }, [hasPaymentCompleted, customers]);

    const takeChangeAsTip = () => {
        if (!paymentMethod) return notification({ message: translate('Please select a payment method.') });
        if (hasPaymentByCustomer) return notification({ message: translate('Cannot take change as tip when paying by diner') });

        if (hasMultiplePaymentMethods) return notification({ message: translate('Cannot take change as tip when paying with multiple payment methods') });

        return addTip({
            tipType: TipTypes.AMOUNT,
            tipAmount: BigNumber(totalPaid).minus(total).toFixed(2),
            restaurantCurrencyAmount: change,
            paymentMethod,
            customPaymentMethod,
            isTipFromChange: true,
            restaurantCurrency: payments[0]?.restaurantCurrency,
        });
    };

    return (
        <div className={classes.bottomSection}>
            {isPaymentLinkMethodSelected && posPaymentIsPaymentLink && (
                <div className={classes.row}>
                    <span className={classes.orderResumeText}>
                        {translate('Payment link amount')} - {formatAsCurrencyNumber(total)}
                    </span>
                </div>
            )}
            {!hasPaymentMissing && (
                <div className={classes.paymentMissingContainer} style={{ backgroundColor: 'transparent' }}>
                    <span className={classes.orderResumeTextBold}>{translate('change')}</span>
                    <span className={classes.orderResumeText}>{formatAsCurrencyNumber(change)}</span>
                </div>
            )}
            <div className={classes.paymentMissingContainer}>
                <span className={classes.orderResumeTextBold}>{translate('Payment missing')}</span>
                <span className={classes.orderResumeTextBold} style={{ color: hasPaymentMissing ? 'red' : '#222' }}>
                    {formatAsCurrencyNumber(exchangeCurrencyAmount(BigNumber(missingPayment).isLessThan(0) ? 0 : missingPayment, selectedCurrency), { currency: selectedCurrency?.currency })}
                </span>
            </div>
            {renderTakeChangeAsTip && (
                <Button text onClick={takeChangeAsTip} classes={{ button: classes.takeAsTipButton }}>
                    {translate('Take as tip')}
                </Button>
            )}
            <PosPaymentActions />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bottomSection: {
        width: '100%',
        flexShrink: 0,
    },
    secondaryAction: {
        margin: '0 0 14px 0',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        borderRadius: 6,
        height: 35,
        textTransform: 'none',
        color: '#324054',
    },
    paymentMissingContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 12,
        borderRadius: 8,
        backgroundColor: '#F6F6F6',
        paddingLeft: 12,
    },
    orderResumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    orderResumeTextBold: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        gap: 5,
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    largerButtonsContainer: {
        flexWrap: 'wrap',
    },
    contextualMenu: {
        height: 42,
        [theme.breakpoints.down('xs')]: {
            order: -1,
        },
    },
    takeAsTipContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 12,
        cursor: 'pointer',
    },
    takeAsTipButton: {
        width: '100%',
        justifyContent: 'start',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        minHeight: 'fit-content',
        padding: 0,
        color: theme.palette.text.brand,
        '&:hover': {
            backgroundColor: '#F6F6F6',
        },
    },
    largerContextualMenu: {
        height: 52,
        order: -1,
    },
}));
