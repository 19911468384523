import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormEmailField, FormTextField } from '@pidedirecto/ui/form';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { app2 } from 'src/app2';
import { FormCreditNoteTypeSelect } from 'src/components/form/FormCreditNoteTypeSelect';
import { FormFiscalEntitySelect } from 'src/components/form/FormFiscalEntitySelect';
import { FiscalEntities, FiscalEntity } from 'src/constants/FiscalEntity';
import { NubefactCreditNoteType, NubefactCreditNoteTypes } from 'src/constants/NubefactCreditNoteType';
import { translate } from 'src/i18n/translate';
import { EmailAddress } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { isBusinessFiscalEntity } from 'src/utils/invoice/fiscalEntity/isBusinessFiscalEntity';
import { isFisicFiscalEntity } from 'src/utils/invoice/fiscalEntity/isFisicFiscalEntity';
import { useAction } from 'src/utils/react/useAction';

export function CreditNoteOfCancelledOrderDialog({ open, order, onSuccess, onClose, defaultFiscalEntity }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const fiscalEntity = useWatch<FiscalEntity>({ name: 'fiscalEntity', control: form.control });

    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    useEffect(() => {
        if (isBusinessFiscalEntity(fiscalEntity)) setInvoiceLegalInfo(order);
    }, [fiscalEntity]);

    const setInvoiceLegalInfo = (order: OrderVm) => {
        form.setValue('fiscalId', order.invoice?.invoiceLegalInfo?.fiscalId);
        form.setValue('businessName', order.invoice?.invoiceLegalInfo?.businessName);
        form.setValue('address', order.invoice?.invoiceLegalInfo?.address);
        form.setValue('city', order.invoice?.invoiceLegalInfo?.city);
    };
    const handleSubmit = (creditNoteCustomerData: CreditNoteCustomerData) => {
        openRejectOrderDialog({
            orderId: order?.orderId,
            creditNoteCustomerData,
            onReject: onSuccess,
        });
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog title={translate('Create credit note')} open={open} onClose={handleClose} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form} classes={{ form: classes.form }}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormFiscalEntitySelect required name='fiscalEntity' label={translate('Select the Fiscal Entity')} defaultValue={defaultFiscalEntity ?? FiscalEntities.FISIC} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} required />
                        </Grid>

                        <Grid item xs={12}>
                            <FormCreditNoteTypeSelect required name='nubefactCreditNoteType' defaultValue={NubefactCreditNoteTypes.OTROS_CONCEPTOS} label={translate('Select the Credit Note Type')} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='fiscalId'
                                required={{
                                    value: true,
                                    message: translate('This field is required'),
                                }}
                                label={isFisicFiscalEntity(fiscalEntity) ? translate('DNI') : translate('RUC')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='businessName'
                                required={{
                                    value: true,
                                    message: translate('This field is required'),
                                }}
                                label={translate('Business Name')}
                            />
                        </Grid>
                        {isBusinessFiscalEntity(fiscalEntity) && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='address'
                                        label={translate('Address')}
                                        required={{
                                            value: true,
                                            message: translate('This field is required'),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='city'
                                        label={translate('City')}
                                        required={{
                                            value: true,
                                            message: translate('This field is required'),
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>
                <DialogActions>
                    <Button variant={'secondary'} onClick={handleClose} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' classes={{ button: classes.button }}>
                        {translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: '700px',
        overflow: 'hidden',
    },
    button: {
        width: '50%',
    },
    form: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
    defaultFiscalEntity?: FiscalEntity;
};

export type CreditNoteCustomerData = {
    nubefactCreditNoteType: NubefactCreditNoteType;
    fiscalEntity?: FiscalEntity;
    businessName?: string;
    email?: EmailAddress;
    address?: string;
    fiscalId: string;
    city?: string;
};
