import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { ResumeOrderInfoRow } from 'src/scenes/letseatmanager/orders/ResumeOrderInfoRow';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function OrdersSalesInfo({ loadingSales, sales }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const showOngoingOrdersInOrdersSalesReportEnabled = useSelector((state) => state.app.restaurant?.showOngoingOrdersInOrdersSalesReportEnabled);
    const showReturnedOrdersDeliveryCost = useSelector((state) => state.app.restaurant?.invoiceRestaurantDeliveryCost);

    const showRowInfo = (value: any) => {
        return BigNumber(value).isGreaterThan(0);
    };

    if (loadingSales && !sales) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.ALL_ORDERS_REPORTS}>
            <div className={classes.container}>
                {showRowInfo(sales.webCard) && (
                    <ResumeOrderInfoRow
                        title={translate('Web Menu Card')}
                        resumeInfo={formatAsCurrencyNumber(sales.webCard, { minimumFractionDigits: 2 })}
                        tooltipText={translate('The orders that you manually changed from payment to card will not be deposited')}
                        showTooltip={sales.hasPaymentMethodChangedToCard}
                    />
                )}
                {showRowInfo(sales.webCash) && <ResumeOrderInfoRow title={translate('Web Menu Cash')} resumeInfo={formatAsCurrencyNumber(sales.webCash, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.webCardOnDelivery) && (
                    <ResumeOrderInfoRow title={translate('Web Menu Card on delivery')} resumeInfo={formatAsCurrencyNumber(sales.webCardOnDelivery, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.webTransfer) && <ResumeOrderInfoRow title={translate('Web Menu Transfer')} resumeInfo={formatAsCurrencyNumber(sales.webTransfer, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.appCard) && <ResumeOrderInfoRow title={translate('AmbitOne App Card')} resumeInfo={formatAsCurrencyNumber(sales.appCard, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.appCash) && <ResumeOrderInfoRow title={translate('AmbitOne App Cash')} resumeInfo={formatAsCurrencyNumber(sales.appCash, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.appTransfer) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne App Transfer')} resumeInfo={formatAsCurrencyNumber(sales.appTransfer, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.kioskCard) && <ResumeOrderInfoRow title={translate('Kiosk App Card')} resumeInfo={formatAsCurrencyNumber(sales.kioskCard, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.kioskCash) && <ResumeOrderInfoRow title={translate('Kiosk App Cash')} resumeInfo={formatAsCurrencyNumber(sales.kioskCash, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.kioskTransfer) && <ResumeOrderInfoRow title={translate('Kiosk Transfer')} resumeInfo={formatAsCurrencyNumber(sales.kioskTransfer, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.appCardOnDelivery) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne App Card on delivery')} resumeInfo={formatAsCurrencyNumber(sales.appCardOnDelivery, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.marketplaceCard) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne Marketplace Card')} resumeInfo={formatAsCurrencyNumber(sales.marketplaceCard, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.marketplaceCash) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne Marketplace Cash')} resumeInfo={formatAsCurrencyNumber(sales.marketplaceCash, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.marketplaceTransfer) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne Marketplace Transfer')} resumeInfo={formatAsCurrencyNumber(sales.marketplaceTransfer, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.marketplaceCardOnDelivery) && (
                    <ResumeOrderInfoRow title={translate('AmbitOne Marketplace Card on delivery')} resumeInfo={formatAsCurrencyNumber(sales.marketplaceCardOnDelivery, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.pidedirectoCardOnDelivery) && (
                    <ResumeOrderInfoRow title={translate('Card on Delivery')} resumeInfo={formatAsCurrencyNumber(sales.pidedirectoCardOnDelivery, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.posCard) && <ResumeOrderInfoRow title={translate('In-Store Card')} resumeInfo={formatAsCurrencyNumber(sales.posCard, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.posCash) && <ResumeOrderInfoRow title={translate('In-Store Cash')} resumeInfo={formatAsCurrencyNumber(sales.posCash, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.posCardOnDelivery) && (
                    <ResumeOrderInfoRow title={translate('In-Store Card on delivery')} resumeInfo={formatAsCurrencyNumber(sales.posCardOnDelivery, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.posTransfer) && <ResumeOrderInfoRow title={translate('In-Store Transfer')} resumeInfo={formatAsCurrencyNumber(sales.posTransfer, { minimumFractionDigits: 2 })} />}
                {sales?.customPaymentMethodsSales?.map((customPaymentMethod: any, index: any) => {
                    return (
                        <ResumeOrderInfoRow key={index} title={customPaymentMethod.paymentMethod} resumeInfo={formatAsCurrencyNumber(customPaymentMethod?.total ?? 0, { minimumFractionDigits: 2 })} />
                    );
                })}
                {showRowInfo(sales.pideDirectoManagerOrders) && (
                    <ResumeOrderInfoRow title={translate('Manual Orders')} resumeInfo={formatAsCurrencyNumber(sales.pideDirectoManagerOrders, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.paymentLinks) && <ResumeOrderInfoRow title={translate('Payment Links')} resumeInfo={formatAsCurrencyNumber(sales.paymentLinks, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.paymentLinksWithoutOrder) && (
                    <ResumeOrderInfoRow title={translate('Payment Links Without Order')} resumeInfo={formatAsCurrencyNumber(sales.paymentLinksWithoutOrder, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.didiFoodOrders) && (
                    <ResumeOrderInfoRow title={translate('Didi Food Orders')} resumeInfo={formatAsCurrencyNumber(sales.didiFoodOrders, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberEatsOrders) && (
                    <ResumeOrderInfoRow title={translate('Uber Eats Orders')} resumeInfo={formatAsCurrencyNumber(sales.uberEatsOrders, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiOrders) && <ResumeOrderInfoRow title={translate('Rappi Orders')} resumeInfo={formatAsCurrencyNumber(sales.rappiOrders, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.rappiCargoWebCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Web Card')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoWebCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoWebCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Web Cash')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoWebCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoAppCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo App Card')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoAppCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoAppCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo App Cash')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoAppCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoManualOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Manual Orders')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoManualOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoPosCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Pos Card')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoPosCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoPosCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Pos Cash')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoPosCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoBringgCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Bringg Card')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoBringgCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.rappiCargoBringgCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Rappi Cargo Bringg Cash')} resumeInfo={formatAsCurrencyNumber(sales.rappiCargoBringgCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasWebCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Web Card')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasWebCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasWebCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Web Cash')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasWebCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasAppCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas App Card')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasAppCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasAppCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas App Cash')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasAppCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasManualOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Manual Orders')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasManualOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasPosCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Pos Card')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasPosCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasPosCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Pos Cash')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasPosCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasBringgCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Bringg Card')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasBringgCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasBringgCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Uber Daas Bringg Cash')} resumeInfo={formatAsCurrencyNumber(sales.uberDaasBringgCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.pedidosYaCashOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Pedidos ya Cash')} resumeInfo={formatAsCurrencyNumber(sales.pedidosYaCashOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.pedidosYaCardOrdersCompleted) && (
                    <ResumeOrderInfoRow title={translate('Pedidos ya Card')} resumeInfo={formatAsCurrencyNumber(sales.pedidosYaCardOrdersCompleted, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.uberDaasMarketplaceCardOrdersCompleted) && (
                    <ResumeOrderInfoRow
                        title={translate('Uber Daas Marketplace Card')}
                        resumeInfo={formatAsCurrencyNumber(sales.uberDaasMarketplaceCardOrdersCompleted, { minimumFractionDigits: 2 })}
                    />
                )}
                {showRowInfo(sales.uberDaasMarketplaceCashOrdersCompleted) && (
                    <ResumeOrderInfoRow
                        title={translate('Uber Daas Marketplace Cash')}
                        resumeInfo={formatAsCurrencyNumber(sales.uberDaasMarketplaceCashOrdersCompleted, { minimumFractionDigits: 2 })}
                    />
                )}
                {showRowInfo(sales.totalReturnedOrdersSales) && (
                    <ResumeOrderInfoRow title={translate('Total Returned Orders Costs')} resumeInfo={formatAsCurrencyNumber(sales.totalReturnedOrdersSales, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.total) && (
                    <ResumeOrderInfoRow title={translate('Total')} resumeInfo={formatAsCurrencyNumber(sales.total, { minimumFractionDigits: 2 })} classes={{ text: classes.textBold }} />
                )}
                {showRowInfo(sales.totalIva) && <ResumeOrderInfoRow title={translate('Total IVA')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />}
                {showRowInfo(sales.totalIsr) && <ResumeOrderInfoRow title={translate('Total ISR')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />}
                {showRowInfo(sales.totalIeps) && <ResumeOrderInfoRow title={translate('Total IEPS')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />}
                {showRowInfo(sales.totalIvaRet) && <ResumeOrderInfoRow title={translate('Total IVA RET')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />}
                {showRowInfo(sales.totalIvaExento) && (
                    <ResumeOrderInfoRow title={translate('Total IVA EXENTO')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />
                )}
                {showRowInfo(sales.totalIvaTasaBasica) && (
                    <ResumeOrderInfoRow title={translate('Total IVA TASA BASICA')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />
                )}
                {showRowInfo(sales.totalIvaTasaMinima) && (
                    <ResumeOrderInfoRow title={translate('Total IVA TASA MINIMA')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />
                )}
                {showRowInfo(sales.totalTax) && <ResumeOrderInfoRow title={translate('Total Tax')} resumeInfo={formatAsCurrencyNumber(sales.total)} classes={{ text: classes.textBold }} />}
                {showRowInfo(sales.numberOfPidedirectoOrders) && <ResumeOrderInfoRow title={translate('Total AmbitOne Orders')} resumeInfo={sales.numberOfPidedirectoOrders} />}
                {showRowInfo(sales.totalDeliveryCosts) && (
                    <ResumeOrderInfoRow
                        title={translate('Total Delivery Costs')}
                        resumeInfo={formatAsCurrencyNumber(sales.totalDeliveryCosts, { minimumFractionDigits: 2 })}
                        classes={{ text: classes.textBold }}
                    />
                )}
                {showRowInfo(sales.totalDeliveryCashHandlingFee) && (
                    <ResumeOrderInfoRow
                        title={translate('Total cash fee')}
                        resumeInfo={formatAsCurrencyNumber(sales.totalDeliveryCashHandlingFee, { minimumFractionDigits: 2 })}
                        classes={{ text: classes.textBold }}
                    />
                )}
                {showRowInfo(sales.totalRestaurantDeliveryCost) && (
                    <ResumeOrderInfoRow title={translate('Delivery costs paid by restaurant')} resumeInfo={formatAsCurrencyNumber(sales.totalRestaurantDeliveryCost, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.totalCustomerDeliveryCost) && (
                    <ResumeOrderInfoRow title={translate('Total Customer Delivery Costs')} resumeInfo={formatAsCurrencyNumber(sales.totalCustomerDeliveryCost, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.totalPideDirectoDeliveryCost) && (
                    <ResumeOrderInfoRow title={translate('Total Pidedirecto Delivery Costs')} resumeInfo={formatAsCurrencyNumber(sales.totalPideDirectoDeliveryCost, { minimumFractionDigits: 2 })} />
                )}
                {showRowInfo(sales.driverTips) && <ResumeOrderInfoRow title={translate('Driver tips')} resumeInfo={formatAsCurrencyNumber(sales.driverTips, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.discounts) && <ResumeOrderInfoRow title={translate('Discounts')} resumeInfo={formatAsCurrencyNumber(sales.discounts, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.posTips) && <ResumeOrderInfoRow title={translate('Tips')} resumeInfo={formatAsCurrencyNumber(sales.posTips, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.credits) && <ResumeOrderInfoRow title={translate('Credits')} resumeInfo={formatAsCurrencyNumber(sales.credits, { minimumFractionDigits: 2 })} />}
                {showRowInfo(sales.numberOfUberEatsOrders) && <ResumeOrderInfoRow title={translate('Total Uber Eats Orders')} resumeInfo={sales.numberOfUberEatsOrders} />}
                {showRowInfo(sales.numberOfUberDaasOrders) && <ResumeOrderInfoRow title={translate('Total Uber Daas Orders')} resumeInfo={sales.numberOfUberDaasOrders} />}
                {showRowInfo(sales.numberOfDidiFoodOrders) && <ResumeOrderInfoRow title={translate('Total Didi Food Orders')} resumeInfo={sales.numberOfDidiFoodOrders} />}
                {showRowInfo(sales.numberOfRappiOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Orders')} resumeInfo={sales.numberOfRappiOrders} />}
                {showRowInfo(sales.numberOfRappiCargoOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Cargo Orders')} resumeInfo={sales.numberOfRappiCargoOrders} />}
                {showRowInfo(sales.numberOfPedidosYaOrders) && <ResumeOrderInfoRow title={translate('Total Pedidos ya  Orders')} resumeInfo={sales.numberOfPedidosYaOrders} />}
                {showRowInfo(sales.courtesyOrders) && <ResumeOrderInfoRow title={translate('Total Courtesy orders Orders')} resumeInfo={sales.courtesyOrders} />}
                {showRowInfo(sales.returnedOrders) && <ResumeOrderInfoRow title={translate('Total Returned Orders')} resumeInfo={sales.returnedOrders} />}
                {showRowInfo(sales.totalReturnedOrdersDeliveryCosts) && showReturnedOrdersDeliveryCost && (
                    <ResumeOrderInfoRow title={translate('Total Returned Orders Delivery Costs')} resumeInfo={formatAsCurrencyNumber(sales.totalReturnedOrdersDeliveryCosts)} />
                )}
            </div>
            {showOngoingOrdersInOrdersSalesReportEnabled && showRowInfo(sales.ongoingOrdersTotal) && (
                <div className={classes.additionalInfoContainer}>
                    <Text variant='subtitle'>{translate('Pendings')}</Text>
                    {showRowInfo(sales.ongoingOrdersTotal) && <ResumeOrderInfoRow title={translate('Total')} resumeInfo={formatAsCurrencyNumber(sales.ongoingOrdersTotal)} />}
                    {showRowInfo(sales.numberOfOngoingPidedirectoOrders) && <ResumeOrderInfoRow title={translate('Total AmbitOne Orders')} resumeInfo={sales.numberOfOngoingPidedirectoOrders} />}
                    {showRowInfo(sales.numberOfOngoingUberEatsOrders) && <ResumeOrderInfoRow title={translate('Total Uber Eats Orders')} resumeInfo={sales.numberOfOngoingUberEatsOrders} />}
                    {showRowInfo(sales.numberOfOngoingUberDaasOrders) && <ResumeOrderInfoRow title={translate('Total Uber Daas Orders')} resumeInfo={sales.numberOfOngoingUberDaasOrders} />}
                    {showRowInfo(sales.numberOfOngoingDidiFoodOrders) && <ResumeOrderInfoRow title={translate('Total Didi Food Orders')} resumeInfo={sales.numberOfOngoingDidiFoodOrders} />}
                    {showRowInfo(sales.numberOfOngoingRappiOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Orders')} resumeInfo={sales.numberOfOngoingRappiOrders} />}
                    {showRowInfo(sales.numberOfOngoingRappiCargoOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Cargo Orders')} resumeInfo={sales.numberOfOngoingRappiCargoOrders} />}
                    {showRowInfo(sales.numberOfOngoingPedidosYaOrders) && <ResumeOrderInfoRow title={translate('Total Pedidos ya  Orders')} resumeInfo={sales.numberOfOngoingPedidosYaOrders} />}
                </div>
            )}
            {showOngoingOrdersInOrdersSalesReportEnabled && showRowInfo(sales.expectedTotal) && (
                <div className={classes.additionalInfoContainer}>
                    <Text variant='subtitle'>{translate('Totals')}</Text>
                    {showRowInfo(sales.expectedTotal) && <ResumeOrderInfoRow title={translate('Total')} resumeInfo={formatAsCurrencyNumber(sales.expectedTotal)} />}
                    {showRowInfo(sales.expectedNumberOfPidedirectoOrders) && <ResumeOrderInfoRow title={translate('Total AmbitOne Orders')} resumeInfo={sales.expectedNumberOfPidedirectoOrders} />}
                    {showRowInfo(sales.expectedNumberOfUberEatsOrders) && <ResumeOrderInfoRow title={translate('Total Uber Eats Orders')} resumeInfo={sales.expectedNumberOfUberEatsOrders} />}
                    {showRowInfo(sales.expectedNumberOfUberDaasOrders) && <ResumeOrderInfoRow title={translate('Total Uber Daas Orders')} resumeInfo={sales.expectedNumberOfUberDaasOrders} />}
                    {showRowInfo(sales.expectedNumberOfDidiFoodOrders) && <ResumeOrderInfoRow title={translate('Total Didi Food Orders')} resumeInfo={sales.expectedNumberOfDidiFoodOrders} />}
                    {showRowInfo(sales.expectedNumberOfRappiOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Orders')} resumeInfo={sales.expectedNumberOfRappiOrders} />}
                    {showRowInfo(sales.expectedNumberOfRappiCargoOrders) && <ResumeOrderInfoRow title={translate('Total Rappi Cargo Orders')} resumeInfo={sales.expectedNumberOfRappiCargoOrders} />}
                    {showRowInfo(sales.expectedNumberOfPedidosYaOrders) && <ResumeOrderInfoRow title={translate('Total Pedidos ya  Orders')} resumeInfo={sales.expectedNumberOfPedidosYaOrders} />}
                </div>
            )}
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    additionalInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        marginTop: 10,
    },
    textBold: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
}));
type Props = {
    loadingSales: boolean;
    sales: any;
};
