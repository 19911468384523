import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { type OrderId } from 'src/types/Id';
import { OrderItemVm, OrderVm } from 'src/types/OrderVm';

export async function separateOrderApi(request: SeparateOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('pos/separateOrderApi', request);
}

export type SeparateOrderApiRequest = {
    orderId: OrderId;
    orderItemsToSeparate: Array<OrderItemVm>;
    orderItems: Array<OrderItemVm>;
    firstName?: string;
    lastName?: string;
};
