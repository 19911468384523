import { makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReceiptIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { Channel } from 'src/components/Channel';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { SimplifiedOrderCard } from 'src/components/orderCard/SimplifiedOrderCard';
import { SimpleTable } from 'src/components/SimpleTable';
import { Apps } from 'src/constants/App';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { useOrderPageIsTableView } from 'src/modules/order/useOrderPageIsTableView';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';

export function EatHereOrders({ orders, orderStatuses, onChangeOrder }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const isBelowSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });

    const pendingOrders = orders.filter((order) => isPendingOrder(order.orderStatus) || isClosedOrder(order.orderStatus));
    const completedOrders = orders.filter((order) => isCompletedOrder(order.orderStatus));
    const cancelledOrders = orders.filter((order) => isCancelledOrder(order.orderStatus));
    const rejectedOrders = orders.filter((order) => isRejectedOrder(order.orderStatus));
    const renderPendingOrders = orderStatuses.includes(OrderStatuses.PENDING);
    const renderCompletedOrders = orderStatuses.includes(OrderStatuses.COMPLETE);
    const renderCancelledOrders = orderStatuses.includes(OrderStatuses.CANCELLED);
    const renderRejectedOrders = orderStatuses.includes(OrderStatuses.REJECTED);
    const isTableView = useOrderPageIsTableView();
    const renderTableView = !isBelowSmallScreen && isTableView;

    const openOrderDetailsDialog = (order: OrderVm) => setOrderDetailsDialogState({ open: true, order });

    const closeOrderDetailsDialog = () => setOrderDetailsDialogState({ open: false, order: undefined });

    const columns = [
        {
            id: 'app',
            content: translate('Channel'),
            size: 0.5,
        },
        {
            id: 'id',
            content: translate('Id'),
            size: 0.7,
        },
        {
            id: 'customerName',
            content: translate('Customer'),
        },
        {
            id: 'createdAt',
            content: translate('Time'),
        },
        {
            id: 'paymentMethod',
            content: translate('Payment method'),
            size: 0.8,
        },
        {
            id: 'price',
            content: translate('Price'),
            size: 0.4,
        },
    ];

    const getPaymentMethodLabel = (order: OrderVm) => {
        if (order.isCourtesyOrder) return translate('Courtesy');
        if (!order.paymentMethod) return translate('');

        return translate(`PaymentMethods.${order.paymentMethod}`);
    };

    return (
        <div className={classes.container}>
            {!!orderDetailsDialogState.order && (
                <OrderDetailsDialog open={orderDetailsDialogState.open} order={orderDetailsDialogState.order} onClose={closeOrderDetailsDialog} onChange={onChangeOrder} />
            )}
            {renderPendingOrders && (
                <Accordion title={translate('Opened (@orders)', { orders: pendingOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={pendingOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: getPaymentMethodLabel(order),
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer} style={{ display: !isBelowSmallScreen && isTableView ? 'none' : 'grid' }}>
                            {pendingOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderCompletedOrders && (
                <Accordion title={translate('Completed (@orders)', { orders: completedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={completedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                            {order.invoiced && <ReceiptIcon size={22} title={translate('Order invoiced')} />}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: getPaymentMethodLabel(order),
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer} style={{ display: !isBelowSmallScreen && isTableView ? 'none' : 'grid' }}>
                            {completedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderCancelledOrders && (
                <Accordion title={translate('Cancelled (@orders)', { orders: cancelledOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={cancelledOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: getPaymentMethodLabel(order),
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer} style={{ display: !isBelowSmallScreen && isTableView ? 'none' : 'grid' }}>
                            {cancelledOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderRejectedOrders && (
                <Accordion title={translate('Rejected (@orders)', { orders: rejectedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={rejectedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: getPaymentMethodLabel(order),
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer} style={{ display: !isBelowSmallScreen && isTableView ? 'none' : 'grid' }}>
                            {rejectedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBottom: 30,
    },
    tableContainer: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    ordersContainer: {
        width: '100%',
        marginTop: 20,
        display: 'grid',
        gap: 20,
        gridTemplateColumns: 'repeat(auto-fill, 350px)',
    },
    link: {
        color: '#198074',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    tableCell: {
        alignItems: 'center',
    },
    orderType: {
        color: 'white',
        padding: '4px 12px',
        borderRadius: 4,
    },
}));

type Props = {
    orders: Array<OrderVm>;
    orderStatuses: Array<OrderStatus>;
    onChangeOrder?: any;
};
