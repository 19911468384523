import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { TipType } from 'src/constants/TipType';
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import {
    OrderId,
    PromotionId,
    type CashRegisterId,
    type CashRegisterPosBusinessDayId,
    type CustomerId,
    type DeliveryEstimateId,
    type GooglePlaceId,
    type ManagerUserId,
    type PosBusinessDayId,
    type PromoCodeId,
    type RestaurantId,
    type RestaurantZoneId,
} from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTipVm } from 'src/types/PosTipVm';

export async function savePosOrderApi(request: SavePosOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('pos/savePosOrderApi', request);
}

export type SavePosOrderApiRequest = {
    orderId: OrderId;
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    promoCodeId?: PromoCodeId;
    restaurantZoneId?: RestaurantZoneId;
    cashRegisterId?: CashRegisterId;
    posBusinessDayId?: PosBusinessDayId;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    waiterId?: ManagerUserId;
    promotionIds?: Array<PromotionId>;
    orderType: OrderType;
    orderStatus: 'COMPLETE' | 'PENDING' | 'NEW';
    kitchenStatus?: 'PREPARING' | 'READY';
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    address?: {
        location: GeoJsonPoint;
        street: string;
        number: string;
        neighborhood: string;
        postalCode?: string;
        other?: string;
        instructions?: string;
        googlePlaceId: GooglePlaceId;
        formattedAddress?: string;
        name?: string; // sent for debugging reasons
    };
    deliveryEstimateId?: DeliveryEstimateId;
    deliveryPaidByRestaurant?: boolean;
    deliveryPaidByPideDirecto?: boolean;
    deliveryCost?: string;
    paymentMethod: PaymentMethod;
    posPayments: Array<PosPaymentVm>;
    payments: Array<OrderPaymentVm>;
    partiallyPaidByCash?: boolean;
    partiallyPaidByCashAmount?: string;
    table?: string;
    notes?: string;
    numberOfCustomers?: number;
    pickupTimeType?: PickupTimeType;
    orderItems: Array<OrderItemVm>;
    cancelledItems: Array<OrderItemVm>;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string | CustomDiscountComment;
    };
    isCourtesyOrder?: boolean;
    posDiscountPercentage?: string;
    posTip?: {
        tip: string;
        tipType: TipType;
    };
    tips?: Array<PosTipVm>;
    uruguayanRucInvoice?: boolean;
    useLetsEatCredits?: boolean;
    usedCustomerCredits?: string;
    customers?: Array<CustomerOrderDetailsVm>;
    courses?: Array<CourseOrderDetailsVm>;
    pickupTime?: Date;
    createdAt?: Date;
    completedAt?: Date;
};
