import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { createSlice } from 'redux-starter-kit';
import { KitchenOrdersSummaryVm } from 'src/api/letseatmanager/kitchen/getKitchenOrdersApi';
import type { IntegrationMenuApprovalVm } from 'src/api/letseatmanager/order/realTimeUpdatesApi';
import { CreditNoteCustomerData } from 'src/components/dialog/order/CreditNoteOfCancelledOrderDialog';
import type { CardPriority } from 'src/constants/CardPriority';
import type { City } from 'src/constants/City';
import { CostType, CostTypes } from 'src/constants/CostType';
import type { MenuItemType } from 'src/constants/DeprecatedMenuItemType';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import { FilterReportType, FilterReportTypes } from 'src/constants/FilterReportType';
import { OrderStatuses } from 'src/constants/OrderStatus';
import type { PrintType } from 'src/constants/PrintType';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { UberEatsStoreOfflineReason } from 'src/constants/UberEatsStoreOfflineReason';
import { translate } from 'src/i18n/translate';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { AppActions } from 'src/types/AppActions';
import type { AppVm } from 'src/types/AppVm';
import type { ManagerMenuItemVm as DeprecatedManagerMenuItemVm } from 'src/types/DeprecatedManagerMenuItemVm';
import type { DeliveryId, DriverId, EmailTemplateId, FaqId, ManagerUserId, MenuCategoryId, MenuId, OrderId, PickupStationId, RestaurantId, ScheduledTaskId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { MenuVm } from 'src/types/MenuVm';
import type { MinimumSupplyLimit } from 'src/types/MinimumSupplyLimit';
import type { OrderVm } from 'src/types/OrderVm';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import type { PrinterVm } from 'src/types/PrinterVm';
import { deepEqual } from 'src/utils/object/deepEqual';
import { isWebSoundPermissionAllowed } from 'src/utils/sound/isSoundEnabled';

export const app2 = createSlice<AppVm, AppActions>({
    name: 'app2',
    initialState: {
        settings: {},
        webSoundPermissionAllowed: isWebSoundPermissionAllowed(),
        appMenuOpen: false,
        title: translate('Welcome'),
        city: undefined,
        cities: undefined,
        managerMessageStyle: undefined,
        managerMessageTitle: undefined,
        managerMessageBody: undefined,
        remainingDays: undefined,
        subscriptionAccess: undefined,
        subscriptionPlan: undefined,
        subscriptionStatus: undefined,
        didiFoodStoreOnline: undefined,
        pedidosYaRestaurantOnline: undefined,
        uberEatsStoreOnline: undefined,
        uberEatsStoreOfflineReason: undefined,
        uberEatsRdOptionalDisabled: undefined,
        uberEatsStorePausedByUber: undefined,
        rappiStoreOnline: undefined,
        storeShouldBeOpen: false,
        alertStoresClosed: false,
        rejectOrderDialog: { open: false },
        cancelDeliveryDialog: { open: false },
        restartDeliveryManagerDialog: { open: false },
        completeDeliveryManagerDialog: { open: false },
        cancelDeliveryManagerDialog: { open: false },
        changePickupStationsDialog: { open: false },
        createScheduledTaskDialog: { open: false },
        changeScheduledTaskDialog: { open: false },
        createEmailTemplateDialog: { open: false },
        changeEmailTemplateDialog: { open: false },
        createFaqDialog: { open: false },
        changeFaqDialog: { open: false },
        createManualOrderDeliveryDialog: { open: false },
        copyRestaurantMenuDialog: { open: false },
        importRestaurantMenuDialog: { open: false },
        createRestaurantMenuPromotionDialog: { open: false },
        createMenuCategoryDialog: { open: false },
        removeMenuCategoryDialog: { open: false },
        changeMenuCategoryDialog: { open: false },
        createMenuDialog: { open: false },
        removeMenuDialog: { open: false },
        changeMenuDialog: { open: false },
        createMenuItemDialog: { open: false },
        changeMenuItemDialog: { open: false },
        removeMenuItemDialog: { open: false },
        moveMenuItemToMenuDialog: { open: false },
        hideMenuItemDialog: { open: false },
        incomingOrdersAlertState: { open: false },
        minimumInventoryReachedAlertState: { open: false },
        noInternetConnectionDialog: { open: false },
        addNewCardDialog: { open: false },
        verifyPosPasswordDialog: { open: false },
        verifyPosPinCodeUserDialog: { open: false },
        integrationMenuChangesApprovalDialog: { open: false },
        submittingOrders: {},
        currentOrders: [],
        preparingKitchenOrders: [],
        preparedKitchenOrders: [],
        kitchenOrdersSummary: undefined,
        theMenuCategoryIdSelected: undefined,
        online: true,
        timesFetchRealtimeUpdatesFailed: 0,
        quickActionMessage: '',
        selectPrinterDialog: {
            printerInstructions: undefined,
            header: undefined,
            error: undefined,
            printType: undefined,
            open: false,
            onSelectPrinter: () => {},
        },
        selectPaymentTerminalDialog: {
            header: undefined,
            error: undefined,
            amount: undefined,
            open: false,
            onSelectPaymentTerminal: () => {},
            onCloseDialog: () => {},
        },
        dateSelectDialogState: {
            open: false,
        },
        filterReportsState: {
            currentRestaurantId: undefined,
            filterReportType: FilterReportTypes.WEEK,
            period: {
                from: moment().subtract(7, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            },
            costType: CostTypes.COST_FIXED,
        },
        zendeskJwt: undefined,
        currentEmployeesClockedIn: undefined,
    },
    reducers: {
        setPeriodOfReports: (state: AppVm, { payload: period }: { payload: { to: string; from: string } }) => {
            state.filterReportsState.period = period;
        },

        setFilterReportType: (state: AppVm, { payload: filterReportType }: { payload: FilterReportType }) => {
            state.filterReportsState.filterReportType = filterReportType;
        },

        setCurrentRestaurantId: (state: AppVm, { payload: restaurantId }: { payload: RestaurantId }) => {
            state.filterReportsState.currentRestaurantId = restaurantId;
        },

        setReportsCostType: (state: AppVm, { payload: costType }: { payload: CostType }) => {
            state.filterReportsState.costType = costType;
        },

        setSettings: (state: AppVm, { payload: settings }: { payload: any }) => {
            state.settings = settings;
        },

        openDateSelectDialog: (state: AppVm, { payload: { open } }: { payload: { open: boolean } }) => {
            state.dateSelectDialogState.open = open;
        },

        closeDateSelectDialog: (state: AppVm) => {
            state.dateSelectDialogState.open = false;
        },

        closeRestaurant: (state: AppVm) => {
            state.submittingOrders = {};
            state.currentOrders = [];
            state.preparingKitchenOrders = [];
            state.preparedKitchenOrders = [];
            state.kitchenOrdersSummary = undefined;
        },

        openAppMenu: (state: AppVm) => {
            state.appMenuOpen = true;
        },

        setTitle: (state: AppVm, { payload: title }: { payload: string }) => {
            state.title = title;
        },

        closeAppMenu: (state: AppVm) => {
            state.appMenuOpen = false;
        },

        setCity: (state: AppVm, { payload: city }: { payload: City }) => {
            state.city = city;
        },

        setCities: (state: AppVm, { payload: cities }: { payload: Array<City> }) => {
            state.cities = cities;
        },

        setUberEatsStoreOnline: (state: AppVm, { payload: uberEatsStoreOnline }: { payload: boolean }) => {
            state.uberEatsStoreOnline = uberEatsStoreOnline;
        },

        setUberEatsStoreOfflineReason: (state: AppVm, { payload: uberEatsStoreOfflineReason }: { payload: UberEatsStoreOfflineReason }) => {
            state.uberEatsStoreOfflineReason = uberEatsStoreOfflineReason;
        },

        setUberEatsRdOptionalDisabled: (state: AppVm, { payload: uberEatsRdOptionalDisabled }: { payload: boolean }) => {
            state.uberEatsRdOptionalDisabled = uberEatsRdOptionalDisabled;
        },

        setUberEatsStorePausedByUber: (state: AppVm, { payload: uberEatsStorePausedByUber }: { payload: boolean }) => {
            state.uberEatsStorePausedByUber = uberEatsStorePausedByUber;
        },

        setRappiStoreOnline: (state: AppVm, { payload: rappiStoreOnline }: { payload: boolean }) => {
            state.rappiStoreOnline = rappiStoreOnline;
        },

        setDidiFoodStoreOnline: (state: AppVm, { payload: didiFoodStoreOnline }: { payload: boolean }) => {
            state.didiFoodStoreOnline = didiFoodStoreOnline;
        },

        setPedidosYaRestaurantOnline: (state: AppVm, { payload: pedidosYaRestaurantOnline }: { payload: boolean }) => {
            state.pedidosYaRestaurantOnline = pedidosYaRestaurantOnline;
        },

        setAlertStoresClosed: (state: AppVm, { payload: { alertStoresClosed } }: { payload: { alertStoresClosed?: boolean } }) => {
            state.alertStoresClosed = alertStoresClosed;
        },

        openRejectOrderDialog: (
            state: AppVm,
            {
                payload: { orderId, onReject, refundSupplies, creditNoteCustomerData },
            }: { payload: { orderId: OrderId; onReject: any; refundSupplies: boolean; creditNoteCustomerData: CreditNoteCustomerData } },
        ) => {
            state.rejectOrderDialog.open = true;
            state.rejectOrderDialog.orderId = orderId;
            state.rejectOrderDialog.onReject = onReject;
            state.rejectOrderDialog.refundSupplies = refundSupplies;
            state.rejectOrderDialog.creditNoteCustomerData = creditNoteCustomerData;
        },

        closeRejectOrderDialog: (state: AppVm) => {
            state.rejectOrderDialog.open = false;
            state.rejectOrderDialog.orderId = undefined;
            state.rejectOrderDialog.onReject = undefined;
            state.rejectOrderDialog.refundSupplies = undefined;
            state.rejectOrderDialog.creditNoteCustomerData = undefined;
        },

        openCancelDeliveryDialog: (state: AppVm, { payload: { deliveryId, onSuccess, driverId } }: { payload: { deliveryId: DeliveryId; onSuccess: any; driverId: DriverId } }) => {
            state.cancelDeliveryDialog.open = true;
            state.cancelDeliveryDialog.deliveryId = deliveryId;
            state.cancelDeliveryDialog.onSuccess = onSuccess;
            state.cancelDeliveryDialog.driverId = driverId;
        },

        closeCancelDeliveryDialog: (state: AppVm) => {
            state.cancelDeliveryDialog.open = false;
            state.cancelDeliveryDialog.deliveryId = undefined;
            state.cancelDeliveryDialog.onSuccess = undefined;
        },

        openRestartDeliveryManagerDialog: (state: AppVm, { payload: { orderId, onSuccess } }: { payload: { orderId: OrderId; onSuccess: any } }) => {
            state.restartDeliveryManagerDialog.open = true;
            state.restartDeliveryManagerDialog.orderId = orderId;
            state.restartDeliveryManagerDialog.onSuccess = onSuccess;
        },

        closeRestartDeliveryManagerDialog: (state: AppVm) => {
            state.restartDeliveryManagerDialog.open = false;
            state.restartDeliveryManagerDialog.orderId = undefined;
            state.restartDeliveryManagerDialog.onSuccess = undefined;
        },

        openCompleteDeliveryManagerDialog: (state: AppVm, { payload: { orderId, onSuccess } }: { payload: { orderId: OrderId; onSuccess: any } }) => {
            state.completeDeliveryManagerDialog.open = true;
            state.completeDeliveryManagerDialog.orderId = orderId;
            state.completeDeliveryManagerDialog.onSuccess = onSuccess;
        },

        closeCompleteDeliveryManagerDialog: (state: AppVm) => {
            state.completeDeliveryManagerDialog.open = false;
            state.completeDeliveryManagerDialog.orderId = undefined;
            state.completeDeliveryManagerDialog.onSuccess = undefined;
        },

        openCancelDeliveryManagerDialog: (state: AppVm, { payload: { orderId, onSuccess } }: { payload: { orderId: OrderId; onSuccess: any } }) => {
            state.cancelDeliveryManagerDialog.open = true;
            state.cancelDeliveryManagerDialog.orderId = orderId;
            state.cancelDeliveryManagerDialog.onSuccess = onSuccess;
        },

        closeCancelDeliveryManagerDialog: (state: AppVm) => {
            state.cancelDeliveryManagerDialog.open = false;
            state.cancelDeliveryManagerDialog.orderId = undefined;
            state.cancelDeliveryManagerDialog.onSuccess = undefined;
        },

        openChangePickupStationsDialog: (state: AppVm, { payload: { pickupStationIds, onSuccess } }: { payload: { pickupStationIds: Array<PickupStationId>; onSuccess: any } }) => {
            state.changePickupStationsDialog.open = true;
            state.changePickupStationsDialog.pickupStationIds = pickupStationIds;
            state.changePickupStationsDialog.onSuccess = onSuccess;
        },

        closeChangePickupStationsDialog: (state: AppVm) => {
            state.changePickupStationsDialog.open = false;
            state.changePickupStationsDialog.pickupStationIds = undefined;
            state.changePickupStationsDialog.onSuccess = undefined;
        },

        openCreateScheduledTaskDialog: (state: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.createScheduledTaskDialog.open = true;
            state.createScheduledTaskDialog.onSuccess = onSuccess;
        },

        closeCreateScheduledTaskDialog: (state: AppVm) => {
            state.createScheduledTaskDialog.open = false;
            state.createScheduledTaskDialog.onSuccess = undefined;
        },

        openChangeScheduledTaskDialog: (state: AppVm, { payload: { scheduledTaskId, onSuccess } }: { payload: { scheduledTaskId: ScheduledTaskId; onSuccess: any } }) => {
            state.changeScheduledTaskDialog.open = true;
            state.changeScheduledTaskDialog.scheduledTaskId = scheduledTaskId;
            state.changeScheduledTaskDialog.onSuccess = onSuccess;
        },

        closeChangeScheduledTaskDialog: (state: AppVm) => {
            state.changeScheduledTaskDialog.open = false;
            state.changeScheduledTaskDialog.scheduledTaskId = undefined;
            state.changeScheduledTaskDialog.onSuccess = undefined;
        },

        openCreateEmailTemplateDialog: (state: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.createEmailTemplateDialog.open = true;
            state.createEmailTemplateDialog.onSuccess = onSuccess;
        },

        closeCreateEmailTemplateDialog: (state: AppVm) => {
            state.createEmailTemplateDialog.open = false;
            state.createEmailTemplateDialog.onSuccess = undefined;
        },

        openCreateFaqDialog: (state: AppVm, { payload: { faqId, onSuccess } }: { payload: { faqId: FaqId; onSuccess: any } }) => {
            state.createFaqDialog.open = true;
            state.createFaqDialog.onSuccess = onSuccess;
        },

        closeCreateFaqDialog: (state: AppVm) => {
            state.createFaqDialog.open = false;
            state.createFaqDialog.onSuccess = undefined;
        },

        openChangeFaqDialog: (state: AppVm, { payload: { faqId, onSuccess } }: { payload: { faqId: FaqId; onSuccess: any } }) => {
            state.changeFaqDialog.open = true;
            state.changeFaqDialog.faqId = faqId;
            state.changeFaqDialog.onSuccess = onSuccess;
        },

        closeChangeFaqDialog: (state: AppVm) => {
            state.changeFaqDialog.open = false;
            state.changeFaqDialog.faqId = undefined;
            state.changeFaqDialog.onSuccess = undefined;
        },

        openCreateManualOrderDeliveryDialog: (state: AppVm, { payload: { onSuccess, isForPos } }: { payload: { onSuccess: any; isForPos?: boolean } }) => {
            state.createManualOrderDeliveryDialog.open = true;
            state.createManualOrderDeliveryDialog.isForPos = isForPos;
            state.createManualOrderDeliveryDialog.onSuccess = onSuccess;
        },

        closeCreateManualOrderDeliveryDialog: (state: AppVm) => {
            state.createManualOrderDeliveryDialog.open = false;
            state.createManualOrderDeliveryDialog.onSuccess = undefined;
        },

        openSelectPrinterDialog: (
            state: AppVm,
            {
                payload: { printerInstructions, header, error, onSelectPrinter, printType },
            }: { payload: { printerInstructions?: Array<PrinterInstruction>; header?: string; error?: string; printType?: PrintType; onSelectPrinter: (arg1: PrinterVm) => any } },
        ) => {
            state.selectPrinterDialog.printerInstructions = printerInstructions;
            state.selectPrinterDialog.header = header;
            state.selectPrinterDialog.error = error;
            state.selectPrinterDialog.printType = printType;
            state.selectPrinterDialog.open = true;
            state.selectPrinterDialog.onSelectPrinter = onSelectPrinter;
        },

        closeSelectPrinterDialog: (state: AppVm) => {
            state.selectPrinterDialog.printerInstructions = undefined;
            state.selectPrinterDialog.header = undefined;
            state.selectPrinterDialog.error = undefined;
            state.selectPrinterDialog.printType = undefined;
            state.selectPrinterDialog.open = false;
            state.selectPrinterDialog.onSelectPrinter = () => {};
        },

        openSelectPaymentTerminalDialog: (
            state: AppVm,
            {
                payload: { header, error, amount, onSelectPaymentTerminal, onCloseDialog },
            }: { payload: { header?: string; error?: string; amount: string; onSelectPaymentTerminal: (arg1: PaymentTerminalVm) => any; onCloseDialog: () => void } },
        ) => {
            state.selectPaymentTerminalDialog.header = header;
            state.selectPaymentTerminalDialog.error = error;
            state.selectPaymentTerminalDialog.amount = amount;
            state.selectPaymentTerminalDialog.open = true;
            state.selectPaymentTerminalDialog.onSelectPaymentTerminal = onSelectPaymentTerminal;
            state.selectPaymentTerminalDialog.onCloseDialog = onCloseDialog;
        },

        closeSelectPaymentTerminalDialog: (state: AppVm) => {
            state.selectPaymentTerminalDialog.header = undefined;
            state.selectPaymentTerminalDialog.error = undefined;
            state.selectPaymentTerminalDialog.amount = undefined;
            state.selectPaymentTerminalDialog.open = false;
            state.selectPaymentTerminalDialog.onSelectPaymentTerminal = () => {};
            state.selectPaymentTerminalDialog.onCloseDialog = () => {};
        },

        openChangeEmailTemplateDialog: (state: AppVm, { payload: { emailTemplateId, onSuccess } }: { payload: { emailTemplateId: EmailTemplateId; onSuccess: any } }) => {
            state.changeEmailTemplateDialog.open = true;
            state.changeEmailTemplateDialog.emailTemplateId = emailTemplateId;
            state.changeEmailTemplateDialog.onSuccess = onSuccess;
        },

        closeChangeEmailTemplateDialog: (state: AppVm) => {
            state.changeEmailTemplateDialog.open = false;
            state.changeEmailTemplateDialog.emailTemplateId = undefined;
            state.changeEmailTemplateDialog.onSuccess = undefined;
        },

        openCopyRestaurantMenuDialog: (state: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.copyRestaurantMenuDialog.open = true;
            state.copyRestaurantMenuDialog.onSuccess = onSuccess;
        },

        closeCopyRestaurantMenuDialog: (state: AppVm) => {
            state.copyRestaurantMenuDialog.open = false;
            state.copyRestaurantMenuDialog.onSuccess = undefined;
        },

        openImportRestaurantMenuDialog: (state: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.importRestaurantMenuDialog.open = true;
            state.importRestaurantMenuDialog.onSuccess = onSuccess;
        },

        closeImportRestaurantMenuDialog: (state: AppVm) => {
            state.importRestaurantMenuDialog.open = false;
            state.importRestaurantMenuDialog.onSuccess = undefined;
        },

        openCreateRestaurantMenuPromotionDialog: (state: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.createRestaurantMenuPromotionDialog.open = true;
            state.createRestaurantMenuPromotionDialog.onSuccess = onSuccess;
        },

        closeCreateRestaurantMenuPromotionDialog: (state: AppVm) => {
            state.createRestaurantMenuPromotionDialog.open = false;
            state.createRestaurantMenuPromotionDialog.onSuccess = undefined;
        },

        openCreateMenuCategoryDialog: (state: AppVm, { payload: { menuId, position, onSuccess } }: { payload: { menuId: MenuId; position: number; onSuccess: any } }) => {
            state.createMenuCategoryDialog.open = true;
            state.createMenuCategoryDialog.menuId = menuId;
            state.createMenuCategoryDialog.position = position;
            state.createMenuCategoryDialog.onSuccess = onSuccess;
        },

        closeCreateMenuCategoryDialog: (state: AppVm) => {
            state.createMenuCategoryDialog.open = false;
            state.createMenuCategoryDialog.menuId = undefined;
            state.createMenuCategoryDialog.position = undefined;
            state.createMenuCategoryDialog.onSuccess = undefined;
        },

        openRemoveMenuCategoryDialog: (state: AppVm, { payload: { category, onSuccess } }: { payload: { category: MenuCategoryVm; onSuccess: any } }) => {
            state.removeMenuCategoryDialog.open = true;
            state.removeMenuCategoryDialog.category = category;
            state.removeMenuCategoryDialog.onSuccess = onSuccess;
        },

        closeRemoveMenuCategoryDialog: (state: AppVm) => {
            state.removeMenuCategoryDialog.open = false;
            state.removeMenuCategoryDialog.category = undefined;
            state.removeMenuCategoryDialog.onSuccess = undefined;
        },

        openChangeMenuCategoryDialog: (state: AppVm, { payload: { category, onSuccess } }: { payload: { category: MenuCategoryVm; onSuccess: any } }) => {
            state.changeMenuCategoryDialog.open = true;
            state.changeMenuCategoryDialog.category = category;
            state.changeMenuCategoryDialog.onSuccess = onSuccess;
        },

        closeChangeMenuCategoryDialog: (state: AppVm) => {
            state.changeMenuCategoryDialog.open = false;
            state.changeMenuCategoryDialog.category = undefined;
            state.changeMenuCategoryDialog.onSuccess = undefined;
        },

        openCreateMenuDialog: (state: AppVm, { payload: { position, onSuccess } }: { payload: { position: number; onSuccess: any } }) => {
            state.createMenuDialog.open = true;
            state.createMenuDialog.position = position;
            state.createMenuDialog.onSuccess = onSuccess;
        },

        closeCreateMenuDialog: (state: AppVm) => {
            state.createMenuDialog.open = false;
            state.createMenuDialog.position = undefined;
            state.createMenuDialog.onSuccess = undefined;
        },

        openRemoveMenuDialog: (state: AppVm, { payload: { menu, onSuccess } }: { payload: { menu: MenuVm; onSuccess: any } }) => {
            state.removeMenuDialog.open = true;
            state.removeMenuDialog.menu = menu;
            state.removeMenuDialog.onSuccess = onSuccess;
        },

        closeRemoveMenuDialog: (state: AppVm) => {
            state.removeMenuDialog.open = false;
            state.removeMenuDialog.menu = undefined;
            state.removeMenuDialog.onSuccess = undefined;
        },

        openChangeMenuDialog: (state: AppVm, { payload: { menu, onSuccess } }: { payload: { menu: MenuVm; onSuccess: any } }) => {
            state.changeMenuDialog.open = true;
            state.changeMenuDialog.menu = menu;
            state.changeMenuDialog.onSuccess = onSuccess;
        },

        closeChangeMenuDialog: (state: AppVm) => {
            state.changeMenuDialog.open = false;
            state.changeMenuDialog.menu = undefined;
            state.changeMenuDialog.onSuccess = undefined;
        },

        openCreateMenuItemDialog: (
            state: AppVm,
            {
                payload: { menuCategoryId, position, isExternalMenu, onSuccess, menuItem, menuItemType },
            }: { payload: { menuCategoryId: MenuCategoryId; position: number; isExternalMenu: boolean; onSuccess: any; menuItem: DeprecatedManagerMenuItemVm; menuItemType: MenuItemType } },
        ) => {
            state.createMenuItemDialog.open = true;
            state.createMenuItemDialog.menuCategoryId = menuCategoryId;
            state.createMenuItemDialog.position = position;
            state.createMenuItemDialog.menuItemType = menuItemType;
            state.createMenuItemDialog.isExternalMenu = isExternalMenu;
            state.createMenuItemDialog.onSuccess = onSuccess;
            state.createMenuItemDialog.menuItem = menuItem ?? undefined;
        },

        closeCreateMenuItemDialog: (state: AppVm) => {
            state.createMenuItemDialog.open = false;
            state.createMenuItemDialog.menuCategoryId = undefined;
            state.createMenuItemDialog.position = undefined;
            state.createMenuItemDialog.isExternalMenu = undefined;
            state.createMenuItemDialog.onSuccess = undefined;
            state.createMenuItemDialog.menuItem = undefined;
        },

        openChangeMenuItemDialog: (
            state: AppVm,
            { payload: { menuItem, isExternalMenu, onSuccess } }: { payload: { menuItem: DeprecatedManagerMenuItemVm; isExternalMenu: boolean; onSuccess: any } },
        ) => {
            state.changeMenuItemDialog.open = true;
            state.changeMenuItemDialog.menuItem = { ...menuItem };
            state.changeMenuItemDialog.isExternalMenu = isExternalMenu;
            state.changeMenuItemDialog.onSuccess = onSuccess;
        },

        closeChangeMenuItemDialog: (state: AppVm) => {
            state.changeMenuItemDialog.open = false;
            state.changeMenuItemDialog.menuItem = undefined;
            state.changeMenuItemDialog.isExternalMenu = undefined;
            state.changeMenuItemDialog.onSuccess = undefined;
        },

        openRemoveMenuItemDialog: (state: AppVm, { payload: { menuItem, onSuccess } }: { payload: { menuItem: ManagerMenuItemVm; onSuccess: any } }) => {
            state.removeMenuItemDialog.open = true;
            state.removeMenuItemDialog.menuItem = menuItem;
            state.removeMenuItemDialog.onSuccess = onSuccess;
        },

        closeRemoveMenuItemDialog: (state: AppVm) => {
            state.removeMenuItemDialog.open = false;
            state.removeMenuItemDialog.menuItem = undefined;
            state.removeMenuItemDialog.onSuccess = undefined;
        },

        openMoveMenuItemToMenuDialog: (state: AppVm, { payload: { menuItem, onSuccess } }: { payload: { menuItem: ManagerMenuItemVm; onSuccess: any } }) => {
            state.moveMenuItemToMenuDialog.open = true;
            state.moveMenuItemToMenuDialog.menuItem = menuItem;
            state.moveMenuItemToMenuDialog.onSuccess = onSuccess;
        },

        closeMoveMenuItemToMenuDialog: (state: AppVm) => {
            state.moveMenuItemToMenuDialog.open = false;
            state.moveMenuItemToMenuDialog.menuItem = undefined;
            state.moveMenuItemToMenuDialog.onSuccess = undefined;
        },

        openIncomingOrdersAlert: (state: AppVm, { payload: { newOrders } }: { payload: { newOrders: Array<OrderVm> } }) => {
            state.incomingOrdersAlertState.open = true;
            state.incomingOrdersAlertState.newOrders = newOrders;
            state.incomingOrdersAlertState.lastTimeOpened = new Date();
        },

        closeIncomingOrdersAlert: (state: AppVm) => {
            state.incomingOrdersAlertState.open = false;
            state.incomingOrdersAlertState.newOrders = undefined;
            state.incomingOrdersAlertState.lastTimeOpened = new Date();
        },

        openMinimumInventoryReachedAlert: (state: AppVm, { payload: { minimumSuppliesLimit } }: { payload: { minimumSuppliesLimit: Array<MinimumSupplyLimit> } }) => {
            state.minimumInventoryReachedAlertState.open = true;
            state.minimumInventoryReachedAlertState.minimumSuppliesLimit = minimumSuppliesLimit;
        },

        closeMinimumInventoryReachedAlert: (state: AppVm) => {
            state.minimumInventoryReachedAlertState.open = false;
        },

        openHideMenuItemDialog: (state: AppVm, { payload: { menuItem, onSuccess } }: { payload: { menuItem: ManagerMenuItemVm; onSuccess: any } }) => {
            state.hideMenuItemDialog.open = true;
            state.hideMenuItemDialog.menuItem = menuItem;
            state.hideMenuItemDialog.onSuccess = onSuccess;
        },

        closeHideMenuItemDialog: (state: AppVm) => {
            state.hideMenuItemDialog.open = false;
            state.hideMenuItemDialog.menuItem = undefined;
            state.hideMenuItemDialog.onSuccess = undefined;
        },

        openAddNewCardDialog: (state: AppVm, { payload: { cardPriority, businessName, onSuccess } }: { payload: { cardPriority: CardPriority; businessName: string; onSuccess: any } }) => {
            state.addNewCardDialog.open = true;
            state.addNewCardDialog.cardPriority = cardPriority;
            state.addNewCardDialog.businessName = businessName;
            state.addNewCardDialog.onSuccess = onSuccess;
        },

        closeAddNewCardDialog: (state: AppVm) => {
            state.addNewCardDialog.open = false;
            state.addNewCardDialog.cardPriority = undefined;
            state.addNewCardDialog.businessName = undefined;
            state.addNewCardDialog.onSuccess = undefined;
        },

        openVerifyPosPasswordDialog: (state: AppVm, { payload: { onAuthorized, onUnauthorized } }: { payload: { onAuthorized: any; onUnauthorized: any } }) => {
            state.verifyPosPasswordDialog.open = true;
            state.verifyPosPasswordDialog.onAuthorized = onAuthorized;
            state.verifyPosPasswordDialog.onUnauthorized = onUnauthorized;
        },

        closeVerifyPosPasswordDialog: (state: AppVm) => {
            state.verifyPosPasswordDialog.open = false;
            state.verifyPosPasswordDialog.onAuthorized = undefined;
            state.verifyPosPasswordDialog.onUnauthorized = undefined;
        },

        openIntegrationMenuChangesApprovalDialog: (state: AppVm, { payload: { menu } }: { payload: { menu: IntegrationMenuApprovalVm } }) => {
            if (state.integrationMenuChangesApprovalDialog.open) return;

            state.integrationMenuChangesApprovalDialog.open = true;
            state.integrationMenuChangesApprovalDialog.menu = menu;
        },

        closeIntegrationMenuChangesApprovalDialog: (state: AppVm) => {
            state.integrationMenuChangesApprovalDialog.open = false;
            state.integrationMenuChangesApprovalDialog.menu = undefined;
        },

        openVerifyPosPinCodeUserDialog: (
            state: AppVm,
            { payload: { onAuthorized, onUnauthorized, managerUserId } }: { payload: { managerUserId: ManagerUserId; onAuthorized: any; onUnauthorized: any } },
        ) => {
            state.verifyPosPinCodeUserDialog.open = true;
            state.verifyPosPinCodeUserDialog.managerUserId = managerUserId;
            state.verifyPosPinCodeUserDialog.onAuthorized = onAuthorized;
            state.verifyPosPinCodeUserDialog.onUnauthorized = onUnauthorized;
        },

        closeVerifyPosPinCodeUserDialog: (state: AppVm) => {
            state.verifyPosPinCodeUserDialog.open = false;
            state.verifyPosPinCodeUserDialog.managerUserId = undefined;
            state.verifyPosPinCodeUserDialog.onAuthorized = undefined;
            state.verifyPosPinCodeUserDialog.onUnauthorized = undefined;
        },

        theMenuSelectCategory: (state: AppVm, { payload: { menuCategoryId } }: { payload: { menuCategoryId: MenuCategoryId } }) => {
            state.theMenuCategoryIdSelected = menuCategoryId;
        },

        fetchSuccessCurrentOrders: (state: AppVm, { payload: { currentOrders } }: { payload: { currentOrders: Array<OrderVm> } }) => {
            state.timesFetchRealtimeUpdatesFailed = 0;
            state.online = true;
            state.noInternetConnectionDialog.open = false;
            state.noInternetConnectionDialog.lastTimeOpened = undefined;

            if (deepEqual(currentOrders, state.currentOrders)) return;

            const newCurrentOrders = currentOrders.map((newCurrentOrder: OrderVm) => {
                const currentOrder = state.currentOrders.find((currentOrder) => currentOrder.orderId === newCurrentOrder.orderId);
                if (deepEqual(newCurrentOrder, currentOrder) && currentOrder) return currentOrder;
                return newCurrentOrder;
            });

            state.currentOrders = newCurrentOrders;
        },

        setPreparingKitchenOrders: (state: AppVm, { payload: preparingKitchenOrders }: { payload: Array<OrderVm> }) => {
            state.preparingKitchenOrders = preparingKitchenOrders;
        },

        setPreparedKitchenOrders: (state: AppVm, { payload: preparedKitchenOrders }: { payload: Array<OrderVm> }) => {
            state.preparedKitchenOrders = preparedKitchenOrders;
        },

        setKitchenOrdersSummary: (state: AppVm, { payload: kitchenSummary }: { payload: KitchenOrdersSummaryVm }) => {
            state.kitchenOrdersSummary = kitchenSummary;
        },

        setHasNewKitchenOrders: (state: AppVm, { payload: hasNewKitchenOrders }: { payload: boolean }) => {
            state.hasNewKitchenOrders = hasNewKitchenOrders;
        },

        updateOrder: (state: AppVm, { payload: { order } }: { payload: { order: OrderVm } }) => {
            const currentOrder = state.currentOrders.find((currentOrder) => currentOrder.orderId === order.orderId);
            if (!currentOrder) state.currentOrders = [order, ...state.currentOrders];
            if (deepEqual(currentOrder, order)) return;
            state.currentOrders = state.currentOrders.map((currentOrder) => (currentOrder.orderId === order.orderId ? order : currentOrder));
        },

        rejectOrder: (state: AppVm, { payload: { orderId } }: { payload: { orderId: OrderId } }) => {
            state.submittingOrders[orderId] = 'REJECTING_ORDER';
        },

        acceptOrder: (state: AppVm, { payload: { orderId } }: { payload: { orderId: OrderId } }) => {
            state.submittingOrders[orderId] = 'ACCEPTING_ORDER';
        },

        cancelOrder: (state: AppVm, { payload: { orderId } }: { payload: { orderId: OrderId } }) => {
            state.submittingOrders[orderId] = 'CANCELLING_ORDER';
        },

        completeOrder: (state: AppVm, { payload: { orderId } }: { payload: { orderId: OrderId } }) => {
            state.submittingOrders[orderId] = 'COMPLETING_ORDER';
        },

        fetchOrder: (state: AppVm, { payload: { orderId } }: { payload: { orderId: OrderId } }) => {
            state.submittingOrders[orderId] = 'FETCHING_ORDER';
        },

        fetchOrderSuccess: (state: AppVm, { payload: { orderId, orderVm } }: { payload: { orderId: OrderId; orderVm: OrderVm } }) => {
            const submittingOrders = { ...state.submittingOrders };
            delete submittingOrders[orderId];

            if (!state.currentOrders.find((order) => order.orderId === orderVm.orderId)) {
                state.currentOrders = orderBy([orderVm, ...state.currentOrders], 'createdAt', 'desc');
                state.submittingOrders = submittingOrders;
                return;
            }

            const currentOrders = orderBy(
                state.currentOrders.reduce<Array<any>>((currentOrders, order) => {
                    if (order.orderId === orderVm.orderId) {
                        if (
                            orderVm.orderStatus === OrderStatuses.CANCELLED ||
                            orderVm.orderStatus === OrderStatuses.REJECTED ||
                            orderVm.orderStatus === OrderStatuses.COMPLETE || // TODO: Remove OrderStatus.COMPLETE when changed to COMPLETED on server and db
                            orderVm.orderStatus === OrderStatuses.COMPLETED
                        ) {
                            return currentOrders;
                        }
                        currentOrders.push(orderVm);
                        return currentOrders;
                    }
                    currentOrders.push(order);
                    return currentOrders;
                }, []),
                'createdAt',
                'desc',
            );

            state.currentOrders = currentOrders;
            state.submittingOrders = submittingOrders;
        },

        fetchRealtimeUpdatesFailed: (state: AppVm, { payload: { e } }: { payload: { e: string } }) => {
            if (e === 'NETWORK_ERROR') {
                state.timesFetchRealtimeUpdatesFailed += 1;
                if (state.timesFetchRealtimeUpdatesFailed > 3) {
                    state.online = false;
                    if (!state.noInternetConnectionDialog.lastTimeOpened) {
                        state.noInternetConnectionDialog.open = true;
                    }
                }
            }
        },

        setOnline: (state: AppVm, { payload: online }: { payload: boolean }) => {
            state.online = online;
        },

        openNoInternetConnectionDialog: (state: AppVm, { payload: online }: { payload: boolean }) => {
            state.noInternetConnectionDialog.open = true;
        },

        closeNoInternetConnectionDialog: (state: AppVm, { payload: online }: { payload: boolean }) => {
            state.noInternetConnectionDialog.open = false;
            state.noInternetConnectionDialog.lastTimeOpened = new Date();
        },

        setZendeskJwt: (state: AppVm, { payload: zendeskJwt }: { payload: string }) => {
            state.zendeskJwt = zendeskJwt;
        },

        setCurrentEmployeesClockedIn: (
            state: AppVm,
            {
                payload: currentEmployeesClockedIn,
            }: {
                payload:
                    | Array<{
                          managerUserId: ManagerUserId;
                          clockedInAt: Date;
                      }>
                    | undefined;
            },
        ) => {
            state.currentEmployeesClockedIn = currentEmployeesClockedIn;
        },

        setRealtimeUpdate: (state: AppVm, { payload }: { payload: RealtimeUpdate }) => {
            state.managerMessageStyle = payload.managerMessageStyle;
            state.managerMessageTitle = payload.managerMessageTitle;
            state.managerMessageBody = payload.managerMessageBody;
            state.managerMessageUrl = payload.managerMessageUrl;
            state.isCopyingMenu = payload.isCopyingMenu;
            state.driverArrivesAtStoreTime = payload.driverArrivesAtStoreTime;
            state.quickActionMessage = payload.quickActionMessage;
            state.storeShouldBeOpen = payload.storeShouldBeOpen;
            state.currentEmployeesClockedIn = payload.currentEmployeesClockedIn;
            state.subscriptionStatus = payload.subscriptionStatus;
            state.subscriptionAccess = payload.subscriptionAccess;
            state.remainingDays = payload.remainingDays;
            state.payPerUsePaymentAmountLimitReached = payload.payPerUsePaymentAmountLimitReached;
            state.didiFoodStoreOnline = payload.didiFoodStoreOnline;
            state.rappiStoreOnline = payload.rappiStoreOnline;
            state.uberEatsStoreOnline = payload.uberEatsStoreOnline;
            state.pedidosYaRestaurantOnline = payload.pedidosYaRestaurantOnline;
            state.uberEatsStoreOfflineReason = payload.uberEatsStoreOfflineReason;
            state.uberEatsRdOptionalDisabled = payload.uberEatsRdOptionalDisabled;
            state.uberEatsStorePausedByUber = payload.uberEatsStorePausedByUber;

            if (payload.subscriptionPlan) state.subscriptionPlan = payload.subscriptionPlan;
        },
    },
});

type RealtimeUpdate = {
    managerMessageStyle: string | undefined;
    managerMessageTitle: string | undefined;
    managerMessageBody: string | undefined;
    managerMessageUrl: string | undefined;
    isCopyingMenu?: boolean;
    driverArrivesAtStoreTime: DriverArrivesAtStoreTime | undefined;
    quickActionMessage: string | undefined;
    storeShouldBeOpen: boolean;
    currentEmployeesClockedIn?: Array<{
        managerUserId: ManagerUserId;
        clockedInAt: Date;
    }>;
    remainingDays: number;
    subscriptionPlan?: SubscriptionPlan;
    subscriptionStatus: SubscriptionStatus | undefined;
    subscriptionAccess: boolean;
    payPerUsePaymentAmountLimitReached?: boolean;
    didiFoodStoreOnline: boolean | undefined;
    rappiStoreOnline: boolean | undefined;
    uberEatsStoreOnline: boolean | undefined;
    uberEatsStoreOfflineReason: UberEatsStoreOfflineReason | undefined;
    uberEatsRdOptionalDisabled: boolean | undefined;
    uberEatsStorePausedByUber: boolean | undefined;
    pedidosYaRestaurantOnline: boolean | undefined;
};
