import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormEmailField, FormTextField } from '@pidedirecto/ui/form';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createPeruvianOrderInvoiceApi } from 'src/api/letseatmanager/invoice/createPeruvianOrderInvoiceApi';
import { findRestaurantInvoicesLegalInfoApi } from 'src/api/letseatmanager/invoice/findRestaurantInvoicesLegalInfoApi';
import { InvoicesLegalInfoVm } from 'src/api/letseatmanager/types/InvoicesLegalInfoVm';
import { FormFiscalEntitySelect } from 'src/components/form/FormFiscalEntitySelect';
import { FormRestaurantInvoiceFiscalIdAutocomplete } from 'src/components/form/FormRestaurantInvoiceFiscalIdAutocomplete';
import { FiscalEntities, FiscalEntity } from 'src/constants/FiscalEntity';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { EmailAddress } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBusinessFiscalEntity } from 'src/utils/invoice/fiscalEntity/isBusinessFiscalEntity';
import { isFisicFiscalEntity } from 'src/utils/invoice/fiscalEntity/isFisicFiscalEntity';
import { useSelector } from 'src/utils/react/useSelector';

export function CreatePeruvianOrderInvoiceDialog({ open, order, onClose, onSuccess, defaultFiscalEntity }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const [printOrderTicket] = usePrintOrderTicket();

    const fiscalEntity = useWatch<FiscalEntity>({ name: 'fiscalEntity', control: form.control, defaultValue: defaultFiscalEntity ?? FiscalEntities.FISIC });

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        if (isBusinessFiscalEntity(fiscalEntity) && order.customerRuc) loadInvoiceLegalInfo(order.customerRuc);
    }, [fiscalEntity]);

    const loadInvoiceLegalInfo = async (customerRuc: string) => {
        form.setValue('ruc', customerRuc);
        setLoading(true);
        const response = await findRestaurantInvoicesLegalInfoApi({ restaurantId, fiscalId: customerRuc });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        if (order.customerRuc) {
            onSelectInvoiceLegalInfoAutocomplete(response.data[0]);
        }
    };

    const handleSubmit = async (form: FormValues) => {
        if (!order.paymentMethod) return;
        setLoading(true);
        const response = await createPeruvianOrderInvoiceApi({
            orderId: order.orderId,
            fiscalEntity: form.fiscalEntity,
            email: form.email,
            dni: form.dni,
            ruc: form.ruc,
            businessName: form.businessName,
            address: form.address,
            city: form.city,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess?.();
        handleClose();

        if (response.data) {
            const updatedOrder = response.data;
            await printOrderTicket(updatedOrder);
        }
    };

    const handleClose = () => {
        if (loading) return;
        onClose();
    };
    const onInputChangeFiscalId = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        form.setValue('ruc', value);
    };

    const onSelectInvoiceLegalInfoAutocomplete = (invoiceLegalInfo: InvoicesLegalInfoVm) => {
        form.setValue('ruc', invoiceLegalInfo.fiscalId);
        form.setValue('businessName', invoiceLegalInfo.businessName);
        form.setValue('address', invoiceLegalInfo.address);
        form.setValue('city', invoiceLegalInfo.city);
    };

    return (
        <Dialog title={translate('Create order invoice')} loading={loading} open={open} onClose={handleClose} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form} classes={{ form: classes.form }}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormFiscalEntitySelect
                                required
                                name='fiscalEntity'
                                label={translate('Select the Fiscal Entity')}
                                disabled={false}
                                defaultValue={defaultFiscalEntity ?? FiscalEntities.FISIC}
                            />
                        </Grid>
                        {isFisicFiscalEntity(fiscalEntity) && (
                            <Grid item xs={12}>
                                <FormTextField name='dni' label={translate('Dni')} disabled={loading} />
                            </Grid>
                        )}

                        {isBusinessFiscalEntity(fiscalEntity) && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormRestaurantInvoiceFiscalIdAutocomplete
                                        name='ruc'
                                        required
                                        label={translate('RUC')}
                                        onSelect={onSelectInvoiceLegalInfoAutocomplete}
                                        onInputChange={onInputChangeFiscalId}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='businessName'
                                        required={{
                                            value: true,
                                            message: translate('This field is required'),
                                        }}
                                        label={translate('Business Name')}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='address'
                                        label={translate('Address')}
                                        required={{
                                            value: true,
                                            message: translate('This field is required'),
                                        }}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='city'
                                        label={translate('City')}
                                        required={{
                                            value: true,
                                            message: translate('This field is required'),
                                        }}
                                        disabled={loading}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} tooltip={translate('Remember to use minor case for the email')} disabled={loading} />
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <Button variant={'secondary'} onClick={handleClose} disabled={loading} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading} classes={{ button: classes.button }}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        overflow: 'hidden',
    },
    dialog: {
        maxWidth: '700px',
        overflow: 'hidden',
    },
    button: {
        width: '50%',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    defaultFiscalEntity?: FiscalEntity;
    onClose: any;
    onSuccess?: any;
};

type FormValues = {
    fiscalEntity: FiscalEntity;
    email?: EmailAddress;
    dni?: string;
    ruc?: string;
    businessName?: string;
    address?: string;
    city?: string;
};
