import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { RestaurantNotificationVm } from 'src/types/RestaurantNotificationVm';

export async function findRestaurantNotificationsApi(request: FindRestaurantNotificationsApiRequest): ApiSauceResponse<Response> {
    return letseatmanagerApiMethod('restaurant/findRestaurantNotificationsApi', request);
}

export type FindRestaurantNotificationsApiRequest = {
    restaurantId: RestaurantId;
};

type Response = {
    notifications: Array<RestaurantNotificationVm>;
    quickActionsNotifications: Array<RestaurantNotificationVm>;
};
